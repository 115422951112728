<div class="stepper-container">
    <h2 style="text-align: center;">{{'MENU.LEGAL_NOTICE' | translate}}</h2>
    <div class="center" style="width: 70%;">
        <mat-form-field>
            <textarea matInput disabled mat-autosize style="color:black; overflow: hidden; padding: 1em;" [value]="legalNotice">

               </textarea>
        </mat-form-field>

    </div>
</div>