import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

    public yes: string = "";
    public no: string = "";
    public onlyOk: boolean = false;
    private messageToDisplay: string= "";
    constructor(public translate: TranslateService,
        public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public message: string,
        )
    {
        this.messageToDisplay = message;
        this.translate.get("Yes").subscribe(msg => 
        {
            this.yes = msg;
        });
        this.translate.get("No").subscribe(msg => 
        {
            this.no = msg;
        });
    }
    ngOnInit(): void {
        document.getElementById("content").innerHTML = this.messageToDisplay;
    }
    onNoClick(): void
    {
        this.dialogRef.close();
    }
}
