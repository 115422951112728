import { Component, OnInit, Inject, EventEmitter, Output, ViewChild, ElementRef, AfterViewInit, Input, OnDestroy } from '@angular/core';
import { IConcern, IConcernCounter } from '../../shared/model/concern';
import { ConcernService } from 'src/app/shared/services/concern.service';
import { IUserConcern } from 'src/app/shared/model/userconcern';
import { UserConcernService } from 'src/app/shared/services/userconcern.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConcernDetailComponent } from '../concerndetail/concerndetail.component';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { FilterComponent } from 'src/app/shared/filter/filter.component';
import { ConcernGroupService } from 'src/app/shared/services/concerngroup.service';
import { IConcernGroup, IConcernGroupCounter } from 'src/app/shared/model/concerngroup';
import { of, Subscription } from 'rxjs';
import { LocationService } from 'src/app/shared/services/location.service';
import { MessageService } from 'src/app/shared/services/messages.service';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { GlobalSettings } from 'src/app/shared/config/globalsettings';
import { ServerSettingService } from 'src/app/shared/services/server-setting.service';
import { ServerSettings } from 'src/app/shared/config/server-settings';
import { ConfigSettings } from 'src/app/config-settings';
import { ConfigSettingsService } from 'src/app/shared/services/config-settings.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/shared/services/language.service';
import { OnlineDesignService } from 'src/app/shared/services/online-design.service';



// TER-572 Nachdem absenden der Kontaktdaten kann man bei dem Anligen noch die Dienstleistungen einstellen.

@Component({
    selector: 'app-concern',
    templateUrl: './concern.component.html',
    styleUrls: ['./concern.component.scss']
})
export class ConcernComponent implements OnInit, AfterViewInit, OnDestroy
{

    private classame = (<any>this).constructor.name;
    
    private subscriptions : Subscription[] = [];

    translationObject: any;

    configSettings: ConfigSettings;
    //additional client configuration settings.

    //todo: for small devices display in columns!

    @Input() concernGroupid: string;

    parentListFilter: string;
    includeDetail: boolean = true;
    frmConcern: UntypedFormGroup;
    showServiceDuration: boolean;
    showInfo: boolean;


    CONCERN_DURATION_TEXT: string;

    // either by configuarion or via each service
    maxNumberOfConcernsGlobal = 1;
    numberOfConcerns = 0;

    // for the array of concerns we need a new array with add and reduce enabled,
    concerns: IConcern[];

    concernGroups: IConcernGroup[];

    // filter later
    //@ViewChild(FilterComponent) filterComponentRef: FilterComponent;
    filteredConcerns: IConcern[];


    // callback function of the concerngroup
    @Output() concernGroupCounterNotify: EventEmitter<IConcernGroupCounter> = new EventEmitter<IConcernGroupCounter>();

    constructor(
        private onlineDesignService: OnlineDesignService,
        private concernService: ConcernService,
        private userConcernService: UserConcernService,
        private locationService: LocationService,
        public snackBar: MatSnackBar,
        public dialog: MatDialog,
        private formBuilder: UntypedFormBuilder,
        private messageService: MessageService,
        private customerService: CustomerService,
        private globalSettingService: ServerSettingService,
        private configSettingsService: ConfigSettingsService,
        public translate: TranslateService,
        public languageService: LanguageService,
    )
    {
    }

    ngOnInit()
    {
        // get the translation resources
        this.subscriptions.push(this.translate.get(['CONCERN.DURATION_TEXT']).subscribe((translation: [string]) =>
        {
            this.translationObject = translation;
            this.CONCERN_DURATION_TEXT = this.translationObject['CONCERN.DURATION_TEXT'];
        }));


        this.configSettings = this.configSettingsService.configSettings;
        this.onlineDesignService.showServiceDuration.subscribe( result => this.showServiceDuration = result);
        this.showServiceDuration = this.onlineDesignService.showServiceDurationCurrentState;

        this.showInfo = this.configSettings.concernShowInfoButton;

        this.concerns = this.concernService.getConcernInGroup(this.concernGroupid);


        // TER-572
        this.subscriptions.push(this.customerService.notifyFormSent.subscribe(
            customerFormSent =>
            {
                this.setReadonlyForm(customerFormSent)
            }
        ));

    }

    ngAfterViewInit(): void { }



    calculateDuration(concern: IConcern): number
    {
        if (concern.countSelected == 0)
        {
            // this is responsible that nothing will displayed in the pipe for no value
            return null;

        }

        let result = concern.initialDuration + (concern.countSelected - 1) * concern.consecutiveDuration
        return (result);
    }



    changeUserConcern(concern: IConcern, action: string): void
    {

        if (action === "add")
        {
            this.userConcernService.updateUserConcernQuantity(concern.id, 1);
            this.concernService.updateSelectedCount(concern.id, 1);

        } else
        {
            this.userConcernService.updateUserConcernQuantity(concern.id, -1);
            this.concernService.updateSelectedCount(concern.id, -1);
        }


        // check the situation of the buttons after changing the number of concerns.
        let index = this.concerns.findIndex(x => x.id == concern.id);
        this.concerns[index] = concern;

        if (concern.countSelected >= (concern.maxRepetitions ? concern.maxRepetitions : this.maxNumberOfConcernsGlobal))
            concern.addEnabled = false;
        else
            concern.addEnabled = true;


        if (concern.countSelected <= 0)
            concern.reduceEnabled = false;
        else
            concern.reduceEnabled = true;

        // notify the group for the amount from child to parent.
        let concernGroupCounter = new IConcernGroupCounter();
        concernGroupCounter.id = this.concernGroupid;
        concernGroupCounter.amount = this.concernService.getSelectedConcernsQuantityInGroup(this.concernGroupid);

        concernGroupCounter.duration = this.concernService.getSelectedRecordDurationInGroup(this.concernGroupid);
        this.concernGroupCounterNotify.emit(concernGroupCounter);
     

        // the currently loaded locations must be done invalid.  
        this.locationService.loadedLocationsInvalid = true;
    }

    //  dialog for the detail pape 
    openDialog(concern: IConcern): void
    {
        const dialogRef = this.dialog.open(ConcernDetailComponent, {
            height: '400px',
            width: '600px',
            data: { concern }
        });

        this.subscriptions.push(dialogRef.afterClosed().subscribe(result =>
        {
           
        }));
        //this.subscription.add(subscription);
    }

    //set notifer on contact form sent.  
    setReadonlyForm(isSent: boolean)
    {
        this.concerns.forEach(function (concern)
        {
            concern.addEnabled = false;
            concern.reduceEnabled = false;
        })
    };

    // maybe called from the concerngroup
    // or from the service
    setReadonlyForm2(isSent: boolean)
    {

        this.concerns.forEach(function (concern)
        {
            concern.addEnabled = false;
            concern.reduceEnabled = false;
        })
    };


    ngOnDestroy(): void
    {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

}
