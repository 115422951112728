import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IInfoBanner } from '../model/info-banner.model';
import { ConfigSettingsService } from './config-settings.service';
import { OnlineDesign } from '../model/online-design';


@Injectable({
    providedIn: 'root'
})
export class OnlineDesignService
{
    // used as "state-management"
    public showServiceDuration: Subject<boolean> = new Subject<boolean>();
    public showServiceDurationCurrentState: boolean;

    public showServiceGroupDuration: Subject<boolean> = new Subject<boolean>();
    public showServiceGroupDurationCurrentState: boolean;

    public showTotalDuration: Subject<boolean> = new Subject<boolean>();
    public showTotalDurationCurrentState: boolean;

    constructor( private http: HttpClient, private configSettingsService: ConfigSettingsService ) {
        this.showServiceDuration.subscribe(val => this.showServiceDurationCurrentState = val);
        this.showServiceGroupDuration.subscribe(val => this.showServiceGroupDurationCurrentState = val);
        this.showTotalDuration.subscribe(val => this.showTotalDurationCurrentState = val);
     }

    public getOnlineDesignSettings() : Observable<OnlineDesign>
    {
        return this.http.get<OnlineDesign>(this.configSettingsService.configSettings.apiUrl + "/api/online-design/get");
    }


}
