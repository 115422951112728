<div>
    <div class="appointment-content">
        <div class="appointment-sec1">
            <div class="calendar-wrapper">
                <div>
                    <ng-container #outlet></ng-container>
                </div>
                <ng-template #calendar class="primary-colors">
                    <app-calendar [datesToHiglightList]="datesToHighlight"
                        (notifyCalendarDay)="notifyCalendarDay($event)" class="no-select"></app-calendar>
                </ng-template>
            </div>
        </div>

        <div class="appointment-space1">
        </div>

        <div class="appointment-sec2">

            <div *ngIf="datesToHighlight?.length === 0 || !chooseAvailabeDateVisible"
                style="display:flex; flex-direction: column; justify-content: center; align-items: center; height: 100%; text-align: center;">
                <h3>{{'LOCATION.NOT_AVAILABLE_TEXT' | translate}}</h3>
            </div>
            <p *ngIf="chooseAvailabeDateVisible">
                {{languageService.getFormatDateString('APPOINTMENT_CALENDAR.TEXT_TIME_SELECT_OPTION' |
                translate,selectedDate)}}
            </p>
            <ng-template [ngIf]="chooseAvailabeDateVisible">
                <form [formGroup]="form">
                    <label id="example-radio-group-label"></label>
                    <mat-radio-group aria-labelledby="example-radio-group-label" formControlName="selectedDateWithTime"
                        class="example-radio-group" [(ngModel)]="selectedDateWithTime"
                        (change)="SelectAppointmentDate($event)">
                        <div formArrayName="timeSectionsArray">
                            <div *ngFor="let timeSection of form.controls.timeSectionsArray.controls; let i=index">
                                <mat-accordion [formGroupName]="i">
                                    <mat-expansion-panel [expanded]="(i == 0)">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                {{'APPOINTMENT_CALENDAR.DAY_HOUR_HEADER_TEXT' | translate |
                                                userSettings:timeSection.get('timeFrom').value:timeSection.get('timeTo').value}}
                                            </mat-panel-title>
                                            <mat-panel-description>
                                            </mat-panel-description>
                                        </mat-expansion-panel-header>

                                        <mat-radio-button class="sel-date-radio-button no-select"
                                            *ngFor="let availableDateTime of avilableHoursSelect[i]"
                                            [value]="availableDateTime"
                                            [checked]="(selectedDateWithTime?.getTime()===availableDateTime?.getTime())">
                                            {{availableDateTime | localdate:'ConfigTimeSettings'}}
                                        </mat-radio-button>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>
                            <div *ngIf="form.controls.timeSectionsArray.controls?.length === 0">
                                <h3>{{'LOCATION.NOT_AVAILABLE_TEXT' | translate}}</h3>
                            </div>
                        </div>
                    </mat-radio-group>
                </form>
                <div class="selected-value">
                    <ng-template [ngIf]="selectedDateWithTime">
                        {{languageService.getFormatDateString('APPOINTMENT_CALENDAR.TEXT_SELECTED_VALUE' |
                        translate,selectedDateWithTime)}}
                    </ng-template>
                </div>
            </ng-template>
        </div>

        <div class="appointment-space2"></div>

        <div class="sec3"></div>
    </div>
</div>



<div class="nav-footer-bar">
    <div class="fixedLeft">
        <button mat-raised-button matStepperPrevious
            matTooltip="{{'APPOINTMENT_CALENDAR.BACK_PAGE_TOOLTIIP' | translate}}">
            <div style="display:flex; flex-direction:row; align-items:center;">
                <mat-icon>
                    <mat-icon>keyboard_arrow_left</mat-icon>
                </mat-icon>
                <div>
                    {{'BUTTONS.BACK' | translate}}
                </div>
            </div>
        </button>
    </div>

    <ng-template [ngIf]="selectedDateWithTime">
    </ng-template>

    <div class="fixedRight">
        <button mat-raised-button matStepperNext [disabled]="!selectedDateWithTime"
            matTooltip="{{'APPOINTMENT_CALENDAR.NEXT_PAGE_TOOLTIIP' | translate}}">
            <div style="display:flex; flex-direction:row; align-items:center;">
                <div>{{'BUTTONS.NEXT' | translate}}</div>
                <mat-icon>keyboard_arrow_right</mat-icon>
            </div>
        </button>
    </div>
</div>