<div class="page-content">
    <ng-template [ngIf]="(!isInStep || showTitleInStepper)">
        <h2 style="text-align: center;" *ngIf="!linkConfirmationFinished">{{'CONFIRMATION.TITLE' | translate}}</h2>
        <h2 style="text-align: center;" *ngIf="linkConfirmationFinished">{{'CONFIRMATION.TITLE_CONFIRMED' | translate}}</h2>
    </ng-template>
    <div class="content-header-height" *ngIf="!showConfirmationForm" style="text-align: center;">
        <!-- form submit messages -->
        <ng-template [ngIf]="!linkConfirmationFinished">
            <!-- <div [innerHTML]="getRessourceText('CONTACT_FORM.CHECK_AGB')"></div> -->
            <div [innerHTML]="getReplaceText('CONFIRMATION.SUBMITTED_TEXT_STEPPER')"></div>
            <mat-card *ngIf="isLoading" style="margin-bottom: 20px;">
                <mat-card-content style="display:flex; flex-direction:row; justify-content:center; align-items:center;">
                    <mat-spinner></mat-spinner>
                </mat-card-content>
            </mat-card>
        </ng-template>
        <ng-template [ngIf]="!isInStep && linkConfirmationFinished">
            <!-- link conirmation page -->
            <div [innerHTML]="getReplaceText('CONFIRMATION.SUBMITTED_TEXT_PAGE')"></div>
        </ng-template>

        <ng-template [ngIf]="displayTicketNumber">
            <br>
            <div>{{'CONFIRMATION.TICKET_MESSAGE' | translate}}</div>
            <h3>{{ticketNumber}}</h3>
        </ng-template>
        <br>
        <button mat-raised-button  (click)="bookAnother()" >{{'BUTTONS.BOOKANOTHER' | translate}}</button>
        <!--  [routerLink]="['/book-appointment/']" routerLinkActive="router-link-active" -->
    </div>
</div>
<div class="page-content">
    
    <form [formGroup]="frmConfirmation" class="form" novalidate *ngIf="showConfirmationForm">
        <div class="content-header-height" style="text-align: center;">
            <ng-template [ngIf]="isInStep">
                <div [innerHTML]="getReplaceText('CONFIRMATION.HEADER_TEXT_STEPPER')"></div>
                <p>{{"CONFIRMATION.CHECK_EMAIL" | translate}}</p>
                <p style="color: red;">{{"CONFIRMATION.NO_CONFIRMATION" | translate}}</p>
            </ng-template>
            <ng-template [ngIf]="!isInStep">
                <div [innerHTML]="getReplaceText('CONFIRMATION.HEADER_TEXT_PAGE')"></div>
            </ng-template>
        </div>
        <div class="center">
            <mat-card *ngIf="isLoading" style="margin-bottom: 20px;">
                <mat-card-content style="display:flex; flex-direction:row; justify-content:center; align-items:center;">
                    <mat-spinner></mat-spinner>
                </mat-card-content>
            </mat-card>

            <mat-form-field class="form-element" style="min-width: 120px;">
                <mat-label>{{'CONFIRMATION.CONTROL_CODE' | translate}}</mat-label>
                <input matInput placeholder="{{'CONFIRMATION.CONTROL_CODE' | translate}}" formControlName="controlCode"  >
                <mat-error *ngIf="frmConfirmation.controls.controlCode.errors?.required">
                    {{'MESSAGES.CONFIRM_CODE_REQUIRED' | translate}}</mat-error>
            </mat-form-field>
            <mat-error *ngIf="confirmError">
                {{'CONFIRMATION.CONFIRM_ERROR_IN_CODE' | translate}}
            </mat-error>

            <br />
            <br />
            <br />

            <div class="nav-footer-bar">
                <ng-template [ngIf]="isInStep">
                    <div class="fixedLeft">
                        <button mat-raised-button  matStepperPrevious
                            matTooltip="{{'CONFIRMATION.BACK_PAGE_TOOLTIIP' | translate}}">
                            <div style="display:flex; flex-direction:row; align-items:center;">
                            <mat-icon>keyboard_arrow_left</mat-icon>
                            <div>{{'BUTTONS.BACK' | translate}}</div>
                            </div>
                        </button>
                    </div>
                </ng-template>

                <div class="fixedRight">
                    <button mat-raised-button  [disabled]="!finishStepIsEnabled" (click)="submit()"
                        matTooltip="{{'CONFIRMATION.CONFIRM' | translate}}">
                        <div style="display:flex; flex-direction:row; align-items:center;">
                        <div>{{'BUTTONS.CONFIRM' | translate}}</div>
                        <mat-icon>keyboard_arrow_right</mat-icon>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
<ng-template [ngIf]="!showConfirmationForm">
    <div class="center">
        <div class="nav-footer-bar">
        </div>
    </div>
</ng-template>