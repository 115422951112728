import { Pipe, PipeTransform, OnDestroy } from '@angular/core';
import { ServerSettings } from '../config/server-settings';
import { ServerSettingService } from '../services/server-setting.service';
import { ConfigSettingsService } from '../services/config-settings.service';
import { ConfigSettings } from 'src/app/config-settings';
import { GlobalSettings } from '../config/globalsettings';
import { LanguageService } from '../services/language.service';
import { Subscription } from 'rxjs';

@Pipe({
  name: 'defaultTranslation',
  pure: false
})
export class DefaultTranslationPipe implements PipeTransform, OnDestroy {

  private subscriptions : Subscription[] = [];
  serverSettingDefaultLanguage:string
  language:string;
  serverSettings:ServerSettings;
  configSettings: ConfigSettings;
  constructor(

    private configSettingsService: ConfigSettingsService,
    private languageService: LanguageService,
    private globalSettingService:ServerSettingService
  ) {

    this.configSettings = this.configSettingsService.configSettings;

    //this.language = 
    // this.subscriptions.push( this.globalSettingService.getServerVariables().subscribe(serverSettings => {
    //   this.serverSettingDefaultLanguage = serverSettings.defaultLanguageCode;
    // }));
    this.serverSettingDefaultLanguage = this.globalSettingService.defaultLanguageCode;
  }

  transform(value: any, args:any): any {

    if(value != null && value.hasOwnProperty('localizations') && value.localizations != null){
 
      // get the value from the config-settings.json
      // this should be the fall back value
      // todo: think about getting the language value from the application.
      // maybe local storage.
   
      // get language of users choice
      this.language = this.languageService.currentLanguage.code;
      let localizationValue = value.localizations.find(x => x.languageCode == this.language);

      var returnValue="";
      if(localizationValue!=null){
        returnValue = localizationValue[args];
      }

      // if there is no value of users choice then
      // try to get the default from somewhere else
      if (GlobalSettings.isEmpty(returnValue)){

        // we have different options. Default value of client system
        //let language = this.configSettings.localizationLanguage;
        
        // default value of server settings.
        let language = this.serverSettingDefaultLanguage;
        localizationValue = value.localizations.find(x => x.languageCode == language);
        if(localizationValue!=null){
          returnValue = localizationValue[args];
        }
      }
    
    
    // this.serverSettingDefaultLanguage
    
    
    }
    return returnValue;
  }


  ngOnDestroy()
  {
      this.subscriptions.forEach(s => s.unsubscribe());
  }
}
