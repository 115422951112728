import { Injectable, EventEmitter, Output, OnDestroy, Directive } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
//import { CalendarDay } from '../model/calendarday';
import { MessageService } from './messages.service';
import { ICalendarDay, IDateFormat } from '../model/calendarday';
import { GlobalSettings } from '../config/globalsettings';
import { IUserConcern } from '../model/userconcern';
import { ITimeSpan, ICurrentDateTime } from '../model/timespan';

import * as _ from 'lodash';
import * as moment from 'moment/moment';
import { ServerSettings } from '../config/server-settings';
import { ServerSettingService } from './server-setting.service';
import { ConfigSettings } from 'src/app/config-settings';
import { ConfigSettingsService } from './config-settings.service';
import { ServerSettingsService } from './server-settings.service';
import { IPublicEmployee } from '../model/public-employee';
import { RequirementBlueprintDto } from '../model/requirement-blueprint-dto.model';
//import moment = require('moment/moment');
const MIN: number = 60000;
const HOUR: number = 3600000;

@Directive()
@Injectable({
    providedIn: 'root'
})
export class CalendarService implements OnDestroy
{

    // new format date: 2019.12.20T12:00

    private subscriptions: Subscription[] = [];

    private classame = (<any>this).constructor.name;

    globalSettings: GlobalSettings = new GlobalSettings();
    serverSettings: ServerSettings;
    configSettings: ConfigSettings;

    datesToHighlight: ICalendarDay[] = [];
    //datesToHiglightList: Array<number> =  []; //might be used as observable or array

    dayTimeSpans: ITimeSpan[] = [];

    // so far not used. 
    // but use it in the contact form.
    @Output() notifyDate: EventEmitter<Date> = new EventEmitter<Date>();

    @Output() notifyCalendarDay: EventEmitter<number> = new EventEmitter<number>();

    @Output() requirementsChangedEvent: EventEmitter<RequirementBlueprintDto[]> = new EventEmitter<RequirementBlueprintDto[]>();

    private serviceUrl: string;
    private serviceDayUrl: string;

    //urlApi  = 'https://localhost:44338/api/days/office-id/0253705a-7b6f-43dc-ed8e-08d6c3df9b99'

    private urlApi: string;
    private urlFilePath: string;
    private urlFileName: string;
    private urlDayApi: string;
    private urlDayFilePath: string;
    private nowString: string;
    private now: Date;
    private startDate: Date;
    private urlDayString = '/day/';
    private urlDayFileName = '/calendarday.json';



    // Contains the server date. Will be initalised at the beginning as we have an observable 
    // and the value are required immediatetely in some cases.
    // However in some cases later the value will be reinitialised later

    currentDateTime: ICurrentDateTime;

    // MOC
    //urlDayFile = 'http://localhost:4200/assets/days/office-id/{{officeId}}/day/{{day}}/calendarday.json'; 
    //urlDayFile = 'http://localhost:4200/assets/days/office-id/{{officeId}}/calendarday.json';

    constructor(
        private http: HttpClient,
        private messageService: MessageService,
        private globalSettingService: ServerSettingService,
        private configSettingsService: ConfigSettingsService,
        private serverSettingsService: ServerSettingsService,
    ) { }

    ngOnInit()
    {


        this.configSettings = this.configSettingsService.configSettings;

        this.urlApi = this.configSettings.apiUrl + '/api/days/office-id/';

        //moc
        this.urlFilePath = this.configSettings.clientUrl + 'assets/days/office-id/';
        this.urlFileName = '/calendardays.json';

        this.urlDayApi = this.configSettings.apiUrl + '/api/days/office-id/';

        this.urlDayFilePath = this.configSettings.clientUrl + 'assets/days/office-id/';

        this.subscriptions.push(this.globalSettingService.getServerVariables()
            .subscribe((serverSettings: ServerSettings) =>
            {
                this.serverSettings = serverSettings;
            }
            ));

    }

    // the date which will be booked at the end.
    private _selectedDate: Date;
    get selectedDate(): Date
    {
        return this._selectedDate;
    }

    set selectedDate(date: Date)
    {
        this._selectedDate = date;
        this.notifyDate.emit(this._selectedDate);
    }



    // currently this is today
    // todo: later data paging might be required for long loading times.
    private _earliestDisplayDay: string;
    get earliestDisplayDay(): string
    {
        return this._earliestDisplayDay;
    }
    set earliestDisplayDay(date: string)
    {
        this._earliestDisplayDay = date;
    }

    // currently this is the latest config value.
    // todo: later data paging might be required for long loading times.
    private _latestDisplayDay: string;
    get latestDisplayDay(): string
    {
        return this._latestDisplayDay;
    }
    set latestDisplayDay(date: string)
    {
        this._latestDisplayDay = date;
    }


    // add hours from config to current date.
    getStartDate(dateNow: Date): Date
    {

        if (this.serverSettings == undefined)
        {
            this.ngOnInit();
        }

        let startHours = Math.floor(this.serverSettings.earliestAppointmentStartInHours);

        //TER-510
        // IPAD verhält sich vermutlich falsch
        // es muss ein neues Objekt erzeugt werden, damit das Ursprungsobjekt nicht verändert wird.
        //let newDate = dateNow;
        let newDate = new Date(dateNow);


        // always the best option to work with moment vor conversion
        //let momentDate  = moment(serverDate.serverTime,"YYYY-MM-DDThh:mm");
        //let serverDateHelper = momentDate.toDate();

        let dummy = newDate.setHours((newDate.getHours() + startHours));
        let startDate = new Date(dummy);


        return startDate
    }


    getTimeSpansDate(): Observable<ITimeSpan[]>
    {


        this.dayTimeSpans.forEach(function (e)
        {

            if (e["start"] !== undefined)
            {

                // convert to moment, best option to transform to a date
                e.momentStart = moment(e.start, "YYYY-MM-DDThh:mm");


                //probably better option, but not tested
                //let startdate = e.momentStart.toDate();
                let startdate = new Date(e.momentStart);
                e.startTimeDate = new Date(startdate);
                e.startTimeNumber = new Date(startdate).getTime();
            }
            if (e["end"] !== undefined)
            {
                e.momentEnd = moment(e.end, "YYYY-MM-DDThh:mm");

                let enddate = new Date(e.momentEnd);
                e.endTimeDate = new Date(enddate);
                e.endTimeNumber = new Date(enddate).getTime();
            }
        });


        return of(this.dayTimeSpans);
    }



    //just checks if the api input makes sense
    checkValidSpanDate(): boolean
    {
        let IsOK = true;
        let startDayMidnight = new Date(this.dayTimeSpans[0].startTimeNumber).setHours(0, 0, 0, 0);

        for (let i = 0; i < this.dayTimeSpans.length; i = i + 1)
        {
            if ((i > 0) && (this.dayTimeSpans[i].startTimeNumber < this.dayTimeSpans[i - 1].endTimeNumber))
            {
                this.error(`The start date:${this.dayTimeSpans[i].startTimeNumber} is smaller then the previous enddate ${this.dayTimeSpans[i - 1].endTimeNumber}`);
                IsOK = false;
            }

            let startTime = new Date(this.dayTimeSpans[i].startTimeNumber).setHours(0, 0, 0, 0);
            let endTime = new Date(this.dayTimeSpans[i].startTimeNumber).setHours(0, 0, 0, 0);

            //   if (startTime !== startDayMidnight){
            //     this.error(`The start time is not at the same day:  ${startTime}`);
            //   }
            //   if (endTime !== startDayMidnight){
            //     this.error(`The start time is not at the same day:  ${endTime}`);
            //   }

        }
        return IsOK;
    }

    // here we get the days where available times exists 
    // datesToHighlight
    getLocationCalendarDays(locationid: string, userConcerns: IUserConcern[], employee: IPublicEmployee, nowDate: Date): Observable<ICalendarDay[]>
    {



        if (userConcerns.length === 0)
        {
            this.datesToHighlight = [];
            return of(this.datesToHighlight);
        }

        // the start date from now + configured stared hours.
        var earliestDisplayDay = moment(this.getStartDate(nowDate), "YYYY-MM-DDThh:mm");
        this._earliestDisplayDay = earliestDisplayDay.format("YYYY-MM-DD" + "T00:00");


        // for now startd date + max days (from config) are used.
        var endDate = moment(new Date(), "YYYY-MM-DD").add(this.serverSettings.maxCalculatedDays, 'days');
        this._latestDisplayDay = endDate.format("YYYY-MM-DD") + "T23:59";

        if (GlobalSettings.isEmpty(employee))
        {
            this.serviceUrl = this.urlApi + locationid + "/start/" + this._earliestDisplayDay + "/end/" + this._latestDisplayDay;

            return this.http.post<ICalendarDay[]>(this.serviceUrl, userConcerns).pipe(

                tap(data => this.datesToHighlight = data),

                // tap(data => this.convertDates(data)), 
                catchError(this.handleError<ICalendarDay[]>('get LocationCalendarDays', []))
            );
        }
        else
        {
            // TODO: hier die neue api für days/employee-id/{employeeId}/start/{start}/end/{end}/{forOffline?}
            return this.http.post<ICalendarDay[]>(this.configSettings.apiUrl + "/api/days/employee-id/" + employee.id + "/start/" + this._earliestDisplayDay + "/end/" + this._latestDisplayDay + "/false", userConcerns).pipe(

                tap(data => this.datesToHighlight = data),

                // tap(data => this.convertDates(data)), 
                catchError(this.handleError<ICalendarDay[]>('get LocationCalendarDays', []))
            );
        }

    }

    getLocationCalendarDay(locationid: string, numberDate: number, employee: IPublicEmployee, userConcerns: IUserConcern[]): Observable<any>
    {

        if (userConcerns.length === 0)
        {
            this.dayTimeSpans = [];

            return of(this.dayTimeSpans);
        }


        // build the api path with the correct day value 
        // example: 2019.12.20T00:00
        let thisDate = new Date(numberDate)
        var convertToMoment = moment(thisDate);
        var selDateString = convertToMoment.format("YYYY-MM-DD");
        selDateString = selDateString + "T00:00";
        //selDateString = selDateString + "T00:00";
        //this._monthDate = "2019.29.04T00:00"


        if (GlobalSettings.isEmpty(employee))
        {
            //this.serviceDayUrl = this.urlDayApi;
            this.serviceDayUrl = this.urlDayApi + locationid + this.urlDayString + selDateString;


            return this.http.post<any>(this.serviceDayUrl, userConcerns).pipe(

                tap(data => this.dayTimeSpans = data.timespans),
                tap(data => this.requirementsChangedEvent.next(data.requirementBlueprintDtos)),
                tap(data => this.checkValidSpanDate()),
                tap(data => this.getTimeSpansDate()),
                catchError(this.handleError<ITimeSpan[]>('post LocationCalendarDay', []))
            );

        }
        else
        {
            //days/employee-id/{employeeId}/day/{day}
            return this.http.post<any>(this.configSettings.apiUrl + "/api/days/employee-id/" + employee.id + "/day/" + selDateString, userConcerns).pipe(

                tap(data => this.dayTimeSpans = data.timespans),
                tap(data => this.requirementsChangedEvent.next(data.requirementBlueprintDtos)),
                tap(data => this.checkValidSpanDate()),
                tap(data => this.getTimeSpansDate()),
                catchError(this.handleError<ITimeSpan[]>('post LocationCalendarDay', []))
            );
        }


    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T)
    {
        return (error: any): Observable<T> =>
        {

            this.error(error);

            this.error(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    private error(message: string)
    {
        this.messageService.error(`${this.classame} : ${message}`);
    }



    ngOnDestroy()
    {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}


