
//https://stackblitz.com/angular/kggokxmkobp?file=src%2Fapp%2Fhero-form%2Fhero-form.component.html


// material form
// https://stackblitz.com/edit/example-angular-material-reactive-form?file=app%2Fapp.component.css


// https://www.c-sharpcorner.com/article/angular-material-design-components-with-reactive-form-part-2/


// working with reactive forms:


import { Component, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';

import { ICustomer } from 'src/app/shared/model/customer';
import { UntypedFormBuilder, UntypedFormGroup, AbstractControl, Validators, UntypedFormControl, ValidatorFn, UntypedFormArray } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { LocationService } from 'src/app/shared/services/location.service';
import { ILocation } from 'src/app/shared/model/location';
import { CalendarService } from 'src/app/shared/services/calendar.service';
import { MessageService } from 'src/app/shared/services/messages.service';
// import { jsonpCallbackContext } from '@angular/common/http/src/module';
import { IContactData } from 'src/app/shared/model/contactdata';
import { IUserConcern } from 'src/app/shared/model/userconcern';
import { UserConcernService } from 'src/app/shared/services/userconcern.service';
import { CustomerService } from 'src/app/shared/services/customer.service';
import * as moment from 'moment/moment';
import { GlobalSettings } from 'src/app/shared/config/globalsettings';
import { ApiTranslationLoaderService } from 'src/app/shared/services/api-translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
//import { AppValidators } from 'src/app/shared/validators/app-validators';

import { emailValidator } from 'src/app/shared/validators/app-validators';
import { ServerSettings } from 'src/app/shared/config/server-settings';
import { ServerSettingService } from 'src/app/shared/services/server-setting.service';
import { Title } from '@angular/platform-browser';
import { RequirementBlueprintDto } from 'src/app/shared/model/requirement-blueprint-dto.model';
import { LanguageService } from 'src/app/shared/services/language.service';
import { CustomEnumValueDto } from 'src/app/shared/model/custom-enum-value-dto.model';
import { BookAppointmentDto } from 'src/app/shared/model/book-appointment-dto.model';
// import { DebugRenderer2 } from '@angular/core/src/view/services';


@Component({
    selector: 'app-concactform',
    templateUrl: './concactform.component.html',
    styleUrls: ['./concactform.component.scss']
})


export class ConcactFormComponent implements OnInit, OnDestroy
{

    public requirementBlueprints: RequirementBlueprintDto[] = [];
    private subscriptions: Subscription[] = [];

    private classame = (<any>this).constructor.name;
    translationTitleObject: any;

    public errorObject: any;
    public errorMessage: string = "";

    formIsReadonly: boolean = false;

    public isLoading: boolean = false;

    globalSettings: GlobalSettings = new GlobalSettings();
    translationObject: any;
    serverSettings: ServerSettings;

    contactData: IContactData;


    frmContact: UntypedFormGroup;
    post: any = '';

    displayNumber = false;

    nextStepIsEnabled = false;
    formSuccessFullySent = false;
    formSubmitProcess = false
    // will be used from the stepper for chaning to the next step
    @Output() notifyContactFormSuccessfulySent: EventEmitter<boolean> = new EventEmitter<boolean>();

    /* for text resources */
    headerText: string;


    // automatic change detection.
    // if the value of the service will change
    // the property is beeing notifyed and also changed
    get selectedDate(): Date
    {
        return this.calendarService.selectedDate;
    }

    selectedLocation: ILocation;

    public useReCaptcha: boolean = false;
    public siteKey: string = "";
    public dataProtectionURL: string = "";

    constructor(
        private formBuilder: UntypedFormBuilder,
        private locationService: LocationService,
        private calendarService: CalendarService,
        private messageService: MessageService,
        private userConcernService: UserConcernService,
        private customerService: CustomerService,
        private translate: TranslateService,
        private globalSettingService: ServerSettingService,
        public title: Title,
        private languageService: LanguageService
    )
    {
    }


    checkCheckbox(c: AbstractControl)
    {
        return c.get('checkAGB').value;
    }

    ngOnInit()
    {
        
        this.createForm();
        this.calendarService.requirementsChangedEvent.subscribe((requirementBlueprints: RequirementBlueprintDto[]) =>
        {
            this.frmContact = this.formBuilder.group({
                'requirementValues': this.formBuilder.array([]),
                'checkAGB': [false, Validators.requiredTrue],
                'recaptchaReactive': new UntypedFormControl(null)
            });
            this.requirementBlueprints = [...requirementBlueprints];
            this.requirementBlueprints = this.requirementBlueprints.sort((a: RequirementBlueprintDto, b: RequirementBlueprintDto) => a.sortNumber - b.sortNumber);
            const requirementValues = this.frmContact.get('requirementValues') as UntypedFormArray;

            this.requirementBlueprints.forEach(bp => 
            {
                let newValue = this.formBuilder.group({
                    "blueprintId": bp.blueprintId,
                    "type": bp.type,
                    "value": null
                });

                let validators: ValidatorFn[] = this.getValidatorsForRequirementBlueprint(bp);
                newValue.controls["value"].setValidators(validators);
                newValue.controls["value"].setValue(this.getDefaultValueForRequirementBlueprint(bp));
                requirementValues.push(newValue);
            });
            this.onChanges();
        });
        this.isLoading = false;
        this.subscriptions.push(this.locationService.selectedLocationChanges$.subscribe(
            selectedLocation => this.selectedLocation = selectedLocation
        ));

        this.subscriptions.push(this.globalSettingService.getServerVariables()
            .subscribe((serverSettings: ServerSettings) =>
            {
                this.serverSettings = serverSettings;

                if (!GlobalSettings.isEmpty(this.serverSettings))
                {

                    this.displayNumber = this.serverSettings.useSMSService;
                    this.useReCaptcha = this.serverSettings.useReCaptcha;
                    this.siteKey = this.serverSettings.reCaptchaSiteKey;
                    this.dataProtectionURL = this.serverSettings.dataProtectionURL;
                    if (this.useReCaptcha)
                    {
                        this.frmContact.controls['recaptchaReactive'].setValidators(Validators.required);
                    }
                }
            }
            ));

        this.subscriptions.push(this.customerService.notifyFormSent.subscribe(
            customerFormSent =>
            {
                this.setReadonlyForm(customerFormSent),
                    this.formIsReadonly = true;
            }
        ));

        this.onChanges();
    }

    private getValidatorsForRequirementBlueprint(bp: RequirementBlueprintDto): ValidatorFn[]
    {
        let validators: ValidatorFn[] = [];
        if (bp.isRequiredOnline)
        {
          if(bp.type === 2)
          {
            validators.push(Validators.requiredTrue);
          }
          else
          {
            validators.push(Validators.required);
          }
        }

        // if (bp.isRequiredOnline)
        //     validators.push(Validators.required);


        if (bp.useValidation)
        {
            switch (bp.type)
            {
                // SingleLineText
                case 0:
                    let regexp = new RegExp(bp.regularExpression);
                    let regularExpressionValidator: ValidatorFn = function regExValidator(control: AbstractControl): { [key: string]: boolean } | null 
                    {
                        if (control.value && !regexp.test(control.value))
                        {
                            return { regExValidator: true };
                        }
                    }
                    validators.push(regularExpressionValidator);
                    break;

                // // MultiLineText
                // case 1:
                // break;
                // // Bool
                // case 2:
                // break;

                // Int
                case 3:
                    let intValidator: ValidatorFn = function intValidatorFN(control: AbstractControl): { [key: string]: boolean } | null
                    {
                        if (control.value != null && control.value !== undefined)
                        {
                            try
                            {
                                let val = parseInt(control.value);
                                if (val >= bp.minDoubleValue && val <= bp.maxDoubleValue)
                                {
                                    return;
                                }
                                else
                                {
                                    return { intValidator: true };
                                }
                            }
                            catch
                            {
                                return { intValidator: true };
                            }
                        }
                    }
                    validators.push(intValidator);
                    break;

                // Double
                case 4:
                    let doubleValidator: ValidatorFn = function doubleValidatorFN(control: AbstractControl): { [key: string]: boolean } | null
                    {
                        if (control.value !== null && control.value !== undefined)
                        {
                            try
                            {
                                let val = parseFloat(control.value);

                                if (val >= bp.minDoubleValue && val <= bp.maxDoubleValue)
                                {
                                    return;
                                }
                                else
                                {
                                    return { doubleValidator: true };
                                }
                            }
                            catch
                            {
                                return { doubleValidator: true };
                            }
                        }
                    }
                    validators.push(doubleValidator);
                    break;

                // Date
                case 5:
                    let dateValidator: ValidatorFn = function dateValidatorFN(control: AbstractControl): { [key: string]: boolean } | null
                    {
                        if (control.value)
                        {
                            try
                            {

                                let val = new Date(control.value);

                                if (val >= new Date(bp.minDate) && val <= new Date(bp.maxDate))
                                {
                                    return;
                                }
                                else
                                {
                                    return { dateValidator: true };
                                }
                            }
                            catch
                            {
                                return { dateValidator: true };
                            }
                        }
                    }
                    validators.push(dateValidator);
                    break;

                // // SingleSelect
                // case 6:
                //     break;

                // MultiSelect
                case 7:
                    let multiSelectValidator: ValidatorFn = function multiSelectValidatorFN(control: AbstractControl): { [key: string]: boolean } | null
                    {
                        if (control.value !== null && control.value !== undefined)
                        {
                            try
                            {
                                let val = control.value.length;

                                if (val >= bp.minSelectedValues && val <= bp.maxSelectedValue)
                                {
                                    return;
                                }
                                else
                                {
                                    return { multiSelectValidator: true };
                                }
                            }
                            catch
                            {
                                return { multiSelectValidator: true };
                            }
                        }
                    }
                    validators.push(multiSelectValidator);
                    break;
            }
        }
        return validators;
    }

    private formatDate(date): string
    {
        const d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [year, month, day].join('-');
    }

    private getDefaultValueForRequirementBlueprint(bp: RequirementBlueprintDto): any
    {
        if (!bp.useDefaultValue)
            return null;

        switch (bp.type)
        {
            // SingleLineText
            case 0:
                return bp.defaultTextValue;
                break;

            // MultiLineText
            case 1:
                return bp.defaultTextValue;
                break;

            // Bool
            case 2:
                return bp.defaultBoolValue;
                break;

            // Int
            case 3:
                return bp.defaultDoubleValue;
                break;

            // Double
            case 4:
                return bp.defaultDoubleValue;
                break;

            // Date
            case 5:
                return this.formatDate(new Date(bp.defaultDateValue));
                break;

            // SingleSelect
            case 6:
                return bp.selectedDefaultEnumValues[0];
                break;

            // MultiSelect
            case 7:
                return bp.selectedDefaultEnumValues;
                break;
        }
    }

    public getName(index): string
    {
        if(this.requirementBlueprints && this.requirementBlueprints.length > index)
        {
            var postfix = "";
            // if(this.requirementBlueprints[index].isRequiredOnline)
            // {
            //     postfix = " *"
            // }
            let languageCode = this.languageService.currentLanguage.code.toLocaleLowerCase();
            let local = this.requirementBlueprints[index].localizations.filter(local => local.languageCode.toLowerCase() == languageCode)[0];
            if(local)
            {
                return local.name + postfix;
            }
            else
            {
                let defaultLocal = this.requirementBlueprints[index].localizations.filter(local => local.isDefault)[0];
                if(defaultLocal)
                {
                    return defaultLocal.name + postfix;
                }
                else
                {
                    return "" + postfix;
                }
            }
        }
        
    }


    public getDescription(index): string
    {
        if(this.requirementBlueprints && this.requirementBlueprints.length > index)
        {
            let languageCode = this.languageService.currentLanguage.code.toLocaleLowerCase();
            let local = this.requirementBlueprints[index].localizations.filter(local => local.languageCode.toLowerCase() == languageCode)[0];
            if(local)
            {
                return local.description;
            }
            else
            {
                let defaultLocal = this.requirementBlueprints[index].localizations.filter(local => local.isDefault)[0];
                if(defaultLocal)
                {
                    return defaultLocal.description;
                }
                else
                {
                    return "";
                }
            }
        // return this.requirementBlueprints[index].localizations.filter(local => local.languageCode.toLowerCase() == languageCode)[0].description;
        }
    }

    public getCustomEnumName(bpIndex, enumIndex): string
    {
        if(this.requirementBlueprints && this.requirementBlueprints.length > bpIndex)
        {
        let languageCode = this.languageService.currentLanguage.code.toLocaleLowerCase();
        return this.requirementBlueprints[bpIndex].customEnums[enumIndex].localizations.filter(local => local.languageCode.toLowerCase() == languageCode)[0].name;
        }
    }
    public getCustomEnumTooltip(bpIndex, enumIndex): string
    {
        if(this.requirementBlueprints && this.requirementBlueprints.length > bpIndex)
        {
        let languageCode = this.languageService.currentLanguage.code.toLocaleLowerCase();
        return this.requirementBlueprints[bpIndex].customEnums[enumIndex].localizations.filter(local => local.languageCode.toLowerCase() == languageCode)[0].description;
        }
    }
    public getSortedCustomEnums(index): CustomEnumValueDto[]
    {
        if(this.requirementBlueprints && this.requirementBlueprints.length > index)
        {
        return this.requirementBlueprints[index].customEnums.sort((a,b) => a.sortNumber - b.sortNumber);
        }
    }
   
    public getValidationError(index): string
    {
        let languageCode = this.languageService.currentLanguage.code.toLocaleLowerCase();
        return this.requirementBlueprints[index].localizations.filter(local => local.languageCode.toLowerCase() == languageCode)[0].validationMessage;
    }
    getRessourceText(val: string): string
    {

        this.translationObject = this.translate.get(val);
        if (this.translationObject.value !== undefined)
            return (this.translationObject.value)

        return "";
    }

    onChanges(): void
    {
        this.subscriptions.push(this.frmContact.valueChanges.subscribe(val =>
        {
            this.errorMessage = "";
            this.nextStepIsEnabled = this.frmContact.valid;
        }));
    }

    createForm()
    {
        this.frmContact = this.formBuilder.group({
            'requirementValues': this.formBuilder.array([]),
            'checkAGB': [false, Validators.requiredTrue],
            'recaptchaReactive': new UntypedFormControl(null)
        });

        this.nextStepIsEnabled = this.frmContact.valid;
    }

    resolved(captchaResponse: string)
    {

    }

    submitForm(): void
    {

        if (this.frmContact.valid)
        {
            if (this.frmContact.dirty)
            {
                this.isLoading = true;
                this.formSubmitProcess = true;

                const data = {...this.frmContact.value};
                let bookAppointmentDto = new BookAppointmentDto();
                bookAppointmentDto.requirementValues = data.requirementValues;
                
                let employee = this.userConcernService.getEmployee();
                if (GlobalSettings.isEmpty(employee))
                {
                    bookAppointmentDto.id = this.selectedLocation.id;
                }
                else
                {
                    bookAppointmentDto.id = employee.id;
                }

                let thisDate = new Date(this.calendarService.selectedDate)
                var convertToMoment = moment(thisDate);

                var selDateString = convertToMoment.format("YYYY-MM-DDTHH:mm");
                bookAppointmentDto.start = selDateString;


                // setTimeout(() => this.isLoading = false, 10000);
                bookAppointmentDto.selectedServiceDtos = this.userConcernService.getUserConcerns();

                this.subscriptions.push(this.customerService.saveData(bookAppointmentDto, employee)
                    .subscribe((responseStatus) =>
                    {
                        this.isLoading = false;
                        this.formSuccessFullySent = true;
                        this.notifyContactFormSuccessfulySent.emit(this.formSuccessFullySent);
                        this.customerService.customerFormSent = true;

                        // set the notify service which leads to set the form to readonly
                        this.customerService.notifyFormSent.emit(true);

                        // delete the user concerns
                        this.userConcernService.deleteUserConcerns();

                        this.nextStepIsEnabled = true;
                        this.setReadonlyForm(true);
                        this.formSubmitProcess = false;
                    },
                        (error) =>
                        {
                            this.isLoading = false;
                            this.formSubmitProcess = false;
                        },
                        () => 
                        { // for some reason the error ends up here
                            this.isLoading = false;
                            this.formSubmitProcess = false;
                        }
                    ));
            }
        } else
        {

            if (!this.formIsReadonly)
                this.errorMessage = 'Please correct the validation errors.';
        }
    }

    setReadonlyForm(isSent: boolean)
    {
        this.frmContact.disable()
        this.nextStepIsEnabled = true;
    };

    ngOnDestroy()
    {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

}