

<div class="mat-app-background basic-container concern_list-content" *ngFor="let concern of concerns">
    <div class="list-item item-01" *ngIf="showInfo">
        <!-- dev mode oder inhaltstext, außerderdem situation in einigen texten vorhanden, oder komplett ller  -->

        <button mat-icon-button (click)="openDialog(concern);" onclick="this.blur();"
            matTooltip="{{'CONCERN.MORE_DETAILS' | translate}}">
            <mat-icon  class="info-button-colors">info</mat-icon>
        </button>
    </div>

    <div class="list-item item-02">
        {{concern | defaultTranslation : 'name' }}
        <div style="display: inline" *ngIf="showServiceDuration && concern.maxRepetitions > 1"
            matTooltip="{{'CONCERN.TOOLTIP_DURATION_SERVICE' | translate}}">
            ({{concern.initialDuration}}/{{concern.consecutiveDuration}} {{'SETTINGS.MINUTE' | translate}})</div>
        <div style="display: inline" *ngIf="showServiceDuration && concern.maxRepetitions == 1"
            matTooltip="{{'CONCERN.TOOLTIP_DURATION_SERVICE' | translate}}">({{concern.initialDuration}}
            {{'SETTINGS.MINUTE' | translate}})</div>
    </div>
    <div class="list-item item-03" *ngIf="showServiceDuration"
        matTooltip="{{'CONCERN.TOOLTIP_SELECTED_DURATION_SERVICE' | translate}}">
        {{'CONCERN.DURATION_TEXT' | translate | userSettings:calculateDuration(concern)}}
    </div>

    <div class="list-item item-04" style="display:flex; flex-direction:row; align-items:center;">
        <button mat-icon-button (click)="changeUserConcern(concern,'remove')" [disabled]="!concern.reduceEnabled"  [ngClass]="{'substract-button-colors': concern.reduceEnabled == true}">
            <mat-icon [ngClass]="{'substract-button-colors': concern.reduceEnabled == true}">remove_circle_outline</mat-icon>
        </button>
        {{concern.countSelected}}
        <!-- <span class="example-fill-remaining-space"></span> -->
        <button mat-icon-button (click)="changeUserConcern(concern,'add')" [disabled]="!concern.addEnabled"   [ngClass]="{'add-button-colors': concern.addEnabled == true}">
            <mat-icon [ngClass]="{'add-button-colors': concern.addEnabled == true}">add_circle_outline</mat-icon>
        </button>
    </div>

</div>