
import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';

import { IUserConcern } from 'src/app/shared/model/userconcern';
import { UserConcernService } from '../../shared/services/userconcern.service';
import { ConcernService } from 'src/app/shared/services/concern.service';
import { IConcern } from 'src/app/shared/model/concern';
import { MessageService } from 'src/app/shared/services/messages.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-userconcern',
  templateUrl: './userconcern.component.html',
  styleUrls: ['./userconcern.component.scss']
})



export class UserconcernComponent   implements OnInit, OnDestroy {


//todo remove component!

private subscriptions : Subscription[] = [];

  locationResource = 'Standord:';
  
  title = 'Bereits gewählte Anliegen:';
  userConcernEmpty = 'kein Anliegen ausgewählt';
  appointmentContinue = 'zur Terminvereinbarung';

  userconcerns: IUserConcern[];
  concerns: IConcern[];

  private classame = (<any>this).constructor.name;


  constructor(
    private userconcernService: UserConcernService, 
    private concernService: ConcernService,
    private messageService: MessageService
  ) { }

  ngOnInit() {

    this.getConcerns();
    this.getUserConcerns()

  }


  getConcerns(): void {
    this.subscriptions.push(this.concernService.getConcerns().subscribe(
      concerns => this.concerns = concerns
    ));
  }

  getUserConcerns(): void {
    this.subscriptions.push(this.userconcernService.getUserConcerns$().subscribe(userconcerns => {
      this.userconcerns = userconcerns
    }));
  }


  add(userconcern: IUserConcern): void {

  }

  delete(userconcern: IUserConcern): void {

    if (userconcern.quantity === 1) {
     
      this.userconcernService.deleteUserConcern(userconcern);

      this.userconcerns = this.userconcerns.filter(x => x !== userconcern);
    } else {
    
    
    }
  }

  deleteAllData(): void {
   
  }

  deleteUserConcerns(): void{
   
  }
  


  ngOnDestroy()
  {
      this.subscriptions.forEach(s => s.unsubscribe());
  }
}
