import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom, Observable } from "rxjs";
import { AppointmentDetailDto } from "src/app/shared/model/appointment.model";
import { ICalendarDay } from "src/app/shared/model/calendarday";
import { IPublicEmployee } from "src/app/shared/model/public-employee";
import { ITimeSpan } from "src/app/shared/model/timespan";
import { ConfigSettingsService } from "src/app/shared/services/config-settings.service";
import { ShiftAppointmentDto } from "./ShiftAppointment.model";



@Injectable({
    providedIn: 'root'
})

export class ShiftAppointmentService
{

    private baseURL : string;
    constructor
    (
        private httpClient : HttpClient,
        private configSettingsService: ConfigSettingsService,
    )
    {
        this.baseURL = configSettingsService.configSettings.apiUrl;
    }
    
    // Returns the Appointment where the ShiftAppointmentCode and Email match.
    public getAppointment(code: string, email: string): Observable<AppointmentDetailDto>
    {
        return this.httpClient.post<AppointmentDetailDto>(this.baseURL + "/api/appointment/shift/get-appointment-details", {code: code, email: email});
    }

    public async getPossibleDays(appointmentId: string, target: number, targetId: string): Promise<Array<ICalendarDay>>
    {
        return await lastValueFrom(this.httpClient.get<Array<ICalendarDay>>(this.baseURL + "/api/appointment/shift/get-days/" + appointmentId + "/" + target + "/" + targetId));
    }

    public async getPossibleTimespans(appointmentId: string, target: number, targetId: string, selectedDate: string): Promise<Array<ITimeSpan>>
    {
        // appointment/shift/get-timespans/{appointmentId}/{target}/{targetId}/{selectedDate}
        return await lastValueFrom(this.httpClient.get<Array<ITimeSpan>>(this.baseURL + "/api/appointment/shift/get-timespans/" + appointmentId + "/" + target + "/" + targetId + "/" + selectedDate));
    }

    public getPublicEmployees(appointmentId: string): Observable<Array<IPublicEmployee>>
    {
        return this.httpClient.get<IPublicEmployee[]>(this.baseURL + "/api/public-employees/" + appointmentId);
    }

    public async shiftAppointment(dto: ShiftAppointmentDto): Promise<void>
    {
        return await lastValueFrom(this.httpClient.post<void>(this.baseURL + "/api/appointment/shift", dto));
    }
}