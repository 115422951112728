import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  ViewContainerRef,
  TemplateRef,
  ChangeDetectorRef,
  OnDestroy,
} from "@angular/core";
import { FormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ConcernComponent } from "src/app/components/concern/concern.component";
import { LocationComponent } from "src/app/components/location/location.component";
import { ConcactFormComponent } from "src/app/components/concactform/concactform.component";
import { AppointmentcalendarComponent } from "src/app/components/appointmentcalendar/appointmentcalendar.component";
import { MatStep } from "@angular/material/stepper"; //, MatHorizontalStepper
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { ConcernGroupComponent } from "src/app/components/concerngroup/concerngroup.component";
import { LocationService } from "../services/location.service";
import { ILocation } from "../model/location";
import { observable, Observable, Subscription } from "rxjs";
import { CalendarService } from "../services/calendar.service";
import { MessageService } from "../services/messages.service";
import { ConfirmationComponent } from "src/app/components/confirm-appointment/confirmation.component";
import { IContactData } from "../model/contactdata";
import { ICustomer } from "../model/customer";
import * as moment from "moment/moment";
import { UserConcernService } from "../services/userconcern.service";
import { CustomerService } from "../services/customer.service";
import { GlobalSettings } from "../config/globalsettings";
import { MediaMatcher } from "@angular/cdk/layout";
import { StepperSelectionEvent } from "@angular/cdk/stepper";
import { ConfigSettingsService } from "../services/config-settings.service";
import { ConfigSettings } from "src/app/config-settings";
import { TranslateService } from "@ngx-translate/core";
import { ConcernService } from "../services/concern.service";
import { MatStepper } from "@angular/material/stepper";
import { Title } from "@angular/platform-browser";

// this components is the routing start point for all other pages

export enum pageIndex {
  concern = 0,
  location,
  appointmentCalender,
  contactForm,
  confirmation,
}

@Component({
  selector: "app-stepper",
  templateUrl: "./stepper.component.html",
  styleUrls: ["./stepper.component.scss"],
})
export class StepperComponent implements OnInit, OnDestroy {
  private classame = (<any>this).constructor.name;
  private subscriptions: Subscription[] = [];

  configSettings: ConfigSettings;
  globalSettings: GlobalSettings = new GlobalSettings();

  translationTitleObject: any;

  stepperVertical: boolean;
  stepperHorizontal: boolean;

  mobileQuery: MediaQueryList;

  @ViewChild("stepper") stepper!: MatStepper;

  //if enabled stepping only possible, if previous is finished.
  isLinear = true;

  @ViewChild(ConcernGroupComponent)
  concernGroupFormGroup!: ConcernGroupComponent;
  @ViewChild(LocationComponent) locationFormGroup!: LocationComponent;
  @ViewChild(ConcactFormComponent) contactFormGroup!: ConcactFormComponent;
  @ViewChild(AppointmentcalendarComponent)
  appointmentCalenderFormGroup!: AppointmentcalendarComponent;
  @ViewChild(ConfirmationComponent)
  confirmationFormGroup!: ConfirmationComponent;

  // handle the location
  selectedLocation: ILocation | undefined;

  constructor(
    //private formBuilder: FormBuilder,
    //private router: Router,
    private location: Location,
    private locationService: LocationService,
    private messageService: MessageService,
    private concernservice: ConcernService,
    //private customerService: CustomerService,
    private configSettingsService: ConfigSettingsService,
    // changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private translate: TranslateService,
    private title: Title,
    private route: ActivatedRoute
  ) {
    this.configSettings = this.configSettingsService.configSettings;
    this.mobileQuery = media.matchMedia(
      `(max-width: ${this.configSettings.SMALL_WIDTH_BREAKPOINT}px)`
    );

    // when canging devices the stepper can't handle, and horizontal vertikal do not changes
    //this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    // //this.mobileQuery.addListener(this._mobileQueryListener);

    // not change the device anymore after intialsation.
    this.stepperVertical = this.mobileQuery.matches;
    this.stepperHorizontal = !this.stepperVertical;
  }

  stepToInitPage(navUrl: string): void {
    // don't now if it is the best postition but at least it works.
    this.subscriptions.push(
      this.translate
        .get(["APPLICATION_TITLE", "MENU.BOOK_APPOINTMENT"])
        .subscribe((translation: [string]) => {
          this.translationTitleObject = translation;
          this.title.setTitle(
            this.translationTitleObject["APPLICATION_TITLE"] +
              " > " +
              this.translationTitleObject["MENU.BOOK_APPOINTMENT"]
          );
        })
    );

    if (navUrl.startsWith("/location")) {
      this.stepper.selectedIndex = pageIndex.location;
    } else if (navUrl.startsWith("/appointmentcalendar")) {
      //appointment calendar page
      this.stepper.selectedIndex = pageIndex.appointmentCalender;
    } else if (navUrl.startsWith("/contactform")) {
      //appointment calendar page
      this.stepper.selectedIndex = pageIndex.contactForm;
    } else if (navUrl.startsWith("/confirmation")) {
      //confirmation page.
      this.stepper.selectedIndex = pageIndex.confirmation;
    }
  }

  ngOnInit() {
    const param = this.route.snapshot.paramMap.get("init");
    if (param) {
      if (param == "init") {
        // delete the concern form again when renavigating.
        this.concernservice.emptyConcernSelectForm();
      }
    }

    this.subscriptions.push(
      this.locationService.selectedLocationChanges$.subscribe(
        (selectedLocation) => (this.selectedLocation = selectedLocation)
      )
    );
  }

  ngAfterViewInit() {
    this.stepToInitPage(this.location.path());
  }

  // validations of the steps if component are loaded
  // or empty FormGroup, mehtods will be called more then once.
  private emtpyFormGroup = {} as UntypedFormGroup;

  get stepConcernCheck(): UntypedFormGroup {
    return this.concernGroupFormGroup
      ? this.concernGroupFormGroup.frmConcern
      : this.emtpyFormGroup;
  }

  get stepLocationCheck(): UntypedFormGroup {
    return this.locationFormGroup
      ? this.locationFormGroup.frmLocation
      : this.emtpyFormGroup;
  }

  get stepAppointmentCalendarCheck(): UntypedFormGroup {
    return this.appointmentCalenderFormGroup
      ? this.appointmentCalenderFormGroup.form
      : this.emtpyFormGroup;
  }

  get stepContactFormCheck(): UntypedFormGroup {
    return this.contactFormGroup
      ? this.contactFormGroup.frmContact
      : this.emtpyFormGroup;
  }

  get stepConfirmationFormCheck(): UntypedFormGroup {
    return this.confirmationFormGroup
      ? this.confirmationFormGroup.frmConfirmation
      : this.emtpyFormGroup;
  }

  // react on concern form changes.
  notifyNumberOfConcerns(event: number): void {
    // the calendar entry is deleted and the related form unvalid.
    // clarify if this is the wished behavior

    // values are deleted from other forms
    this.appointmentCalenderFormGroup.form.get("selectedDateWithTime")?.setValue(null);
    this.locationFormGroup.frmLocation.get("locationChoice")?.setValue(null);
  }

  // react on location change
  notifyLocationChange(event: ILocation | undefined): void {
    // the selected date is deleted, if the location has changed.
    this.appointmentCalenderFormGroup.form.get("selectedDateWithTime")?.setValue(null);
  }

  selectionChange(event: any, stepper: MatStepper) {
    if (
      event.selectedIndex === pageIndex.location &&
      event.previouslySelectedIndex === pageIndex.concern
    ) {
      // here the locations will be loaded from the api if loaded locations are invalid.
      if (this.locationService.loadedLocationsInvalid) {
        this.locationFormGroup.getLocations();
      }
    } else if (
      event.selectedIndex === pageIndex.appointmentCalender &&
      event.previouslySelectedIndex === pageIndex.location
    ) {
      // switching from the location to the calendar.

      let locationId =
        this.locationFormGroup.frmLocation.get("locationChoice")?.value;
      // test if the service can be called here

      // TER-390
      let location: ILocation | undefined =
        this.locationFormGroup.locations.find((x) => x.id === locationId);
      this.locationService.changeSelectedLocation(location);
    } else if (
      event.selectedIndex === pageIndex.contactForm &&
      event.previouslySelectedIndex === pageIndex.appointmentCalender
    ) {
      //init the error message again, there might be old ones from previous contact form
      this.contactFormGroup.errorMessage = "";
    }
  }

  notifyContactFormSuccessfulySent() {
    this.stepper.selectedIndex = pageIndex.confirmation;
  }

  private error(message: string) {
    this.messageService.error(`${this.classame} : ${message}`);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
