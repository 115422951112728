<div class="overlay-page">
  <h1 mat-dialog-title>{{concern.concern | defaultTranslation : 'name' }}</h1>
  <div mat-dialog-content>
      <div [innerHTML]="concern.concern | defaultTranslation : 'description' | nl2pbr"></div>  
  <!-- <mat-form-field>   
    </mat-form-field> -->
  </div>
  <div mat-dialog-actions >
    <button mat-raised-button (click)="onClick()" matTooltip="{{'CONCERN.DETAIL_OK_TOOLTIIP' | translate}}">{{'BUTTONS.OK' | translate}}</button>
  </div>
</div>