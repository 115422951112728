import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { ILocation } from 'src/app/shared/model/location';
import { Subscription, of, Observable } from 'rxjs';
import { LocationService } from 'src/app/shared/services/location.service';
import { CalendarService } from 'src/app/shared/services/calendar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalSettings } from 'src/app/shared/config/globalsettings';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { ConfigSettingsService } from 'src/app/shared/services/config-settings.service';
import { LanguageService } from 'src/app/shared/services/language.service';
// import { tick } from '@angular/core/src/render3';
import { DOCUMENT } from '@angular/common';

/* page is displayed in stepper as a seperate page and also as direct link confirmation page.*/
@Component({
    selector: 'app-confirmation',
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit, OnDestroy
{

    public ticketNumber: string;
    public displayTicketNumber: boolean;

    private subscriptions : Subscription[] = [];
    translationTitleObject: any;
    globalSettings: GlobalSettings = new GlobalSettings();
    frmConfirmation: UntypedFormGroup;
    successMessage: string;

    // errorMessage: string;
    //errorMessageConfirmation: string;
    controlCodeInput: string;
    showConfirmationForm: boolean;
    invalidValidation: boolean = false;
    confirmError: boolean

    // flag of postback of link confirmation message.  
    // will be set to true, after successful api call
    // Can fail because of invalid e-mail adress of employee
    linkConfirmationFinished: boolean
    linkConfirmationActivated: boolean

    //title:string;
    showTitleInStepper: boolean;
    showSelection = false;
    isInStep: boolean = false;
    finishStepIsEnabled: boolean = false;
    contactFormSuccesful: Observable<boolean>;

    selectedLocation: ILocation;
    code: string;
    lang: string;
    translationObject: any;

    public isLoading: boolean = false;


    //translationTimeObject: any;
    constructor(
        private formBuilder: UntypedFormBuilder,
        private locationService: LocationService,
        private customerService: CustomerService,
        private calendarService: CalendarService,
        private route: ActivatedRoute,
        private router: Router,
        private translate: TranslateService,
        private configSettingsService: ConfigSettingsService,
        private languageService: LanguageService,
        public title: Title,
        @Inject(DOCUMENT) private _document: Document
    )
    {
    }

    ngOnInit()
    {

        this.linkConfirmationFinished = false;
        this.linkConfirmationActivated = false;
        this.confirmError = false;
        // change if there should be a different implementation
        this.showTitleInStepper = false;

        this.showConfirmationForm = true;
        this.frmConfirmation = this.formBuilder.group({
            'controlCode': [null, Validators.required],
        });
        this.finishStepIsEnabled = this.frmConfirmation.valid;

        if (this.contactFormSuccesful)
        {

            this.showConfirmationForm = true;
        }

        this.frmConfirmation.get('controlCode').clearValidators();
        this.frmConfirmation.get('controlCode').setValidators(Validators.required);
        this.frmConfirmation.get('controlCode').updateValueAndValidity();


       
        // delete the error message for changing input
        // and display the button if valid.
        this.subscriptions.push(this.frmConfirmation.get('controlCode').valueChanges.subscribe(() =>
        {
            //this.errorMessage = "",
            this.confirmError = false
            // second condition because valid is still false for pasting text.
            this.finishStepIsEnabled = (this.frmConfirmation.valid || this.frmConfirmation.get('controlCode').dirty);

        }));

        this.subscriptions.push(this.locationService.selectedLocationChanges$.subscribe(
            selectedLocation =>
            {
                this.selectedLocation = selectedLocation,
                    this.showSelection = true,
                    this.isInStep = true
            }
        ));


        

        if (!this.isInStep)
        {
            this.subscriptions.push( this.translate.get(['APPLICATION_TITLE', 'MENU.CODE_CONFIRM']).subscribe((translation: [string]) =>
            {

                this.translationTitleObject = translation;
                this.title.setTitle(this.translationTitleObject['APPLICATION_TITLE'] + " > " + this.translationTitleObject['MENU.CODE_CONFIRM']);
            }));
            
        }




        // check direct confirmation link.
        const paramCode = this.route.snapshot.paramMap.get('code');
        if (paramCode)
        {
            this.code = paramCode;
            this.subscriptions.push( this.confirmation_page(this.code).subscribe(confirm =>
            {
                this.showConfirmationForm = !confirm;
            }
            ));
           
        }

        const paramLang = this.route.snapshot.paramMap.get('lang');
        if (paramLang)
        {
            this.lang = paramLang;
            this.languageService.setLanguageFromCode(this.lang);
            // this.languageService.changeDefaultLang();
            this.translate.setDefaultLang(this.lang);
        }
    }

    getRessourceText(val: string): string
    {

        this.translationObject = this.translate.get(val);
        if (this.translationObject.value !== undefined)
            return (this.translationObject.value)

        return "";
    }

   

    //detection for automatic changes
    get selectedDate(): Date
    {
        return this.calendarService.selectedDate;
    }


    getReplaceText(value: string): string
    {


        this.translationObject = this.translate.get(value);

        if (this.translationObject !== undefined && this.translationObject.value !== undefined)
        {


            let str = this.translationObject.value;
            if (this.selectedLocation !== undefined)
            {
                str = str.replace("{{location}}", this.selectedLocation.name);
            }
            if (this.selectedDate !== undefined)
            { //we replace the 
                let time = this.selectedDate.toLocaleTimeString().substring(0, 5);

                let date = this.selectedDate.toLocaleDateString(this.configSettingsService.configSettings.cultureSetting); //, this.configSettingsService.dateDisplayOptions


                str = str.replace("{{selectedDate}}", date + " " + time);
            }
            return (str);

        }

        return "";
    }



    // automatische Link Bestätigungsseite 
    confirmation_page(code: string): Observable<boolean>
    {

        // TER-541

        // nicht klar ob das drin bleibt, da die fehlervalidation message angezeigt wird bis zu dem 
        // zeitpunkt, bis der API Call zurück kommt. 
        // wahrscheinlich bessere Lösung den 500er Fehler im Intereptor durchzulassen. 
        this.linkConfirmationActivated = true;

        if (code)
        {
            this.isLoading = true;
            this.subscriptions.push( this.customerService.confirmCode(code)
                .subscribe((ticket) =>
                {

                    if(ticket != null && ticket !== undefined && ticket !== "" && ticket.ticket !== null && ticket.ticket !== undefined && ticket.ticket !== "")
                    {
                        this.ticketNumber = ticket.ticket;
                        this.displayTicketNumber = true;
                    }
                    this.invalidValidation = false;
                    this.finishStepIsEnabled = false;
                    this.confirmError = false;
                    this.showConfirmationForm = false;
                    this.isLoading = false;
                    return of(true);
                },
                    error =>
                    {
                        this.isLoading = false;
                        this.invalidValidation = true;
                        this.onCallbackSubmit(error.status);
                        return of(false);
                    },
                    () =>
                    {
                        this.invalidValidation = true;
                        this.isLoading = false;
                        return of(false);
                    }
                ));
           
        }

        return of(true);
    }





    // 3 different submits.
    // one directly from the form outsite the stepper and one callback from the stepper and one with url parameters.
    submit(): void
    {

        //disble button for second try. Will be enabled again after post back.
        this.finishStepIsEnabled = false;

        let controlCode = this.frmConfirmation.get('controlCode').value;

        if ((this.frmConfirmation.valid) && (this.frmConfirmation.dirty))
        {
            this.isLoading = true;
            this.subscriptions.push(this.customerService.confirmCode(controlCode)
                .subscribe((ticket) =>
                {
                    // if(responseStatus.body !== null && responseStatus.body !== undefined && responseStatus.body !== "" )
                    // {
                    //     this.ticketNumber = responseStatus.body;
                    //     this.displayTicketNumber = true;
                    // }
                    if(ticket != null && ticket !== undefined && ticket !== "" && ticket.ticket !== null && ticket.ticket !== undefined && ticket.ticket !== "")
                    {
                        this.ticketNumber = ticket.ticket;
                        this.displayTicketNumber = true;
                    }
                    
                    this.invalidValidation = false;
                    this.finishStepIsEnabled = false;
                    this.confirmError = false;
                    this.showConfirmationForm = false;
                    this.isLoading = false;
                    // this.onCallbackSubmit(responseStatus.status);
                },
                    error =>
                    {
                        //fehlercode 500 (internal server error) 
                        // wird vom interceptor abgefangen und nicht ausgewertet. 
                        this.isLoading = false;
                        this.invalidValidation = true;
                        this.onCallbackSubmit(error.status);
                    },
                    () =>
                    {
                        this.isLoading = false;
                        this.invalidValidation = true;
                    }
                ));
          
        }
    }
    onCallbackSubmit(status: number): void
    {
        switch (status)
        {
            case 200: {

                //this.errorMessage = "";
                this.confirmError = false;
                this.showConfirmationForm = false;
                //this.finishStepIsEnabled = true;
                break;
            }
            case 404: {


                //this.translationObject = this.translate.get("CONFIRMATION.CONFIRM_ERROR_IN_CODE");

                //this.errorMessage = this.errorMessageConfirmation;
                this.confirmError = true;
                this.showConfirmationForm = true;
                this.finishStepIsEnabled = true; //next try
                break;
            }
            // case 400: { 
            //   this.toDoMessage("Nachricht Fehler: die Daten wurden nicht richtig übertragen und der Termin konnte nicht gebucht werden.");
            //    break; 
            // } 
            case 500: {

                break;
            }
            default: {
                //this.errorMessage = "Unbekannter Fehler.";
                break;
            }
        }
    }


    public bookAnother(): void
    {
        // if(this.route != 'book-appointment')
        //     this.router.navigate(['book-appointment']);

        let curRoute = this.router.url;
        if (curRoute == '/book-appointment/init')
        {
            this._document.defaultView.location.reload();
            return;
        }
        else
        {
            this.router.navigate(['book-appointment/init']);
        }
        
        
    }

    ngOnDestroy(): void
    {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
