import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { QFlexTicketInfoDto } from './q-flex-ticket-info-dto.model';
import { QFlexTicketInfoService } from './q-flex-ticket-info.service';

@Component({
    selector: 'app-q-flex',
    templateUrl: './q-flex.component.html',
    styleUrls: ['./q-flex.component.scss']
})
export class QFlexComponent implements OnInit
{

    private translationTitleObject: string[];
    public dto: QFlexTicketInfoDto;
    public searchDisabled: boolean = true;
    constructor(private ticketInfoService: QFlexTicketInfoService,
         private route: ActivatedRoute, 
         public title: Title,
         private translate: TranslateService) { }

    ngOnInit(): void
    {
        const ticket = this.route.snapshot.paramMap.get('ticket');
        if (ticket)
        {
            (<HTMLInputElement> document.getElementById("ticketInput")).value = ticket;
            this.check();
        }

        this.translate.get(['APPLICATION_TITLE','MENU.Q-FLEX']).subscribe((translation: [string]) => {
            this.translationTitleObject = translation;
            this.title.setTitle(this.translationTitleObject['APPLICATION_TITLE'] + " > " + this.translationTitleObject['MENU.Q-FLEX']);
          });
    }

    public check(): void
    {
        var input = (<HTMLInputElement> document.getElementById("ticketInput")).value;
        this.ticketInfoService.getTicketInfo(input).subscribe(result => this.dto = result);
        this.searchDisabled = true;
        setTimeout(() => this.inputChanged(), 10000);
    }

    public inputChanged():void 
    {
        this.searchDisabled = (<HTMLInputElement> document.getElementById("ticketInput")).value.length <= 0;
    }

}
