import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})

// not used yet. from Kurata course. Maybe use for later
export class FilterComponent implements OnInit, AfterViewInit, OnChanges {
  
  @Input() displayDetail: boolean;
  @Input() hitCount: number;
  
  hitMessage: string;
  @ViewChild('filterElement', { static: true }) filterElementRef: ElementRef;

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
 
   
  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {

    //focus element
    if (this.filterElementRef.nativeElement){
        this.filterElementRef.nativeElement.focus();
    }
  }
 
  // getter and setter for the input field.
  private _listFilter: string;
  get listFilter(): string {
    return this._listFilter;
  }
  set listFilter(value: string) {
    this._listFilter = value;
    this.valueChange.emit(value);
  }

  //react on changes of the input hitcount
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['hitCount'] && !changes['hitCount'].currentValue) {
      this.hitMessage = 'No matches found';
    } else {
      this.hitMessage = 'Hits:' + this.hitCount;
    }
  }



  
  


}
