<div style="display:flex; flex-direction: row; align-items: center; justify-content: center;">
    <span class="material-icons" style="font-size: 32px;">warning_amber</span>
</div>
<br />
<div mat-dialog-content style="display:flex; flex-direction:row; justify-content:center; align-items:center;">
    <div id="content"></div>
 </div>
 <br>
 <div mat-dialog-actions style="display:flex; flex-direction:row; justify-content:space-between; align-items:center;">
    <!-- <button *ngIf="onlyOk === true" mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial><mat-icon class="material-icons">done</mat-icon>Ok</button> -->
   <button mat-raised-button style="background-color: #f44336 !important;" [mat-dialog-close]="true" cdkFocusInitial>
    <div style="display:flex; flex-direction:row; align-items:center;">
        <mat-icon class="material-icons">done</mat-icon>
        <div>{{yes}}</div>
    </div>
    </button>
   <button mat-raised-button  (click)="onNoClick()">
        <div style="display:flex; flex-direction:row; align-items:center;">
        <mat-icon class="material-icons">close</mat-icon>
        <div>{{no}}</div>
        </div>
    </button>
 </div>