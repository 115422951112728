

<div class="stepper-container">
    <h2 style="text-align: center;">{{'Q-FLEX.Title' | translate}}</h2>
    <p style="text-align: center;">{{'Q-FLEX.TicketInformationExplanaition' | translate}}</p>
    <div class="center" style="display:flex; flex-direction: row;  justify-content: center;">
        <div style="display:flex; flex-direction: row; justify-content: space-evenly; align-items: baseline; width: 100%; gap:10px;">
            <mat-form-field >
                <mat-label>{{'Q-FLEX.TicketInput' | translate}}</mat-label>
                <input matInput id="ticketInput" autocomplete="off"  placeholder="{{'Q-FLEX.TicketInput' | translate}}" matTooltip="{{'Q-FLEX.TicketInputTooltip' | translate}}" (keyup)="inputChanged()" required />
            </mat-form-field>
            <button mat-raised-button (click)="check()" [disabled]="searchDisabled">
                <div style="display:flex; flex-direction:row; justify-content:center; align-items:center;">
                    <mat-icon>search</mat-icon>
                </div>
            </button>
        </div>
    </div>

    <div *ngIf="dto != undefined && dto != null" class="center">
        <br />
        <div *ngIf="dto.successfull">
            <h3 style="text-align: center;">{{'Q-FLEX.WaitingCustomers' | translate}} {{dto.waitingCustomers}}</h3>
            <h3 style="text-align: center;" *ngIf="dto.averageWaitingTime>0">{{'Q-FLEX.AverageWaitingTime' | translate}} {{dto.averageWaitingTime}}</h3>
            <h3 style="text-align: center;" *ngIf="dto.averageWaitingTime <= 0">{{'Q-FLEX.NoWaitingTimeInformation' | translate}}</h3>
        </div>
        <div *ngIf="!dto.successfull">
            <h3 style="text-align: center;">{{'Q-FLEX.NoInformationAvailable' | translate}}</h3>
        </div>
    </div>
</div>
