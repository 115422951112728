

// implement an init
// https://www.tektutorialshub.com/angular/angular-how-to-use-app-initializer/


// https://davembush.github.io/where-to-store-angular-configurations/

import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap, reduce } from 'rxjs/operators';
import { IConcern } from '../model/concern';
import { Injectable } from '@angular/core';
import { MessageService } from './messages.service';
import { GlobalSettings } from '../config/globalsettings';
import { CustomerService } from './customer.service';
import { ConfigSettings } from 'src/app/config-settings';
import { ConfigSettingsService } from './config-settings.service';
import { UserConcernService } from './userconcern.service';
import { IConcernGroupCounter } from '../model/concerngroup';
import { IPublicEmployee } from '../model/public-employee';
//import { ConfigSettings } from '/config-settings';


//import { TestSettings } from '../config/config-settings';




@Injectable({
    providedIn: 'root'
})


export class ConcernService
{


    private classame = (<any>this).constructor.name;

    configSettings: ConfigSettings;
    globalSettings: GlobalSettings = new GlobalSettings();

    // Holds the records for the entire application
    private concerns: IConcern[];

    private urlFile: string;
    private urlApi: string;
    private serviceUrl: string;

    public selectedEmployee : IPublicEmployee;


    constructor(
        private http: HttpClient,
        private messageService: MessageService,
        private configSettingsService: ConfigSettingsService,

    )
    {

     
    }



    getConcerns(): Observable<IConcern[]>
    {
       
        this.configSettings = this.configSettingsService.configSettings;
        this.urlFile = this.configSettings.clientUrl + "/assets/concern.json";
        this.urlApi = this.configSettings.apiUrl + "/api/concerns";

        if (this.concerns)
        {
            return of(this.concerns);
        }

      
            this.serviceUrl = this.urlApi;
       
        // calls the service if it is not done by initialisation.
        return (this.http.get<IConcern[]>(this.serviceUrl)
            .pipe(
               
                tap(data => this.concerns = data),
              
            ));
    }



    disableOtherGroupConcerns(groupGuid: string)
    {
        this.concerns.forEach(function (concern)
        {
            if (concern.serviceGroupId !== groupGuid)
            {
                concern.addEnabled = false;
                concern.reduceEnabled = false;
               
            }
        });
    }

    public setEmployee(employee: IPublicEmployee )
    {
        this.selectedEmployee = employee;
        this.emptyConcernSelectForm();
        if(!GlobalSettings.isEmpty(employee))
        {
            this.concerns.forEach(concern => {
                if(employee.serviceIds.indexOf(concern.id) < 0)
                {
                    concern.addEnabled = false;
                    concern.reduceEnabled = false;
                }
                concern.countSelected = 0;
            });
        }
        // TODO neues event sodass concern component und evtl andere die zeiten neu berechnen können
        
    }

    // this is called last when a concern was selected. Therefor this only disables the add button if necessary
    public handleMaxEstimatedDuration(concernGroupCounter: IConcernGroupCounter[] ,maxDuration: number, currentDuration: number): void
    {
        this.concerns.forEach(concern =>
        {
            if(!GlobalSettings.isEmpty(this.selectedEmployee) && this.selectedEmployee.serviceIds.indexOf(concern.id) < 0)
            {
                concern.addEnabled = false;
            }
            else if (currentDuration >= maxDuration)
            { 
                concern.addEnabled = false; 
            }
            else if(concern.countSelected == 0)
            {
                if(currentDuration + concern.initialDuration > maxDuration)
                {
                    concern.addEnabled = false;
                }
                else if(concern.maxRepetitions > 0)
                {
                    concern.addEnabled = true;
                }
            }
            else
            {
                if(currentDuration + concern.consecutiveDuration > maxDuration)
                {
                    concern.addEnabled = false;
                }
                else if(concern.countSelected < concern.maxRepetitions)
                {
                    concern.addEnabled = true;
                }
            }
        });
    }


    emptyConcernSelectForm()
    {
        if (this.concerns !== undefined && this.concerns.length > 0)
        {
            this.concerns.forEach(function (concern)
            {
                concern.addEnabled = true;
                concern.reduceEnabled = false;
                concern.countSelected = 0;
            })
        }

    }




    enableOtherGroupConcerns(groupGuid: string)
    {
        this.concerns.forEach((concern) =>
        {
            if(!GlobalSettings.isEmpty(this.selectedEmployee) && this.selectedEmployee.serviceIds.indexOf(concern.id) < 0)
            {
                concern.addEnabled = false;
            }
            else if (concern.serviceGroupId !== groupGuid)
            {
                
                let maxAmount = concern.maxRepetitions !== undefined ? concern.maxRepetitions : 5;
                if (concern.countSelected < maxAmount)
                {
                    concern.addEnabled = true;
                }
                if (concern.countSelected > 0)
                {
                    concern.reduceEnabled = true;
                }
            }
        })
    }

    //


    getConcern(guid: string): Observable<IConcern>
    {

        const foundItem = this.concerns.find(x => x.id === guid)
        return of(foundItem);
    }

    getConcernInGroup(guid: string): IConcern[]
    {
        //previously I was a subscription, but this is changed now.
        // the concern component is subscibing here
        // so the concerns records must already been loaded in the component

        if (this.concerns === undefined)
        {
           
        }


        if (this.concerns[0]["addEnabled"] === undefined)
        {

            this.concerns.forEach( (e) =>
            {
                if (typeof e === "object")
                {
                    e["addEnabled"] = true;
                    e["reduceEnabled"] = false;
                    e["countSelected"] = 0;
                }
            });
        }
        const foundItems = this.concerns.filter(concern => concern.serviceGroupId === guid);
        return (foundItems);
    }

    getSelectedConcernsQuantityInGroup(groupGuid: string): number
    {

        const foundItems = this.concerns.filter(x => x.serviceGroupId === groupGuid)
        let result = 0
        foundItems.forEach( (item) =>
        {
            result += item.countSelected
        });

        return (result);
    }

    getSelectedRecordDurationInGroup(guid: string): number
    {

        const foundItems = this.concerns.filter(concern => concern.serviceGroupId === guid);
        let result = 0
        foundItems.forEach( (item) =>
        {

            if (item.countSelected === 1)
            {
                result = result + (item.countSelected * item.initialDuration)
            } else if (item.countSelected > 1)
            {
                result = result + (1 * item.initialDuration) + (item.countSelected - 1) * item.consecutiveDuration;
            }
        });

        return (result);
    }

    updateSelectedCount(guid: string, countChange: number)
    {
        const foundIndex = this.concerns.findIndex(x => x.id === guid);

        if (foundIndex > -1)
        {

            const foundRecord = this.concerns.find(x => x.id === guid);
            foundRecord.countSelected = foundRecord.countSelected + countChange;

            this.concerns[foundIndex] = foundRecord;

        }
    }


    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T)
    {


        return (error: any): Observable<T> =>
        {

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }





}
