<div  *ngIf="stepperVertical" class="stepper-container">
  <mat-vertical-stepper class="mat-stepper" [linear]="isLinear" #stepper style="margin-bottom: 3.0em;"
  [selectedIndex]="selectedStepIndex"
  (selectionChange)="selectionChange($event,stepper)" >
    <mat-step  optional="false" [stepControl]="stepConcernCheck" class="no-select">  
      <ng-template matStepLabel>{{'STEP.CONCERN_TEXT' | translate}}</ng-template>  
      <app-concerngroup #concernGroupFormGroup (notifyNumberOfConcerns)='notifyNumberOfConcerns($event)'></app-concerngroup> 
    </mat-step>
    <mat-step id="stepLocation" optional="false" [stepControl]="stepLocationCheck" class="no-select">
      <ng-template matStepLabel>{{'STEP.LOCATION_TEXT' | translate}}</ng-template> 
     <app-location #locationFormGroup (notifyLocationChange)='notifyLocationChange($event)'></app-location>
    </mat-step>
    <mat-step optional="false" optional="false" [stepControl]="stepAppointmentCalendarCheck" class="no-select">
      <ng-template matStepLabel >{{'STEP.APPOINTMENT_TEXT' | translate}}</ng-template>
      <app-appointmentcalendar ></app-appointmentcalendar>
    </mat-step>
    <mat-step  optional="false" [stepControl]="stepContactFormCheck" class="no-select">
      <ng-template matStepLabel>{{'STEP.CONTACT_TEXT' | translate}}</ng-template>
      <app-concactform #contactFormGroup (notifyContactFormSuccessfulySent)="notifyContactFormSuccessfulySent($event)">
      </app-concactform>
    </mat-step>
    <mat-step optional="false" [stepControl]="stepConfirmationFormCheck" class="no-select">
      <ng-template matStepLabel>{{'STEP.CONFIRM_TEXT' | translate}}</ng-template>
      <app-confirmation ></app-confirmation>
    </mat-step>
  </mat-vertical-stepper>
</div>

<div  *ngIf="stepperHorizontal" class="stepper-container">
  <mat-horizontal-stepper class="mat-stepper" labelPosition="bottom" [linear]="isLinear"  style="margin-bottom: 3.0em;"
  [selectedIndex]="selectedStepIndex"
  #stepper (selectionChange)="selectionChange($event,stepper)" class="no-select">
    <mat-step  optional="false" [stepControl]="stepConcernCheck" class="primary-colors">  
      <ng-template matStepLabel>{{'STEP.CONCERN_TEXT' | translate}}</ng-template>
      <app-concerngroup #concernGroupFormGroup (notifyNumberOfConcerns)='notifyNumberOfConcerns($event)'></app-concerngroup> 
    </mat-step>
    <mat-step id="stepLocation" optional="false" [stepControl]="stepLocationCheck" class="no-select">
      <ng-template matStepLabel >{{'STEP.LOCATION_TEXT' | translate}}</ng-template>
     <app-location #locationFormGroup (notifyLocationChange)='notifyLocationChange($event)'></app-location>
    </mat-step>
    <mat-step optional="false" optional="false" [stepControl]="stepAppointmentCalendarCheck" class="no-select">
      <ng-template matStepLabel >{{'STEP.APPOINTMENT_TEXT' | translate}}</ng-template>
      <app-appointmentcalendar ></app-appointmentcalendar>
    </mat-step>
    <mat-step  optional="false" [stepControl]="stepContactFormCheck" class="no-select">
      <ng-template matStepLabel>{{'STEP.CONTACT_TEXT' | translate}}</ng-template>
      <app-concactform #contactFormGroup (notifyContactFormSuccessfulySent)="notifyContactFormSuccessfulySent($event)">
      </app-concactform>
    </mat-step>
    <mat-step optional="false" [stepControl]="stepConfirmationFormCheck" class="no-select">
      <ng-template matStepLabel>{{'STEP.CONFIRM_TEXT' | translate}}</ng-template>
      <app-confirmation ></app-confirmation>
    </mat-step>
  </mat-horizontal-stepper>
</div>