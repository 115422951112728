import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigSettingsService } from "src/app/shared/services/config-settings.service";
import { QFlexTicketInfoDto } from "./q-flex-ticket-info-dto.model";

@Injectable({
    providedIn: 'root'
})

export class QFlexTicketInfoService
{

    constructor(private http: HttpClient, private configSettingsService: ConfigSettingsService)
    {
    }


    public getTicketInfo(processHandle: string): Observable<QFlexTicketInfoDto>
    {
      return this.http.get<QFlexTicketInfoDto>(this.configSettingsService.configSettings.apiUrl + "/api/qflex/GetStatisticsByProcessHandle/" + processHandle);
    }

}
