import { Pipe, PipeTransform } from '@angular/core';
import { ConfigSettingsService } from '../services/config-settings.service';
import { LanguageService } from '../services/language.service';
import { ServerSettingService } from '../services/server-setting.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfigSettings } from 'src/app/config-settings';
import { Subscription } from 'rxjs';
import { GlobalSettings } from '../config/globalsettings';
import * as moment from 'moment/moment';

@Pipe({
  name: 'userSettings',
  pure: false
})
export class UserSettingsPipe implements PipeTransform {

  configSettings: ConfigSettings;
  subscription: Subscription;
  translationObject: any;
  settings_time:string;
  settings_minute:string;
 
  constructor(

    private configSettingsService: ConfigSettingsService,
    private languageService: LanguageService,
    private globalSettingService: ServerSettingService,
    private translate: TranslateService,
  ) {
      
  }

  // args can be of any type
  transform(value: string, args1?: any, args2?: any): any {


    value = this.languageService.replaceSettingRessources(value);
    //let args = args
    

    // int replace
    if ((value.indexOf("{{int}}")) !== -1 && (args1 !== undefined)) { 
      
      // only for null, different handling for undefined possible
      if (args1 === null){
        return ""
      }

      let number = + args1;
      let numberString = String(number)
      value = value.replace("{{int}}",numberString);
    
    } else if ((value.indexOf("{{Date}}")) !== -1) { 
      let date = new Date(args1);
      value = this.languageService.getFormatDateString(value,date);
    } else if ((value.indexOf("{{Time}}")) !== -1) { 
      let date = new Date(args1);
      value = this.languageService.getFormatDateString(value,date);  
    
    } else if ((value.indexOf("{{DateTime}}")) !== -1) { 
      let date = new Date(args1);
      value = this.languageService.getFormatDateString(value,date);
    } else if (((value.indexOf("{{StartTime}}")) !== -1) &&  (value.indexOf("{{EndTime}}")) !== -1) { 

        let startTime = new Date(args1);
        let endTime = new Date(args2);

        value = value.replace("{{StartTime}}",this.languageService.convertTime(startTime)).replace("{{EndTime}}",this.languageService.convertTime(endTime));
        return value;

    
    }

    // city must be before adress otherwise replacement wrong.
    if (((value.indexOf("{{CITY}}")) !== -1) &&  (value.indexOf("{{STREET}}")) !== -1) { 
      value = value.replace("{{STREET}}",args1).replace("{{CITY}}",args2);
    } else if ((value.indexOf("{{CITY}}")) !== -1) { 
      value = value.replace("{{CITY}}",args1);
    } else if ((value.indexOf("{{STREET}}")) !== -1) { 
      value = value.replace("{{STREET}}",args1);
    }
    
    return value ;
  }

}
