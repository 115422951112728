
import { Injectable } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeGerman from '@angular/common/locales/de';


@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private _locale: string;

  set locale(value: string) {
      this._locale = value;
  }
  get locale(): string {
      return this._locale || 'de-DE';
  }
  //todo: implement other languages
  registerCulture(culture: string) {
      if (!culture) {
          return;
      }
      this.locale = culture;

      // Register locale data since only the en-US locale data comes with Angular
      switch (culture) {
          case 'de-DE': {
              registerLocaleData(localeGerman);
              break;
          }
      }
  }
}