import { Component, OnInit, OnDestroy } from "@angular/core";
import { GlobalSettings } from "src/app/shared/config/globalsettings";
import {
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder,
} from "@angular/forms";
import { Observable, of, Subscription } from "rxjs";
import { MessageService } from "src/app/shared/services/messages.service";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CustomerService } from "src/app/shared/services/customer.service";
import { Title } from "@angular/platform-browser";
import { LanguageService } from "src/app/shared/services/language.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: "app-cancel-appointment",
  templateUrl: "./cancel-appointment.component.html",
  styleUrls: ["./cancel-appointment.component.scss"],
})
export class CancelAppointmentComponent implements OnInit, OnDestroy {
  private classame = (<any>this).constructor.name;

  public isLoading: boolean = false;

  private subscriptions: Subscription[] = [];
  translationTitleObject: any;
  globalSettings: GlobalSettings = new GlobalSettings();
  frmGroup: UntypedFormGroup;
  successMessage: string;
  //errorMessage: string;

  confirmError: boolean;
  controlCodeInput: string;
  showConfirmationForm: boolean;
  invalidValidation: boolean = false;
  //title:string;
  showTitleInStepper: boolean;
  showSelection = false;
  isInStep: boolean = false;
  enableForm: boolean = false;
  contactFormSuccesful: Observable<boolean>;

  //selectedLocation: ILocation;
  code: string;
  lang: string;
  translationObject: any;
  //translationTimeObject: any;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private messageService: MessageService,
    // private locationService: LocationService,
    private customerService: CustomerService,
    // private calendarService: CalendarService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    public dialog: MatDialog,

    public title: Title,
    private languageService: LanguageService
  ) {}

  ngOnInit() {
    this.confirmError = false;

    this.subscriptions.push(
      this.translate
        .get(["APPLICATION_TITLE", "MENU.CANCEL_APPOINTMENT"])
        .subscribe((translation: [string]) => {
          this.translationTitleObject = translation;
          this.title.setTitle(
            this.translationTitleObject["APPLICATION_TITLE"] +
              " > " +
              this.translationTitleObject["MENU.CANCEL_APPOINTMENT"]
          );
        })
    );

    this.showTitleInStepper = false;

    this.showConfirmationForm = true;
    this.frmGroup = this.formBuilder.group({
      controlCode: [null, Validators.required],
    });
    this.enableForm = this.frmGroup.valid;

    // check direct confirmation link.
    const paramCode = this.route.snapshot.paramMap.get("code");
    if (paramCode) {
      this.code = paramCode;

      this.subscriptions.push(
        this.translate.get("CANCEL_APPOINTMENT.CONFIRM").subscribe((msg) => {
          const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: "450px",
            data: msg,
          });

          this.subscriptions.push(
            dialogRef.afterClosed().subscribe((result) => {
              this.frmGroup.controls["controlCode"].setValue(this.code);
              this.frmGroup.controls["controlCode"].markAsDirty();
              this.frmGroup.get("controlCode").updateValueAndValidity();
              if (result) {
                this.subscriptions.push(
                  this.appointment_delete_code(this.code).subscribe(
                    (confirm) => {
                      this.showConfirmationForm = !confirm;
                    }
                  )
                );
              }
            })
          );
        })
      );
    }

    // set the lang of the application if paramer is set.
    const paramLang = this.route.snapshot.paramMap.get("lang");
    if (paramLang) {
      this.lang = paramLang;
      this.languageService.setLanguageFromCode(this.lang);
      this.translate.setDefaultLang(this.lang);
    }

    if (this.contactFormSuccesful) {
      this.showConfirmationForm = true;
    }

    this.frmGroup.get("controlCode").clearValidators();
    this.frmGroup.get("controlCode").setValidators(Validators.required);
    this.frmGroup.get("controlCode").updateValueAndValidity();

    // delete the error message for changing input
    // and display the button if valid.
    this.subscriptions.push(
      this.frmGroup.get("controlCode").valueChanges.subscribe(() => {
        //this.errorMessage = "",
        this.confirmError = false;
        // second condition because valid is still false for pasting text.
        this.enableForm =
          this.frmGroup.valid || this.frmGroup.get("controlCode").dirty;
      })
    );
  }

  getRessourceText(val: string): string {
    this.translationObject = this.translate.get(val);
    if (this.translationObject.value !== undefined)
      return this.translationObject.value;

    return "";
  }

  //detection for automatic changes
  // get selectedDate(): Date {
  //   return this.calendarService.selectedDate;
  // }

  getReplaceText(value: string): string {
    this.translationObject = this.translate.get(value);

    if (
      this.translationObject !== undefined &&
      this.translationObject.value !== undefined
    ) {
      // = this.globalSettings.confirmationSubmittedTextWithBookingInfo;

      let str = this.translationObject.value;
      return str;
    }

    return "";
  }

  appointment_delete_code(code: string): Observable<boolean> {
    if (code) {
      this.isLoading = true;
      this.subscriptions.push(
        this.customerService.deleteAppointmentCode(code).subscribe(
          (responseStatus) => {
            this.isLoading = false;
            this.invalidValidation = false;
            this.onCallbackSubmit(responseStatus.status);
            return of(true);
          },
          (error) => {
            this.isLoading = false;
            this.invalidValidation = true;
            this.onCallbackSubmit(error.status);
            return of(false);
          },
          () => {
            this.isLoading = false;
            this.invalidValidation = true;
            return of(false);
          }
        )
      );
    }

    return of(true);
  }

  // 3 different submits.
  // one directly from the form outsite the stepper and one callback from the stepper and one with url parameters.
  submit(): void {
    //disble button for second try. Will be enabled again after post back.
    this.enableForm = false;

    let controlCode = this.frmGroup.get("controlCode").value;

    if (this.frmGroup.valid && this.frmGroup.dirty) {
      this.isLoading = true;
      this.subscriptions.push(
        this.customerService.deleteAppointmentCode(controlCode).subscribe(
          (responseStatus) => {
            this.isLoading = false;
            this.invalidValidation = false;
            this.enableForm = false;
            this.onCallbackSubmit(responseStatus.status);
          },
          (error) => {
            this.isLoading = false;
            this.invalidValidation = true;
            this.onCallbackSubmit(error.status);
          },
          () => {
            this.isLoading = false;
            this.invalidValidation = true;
          }
        )
      );
    }
  }
  onCallbackSubmit(status: number): void {
    switch (status) {
      case 200: {
        this.confirmError = false;
        this.showConfirmationForm = false;

        break;
      }
      case 404: {
        this.confirmError = true;
        this.showConfirmationForm = true;
        this.enableForm = true; //next try
        break;
      }

      case 500: {
        break;
      }
      default: {
        //this.errorMessage = "Unbekannter Fehler.";
        break;
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
