import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IInfoBanner } from '../model/info-banner.model';
import { ConfigSettingsService } from './config-settings.service';


@Injectable({
    providedIn: 'root'
})
export class InfoBannerService
{
    constructor( private http: HttpClient, private configSettingsService: ConfigSettingsService ) { }

    public getInfoBannerSettings() : Observable<IInfoBanner>
    {
        return this.http.get<IInfoBanner>(this.configSettingsService.configSettings.apiUrl + "/api/info-banner/get");
    }


}
