import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ViewChild, Renderer2, APP_INITIALIZER, OnDestroy } from '@angular/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatCalendar, MatCalendarCellCssClasses, MatCalendarHeader } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MessageService } from 'src/app/shared/services/messages.service';
import { CalendarService } from 'src/app/shared/services/calendar.service';
import { Moment } from 'moment';
import * as moment from 'moment/moment';
import { GlobalSettings } from 'src/app/shared/config/globalsettings';
import { ServerSettings } from 'src/app/shared/config/server-settings';
import { ServerSettingService } from 'src/app/shared/services/server-setting.service';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfigSettingsService } from 'src/app/shared/services/config-settings.service';
import { initializeData } from 'src/app/app.module';
import { LanguageService } from 'src/app/shared/services/language.service';
import { Subscription } from 'rxjs';
import { ServerSettingsService } from 'src/app/shared/services/server-settings.service';

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss'],
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    ],


})


export class CalendarComponent implements OnInit, OnDestroy
{
    private subscriptions: Subscription[] = [];
    public isLoading = true;
    private isDisabled: boolean = true;
    public frmCalendar: UntypedFormGroup;


    public selectedDate: Moment;
    public maxDate: Date;
    public minDate: Date;
    public selectedMonth: Date;
    public serverSettingsMaxDisplyedDays: number;


    // expected: date as number format with time 0:00
    @Input() datesToHiglightList: Array<number>;

    // the calendarday will be formated as number and with time 0:00
    private selectedCalendarDay: number;
    @Output() notifyCalendarDay: EventEmitter<number> = new EventEmitter<number>();

    // @ViewChild('calendar') calendar: MatCalendar<Date>;
    @ViewChild(MatCalendar, { static: false }) calendar: MatCalendar<Date>;

    constructor(
        private messageService: MessageService,
        private calendarService: CalendarService,
        private renderer: Renderer2,
        private globalSettingService: ServerSettingService,
        private formBuilder: UntypedFormBuilder,
        private translate: TranslateService,
        private dateadapter: DateAdapter<any>,
        private languageService: LanguageService,
        private serverSettingsService: ServerSettingsService,
    )
    {
    }

    ngOnInit()
    {
        this.selectedMonth = new Date(Date.now());
        this.dateadapter.setLocale(this.languageService.currentLanguage.code);
        // subscribe on changes
        this.subscriptions.push(this.languageService.languageChangeSubject.subscribe(language =>
        {
            this.dateadapter.setLocale(language.code);
        }));

        this.frmCalendar = this.formBuilder.group({});

        this.setReadonlyForm(true);

        this.subscriptions.push(this.globalSettingService.getServerVariables().subscribe((serverSettings: ServerSettings) =>
        {

            this.subscriptions.push(this.serverSettingsService.getServerDate().subscribe(serverDate =>
            {

                // always the best option to work with moment vor conversion
                let momentDate = moment(serverDate.serverTime, "YYYY-MM-DDThh:mm");
                let serverDateHelper = momentDate.toDate();


                this.serverSettingsMaxDisplyedDays = serverSettings.maxCalculatedDays;

                // the mindate and max date from server date and config values.
                this.minDate = new Date(serverDateHelper.setHours(serverDateHelper.getHours() + serverSettings.earliestAppointmentStartInHours));
                this.maxDate = new Date(serverDateHelper.setDate(serverDateHelper.getDate() + serverSettings.maxCalculatedDays));

                if (this.datesToHiglightList.length > 0)
                {
                    this.selectedDate = moment(this.datesToHiglightList[0]);
                    this.onSelect(this.datesToHiglightList[0]);
                    this.selectedMonth = this.selectedDate.toDate();
                }
                this.isLoading = false;
            }));
        }));
    }

    // render the selected and not selected styles.
    dateClass()
    {
        return (date: Date): MatCalendarCellCssClasses =>
        {
            const highlightDate = this.datesToHiglightList
                .map(dateEntry => moment(new Date(dateEntry)))
                .some(d => d.isSame(moment(date)));
            return highlightDate ? 'dayEntry' : 'dayNoEntry';
        };
    }

    setReadonlyForm(isSent: boolean)
    {
        this.frmCalendar.disable()
    }

    //day of calendar is selected, no changed from number to moment object.
    onSelect(event: any)
    {
        // all dates are start of day dates
        let findIndex = this.datesToHiglightList.findIndex(x => x == event);

        if ((findIndex > -1))
        {
            //notifyer in number format
            this.selectedCalendarDay = new Date(event).setHours(0, 0, 0, 0);
            this.notifyCalendarDay.emit(this.selectedCalendarDay);
        } 
        else
        {
            this.notifyCalendarDay.emit(null);
        }
    }

    ngOnDestroy()
    {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

}
