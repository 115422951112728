<mat-card appearance="outlined" *ngIf="isLoading">
    <mat-card-content style="display:flex; flex-direction:row; justify-content:center; align-items:center;">
        <mat-spinner></mat-spinner>
    </mat-card-content>
</mat-card>

<div class="container" *ngIf="!isLoading">
    <h2>{{'CONTACT_FORM.TITLE' | translate}}</h2>
    <p>
        {{'CONTACT_FORM.HEADER_TEXT' | translate}}
    </p>
    <form [formGroup]="frmContact" class="form" class="contact-form" novalidate>
        <!-- <form [formGroup]="frmContact" (ngSubmit)="save()" class="form"> -->
        <mat-error>{{errorObject | json}}</mat-error>
        <mat-error *ngIf="errorMessage !== ''">{{errorMessage | translate}}</mat-error>

        <div formArrayName="requirementValues">
            <div *ngFor="let requirementValue of frmContact.controls.requirementValues.controls; let i=index">
                <div [formGroupName]="i">

                    <!-- -------------- SingleLine Text--------------------- -->
                    <div *ngIf="requirementValue.controls.type.value == 0">
                        <mat-form-field  class="w-100" style="line-height: 15px;">
                            <mat-label>{{getName(i)}}</mat-label>
                            <input matInput placeholder="{{getName(i)}}" formControlName="value"
                                matTooltip="{{getDescription(i)}}">
                        </mat-form-field>
                    </div>
                    <!-- -------------- MultiLine Text --------------------- -->
                    <div *ngIf="requirementValue.controls.type.value == 1">
                        <mat-form-field class="w-100" style="line-height: 15px;">
                            <mat-label>{{getName(i)}}</mat-label>
                            <textarea matInput placeholder="{{getName(i)}}" formControlName="value"
                                matTooltip="{{getDescription(i)}}" rows="5"></textarea>
                        </mat-form-field>
                    </div>
                    <!-- ------------- Bool ------------------------------- -->
                    <div *ngIf="requirementValue.controls.type.value == 2">
                        <mat-checkbox formControlName="value" matTooltip="{{getDescription(i)}}">
                            <div class="checkbox_text">
                                {{getName(i)}}
                            </div>
                        </mat-checkbox>
                    </div>
                    <!-- ------------- Int -------------------------------- -->
                    <div *ngIf="requirementValue.controls.type.value == 3">
                        <mat-form-field class="w-100">
                            <mat-label>{{getName(i)}}</mat-label>
                            <input matInput type="number" step="1" pattern="\-?\d*" placeholder="{{getName(i)}}" formControlName="value"
                                matTooltip="{{getDescription(i)}}">
                        </mat-form-field>
                    </div>
                    <!-- ------------- Double ----------------------------- -->
                    <div *ngIf="requirementValue.controls.type.value == 4">
                        <mat-form-field class="w-100">
                            <mat-label>{{getName(i)}}</mat-label>
                            <input matInput type="number" step="0.0001" placeholder="{{getName(i)}}"
                                formControlName="value" matTooltip="{{getDescription(i)}}">
                        </mat-form-field>
                    </div>
                    <!-- ------------- Date ------------------------------- -->
                    <div *ngIf="requirementValue.controls.type.value == 5">
                        <mat-form-field class="w-100">
                            <mat-label>{{getName(i)}}</mat-label>
                            <input matInput type="date" placeholder="{{getName(i)}}" matTooltip="{{getDescription(i)}}"
                                formControlName="value">
                        </mat-form-field>
                    </div>
                    <!-- ------------- Single Select ---------------------  -->
                    <div *ngIf="requirementValue.controls.type.value == 6">
                        <mat-form-field class="w-100">
                            <mat-label>{{getName(i)}}</mat-label>
                            <mat-select formControlName="value" placeholder="{{getName(i)}}"
                                matTooltip="{{getDescription(i)}}">
                                <mat-option>{{'UI.None' | translate}}</mat-option>
                                <mat-option *ngFor="let customEnum of getSortedCustomEnums(i); let j=index"
                                    [value]="customEnum.id" matTooltip="{{getCustomEnumTooltip(i,j)}}" disabled="{{!customEnum.isActive}}">
                                    {{getCustomEnumName(i,j)}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <!-- ------------- Multi Select ---------------------- -->
                    <div *ngIf="requirementValue.controls.type.value == 7">
                        <mat-form-field class="w-100">
                            <mat-label>{{getName(i)}}</mat-label>
                            <mat-select formControlName="value" placeholder="{{getName(i)}}"
                                matTooltip="{{getDescription(i)}}" multiple>
                                <mat-option *ngFor="let customEnum of getSortedCustomEnums(i); let j=index"
                                    [value]="customEnum.id" matTooltip="{{getCustomEnumTooltip(i,j)}}" disabled="{{!customEnum.isActive}}">
                                    {{getCustomEnumName(i,j)}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>


                    <mat-error
                        *ngIf="requirementValue.controls['value'].touched && requirementValue.controls['value'].errors">
                        {{getValidationError(i)}}
                    </mat-error>

                </div>
            </div>
        </div>
        <!-- <mat-form-field class="form-element">
      <input matInput placeholder="{{'CONTACT_FORM.FIRSTNAME' | translate}}" formControlName="firstName" required>
      <mat-error *ngIf="frmContact.controls.firstName.errors?.required">{{'MESSAGES.VALUE_REQUIRED' | translate}}</mat-error>
    </mat-form-field>

    <mat-form-field class="form-element">
      <input matInput placeholder="{{'CONTACT_FORM.LASTNAME' | translate}}" formControlName="lastName" required>
      <mat-error *ngIf="frmContact.controls.lastName.errors?.required">{{'MESSAGES.VALUE_REQUIRED' | translate}}</mat-error>
    </mat-form-field>

    <mat-form-field class="form-element">
      <input matInput placeholder="{{'CONTACT_FORM.EMAIL' | translate}}" formControlName="eMail" required>
      <mat-error *ngIf="frmContact.controls.eMail.errors?.required">{{'MESSAGES.VALUE_REQUIRED' | translate}}</mat-error>
      <mat-error *ngIf="frmContact.controls.eMail.errors?.emailValidator">{{'MESSAGES.INVALID_EMAIL' | translate}}</mat-error> 
    </mat-form-field>

    <mat-form-field class="form-element" *ngIf="displayCustomerNote">
        <textarea matInput placeholder="{{'CONTACT_FORM.NOTE' | translate}}" formControlName="customerNote" [maxlength]="maxCustomerNoteLength"></textarea>
    </mat-form-field>

    <mat-form-field class="form-element" *ngIf="displayNumber">
      <input matInput placeholder="{{'CONTACT_FORM.PHONENUMBER' | translate}}" formControlName="phonenumber" >
      <mat-error *ngIf="frmContact.controls.phonenumber.errors?.required">{{'MESSAGES.VALUE_REQUIRED' | translate}}</mat-error>
    </mat-form-field> -->

        <!-- <div class="content-header-height" [innerHTML]="headerText"></div> -->
        <mat-checkbox formControlName="checkAGB">
            <div innerHtml="{{'CONTACT_FORM.CHECK_AGB' | translate:{ dataProtectionURL: dataProtectionURL } }}"></div>
        </mat-checkbox>

        <div style="display:flex; flex-direction:row; justify-content:center; align-items:center;">
            <re-captcha formControlName="recaptchaReactive" [siteKey]="siteKey" *ngIf="useReCaptcha"></re-captcha>
        </div>
    </form>
</div>

<ng-template #forminfo>
    <div class="container">
        {{ post | json }}
    </div>
</ng-template>

<div class="nav-footer-bar">
    <div class="fixedLeft">
        <button mat-raised-button matStepperPrevious matTooltip="{{'CONTACT_FORM.BACK_PAGE_TOOLTIIP' | translate}}">
            <div style="display:flex; flex-direction:row; align-items:center;">
                <mat-icon>keyboard_arrow_left</mat-icon>
                <div>{{'BUTTONS.BACK' | translate}}</div>
            </div>
        </button>
    </div>


    <div class="fixedRight" *ngIf="!formSuccessFullySent">
        <button id="nextButton" mat-raised-button [disabled]="!nextStepIsEnabled || formSubmitProcess"
            (click)="submitForm()" matTooltip="{{'CONTACT_FORM.NEXT_PAGE_TOOLTIIP' | translate}}">
            <div style="display:flex; flex-direction:row; align-items:center;">
                <div>
                    {{'BUTTONS.NEXT' | translate}}
                </div>
                <mat-icon>keyboard_arrow_right</mat-icon>
            </div>
        </button>
    </div>

    <!-- TER-504 not yet on the server -->
    <div class="fixedRight" *ngIf="formSuccessFullySent">
        <button mat-raised-button matTooltip="{{'CONTACT_FORM.NEXT_PAGE_TOOLTIIP' | translate}}"
            matStepperNext>
            <div style="display:flex; flex-direction:row; align-items:center;">
                <div>
                    {{'BUTTONS.NEXT' | translate}}
                </div>
                <mat-icon>keyboard_arrow_right</mat-icon>
            </div>
        </button>
    </div>
</div>