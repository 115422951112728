import { Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr";
import { QFlexWorkloadService } from './q-flex-workload.service';
import { ConfigSettingsService } from './config-settings.service';


@Injectable({
    providedIn: 'root'
})
export class QFlexWorkloadHub 
{
    private hubConnection: signalR.HubConnection;

    constructor(private workloadService: QFlexWorkloadService,  private configSettingsService: ConfigSettingsService)
    {

    }

    public startConnection = () =>
    {
        if (this.hubConnection == null)
        {
            this.hubConnection = new signalR.HubConnectionBuilder()
                .withUrl(this.configSettingsService.configSettings.apiUrl + '/QFlexWorkload')
                .withAutomaticReconnect()
                .build();

            // this.hubConnection.serverTimeoutInMilliseconds = 1000 * 60 * 10;
            
        }

        this.start();
    }

    //SignalR Verbindung zum Server starten
    async  start()
    {
        if (this.hubConnection.state == signalR.HubConnectionState.Disconnected)
        {
            await this.hubConnection
                .start()
                .then(() => console.log('QFlexWorkloadHub Connection started'))
                .catch(err =>
                {
                    setTimeout(() => this.start(), 5000);
                });
        }

    }

    public receiveWorkload()
    {
        this.hubConnection.on('BroadcastQFlexWorkload', (workloadDTO) =>
        {
            this.workloadService.workload = workloadDTO;  
        });
    }

}
