import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MessageService } from './messages.service';
import { GlobalSettings } from '../config/globalsettings';
import { ServerSettings } from '../config/server-settings';
import { Observable, of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { ConfigSettings } from 'src/app/config-settings';
import { ConfigSettingsService } from './config-settings.service';
// import { IDateDisplayOptions } from '../model/date-display-options';
import { LanguageService } from './language.service';
import * as moment from 'moment/moment';

@Injectable({
    providedIn: 'root'
})

export class CompanyService
{

    constructor(private http: HttpClient, private configSettingsService: ConfigSettingsService)
    {
    }


    getLegalNotice(): Observable<any>
    {
      return this.http.get<any>(this.configSettingsService.configSettings.apiUrl + "/api/company-settings/get-legal-notice");
    }

}
