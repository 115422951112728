import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { QFlexQRScanHistoryDto, QFlexQRScanInformationDto, QFlexQRScanInformationWithTicketImageDto, QRScanSettingsDto } from './q-flex-qr-scan.model';
import { QFlexQrScanService } from './q-flex-qr-scan.service';
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';
// import { ThrowStmt } from '@angular/compiler';
import { MatDialog } from '@angular/material/dialog';
import { QFlexSecurityPopupComponent } from './q-flex-security-popup/q-flex-security-popup.component';
import { Observable } from 'rxjs';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-q-flex-qr-scan',
  templateUrl: './q-flex-qr-scan.component.html',
  styleUrls: ['./q-flex-qr-scan.component.scss']
})
export class QFlexQrScanComponent implements OnInit 
{

    public settingsDto: QRScanSettingsDto = null;
    public isLoading: boolean = true;
    public enableFeature: boolean = false;
    public displayPrivateInfo: boolean = false;
    public displayHistory: boolean = false;
    public showGeneralInfo: boolean = true;

    private securityValue: string = "";
    private ticketId: string = null;

    public generalInfo: QFlexQRScanInformationWithTicketImageDto= null;
    public ticketImageString: string = null;

    public privateInfo: QFlexQRScanInformationDto = null;

    public history: QFlexQRScanHistoryDto = null;

    // HistorySettings
    public showTimestamp: boolean;
    public showEvent: boolean;
    public showNode: boolean;
    public showUser: boolean;
    public showEventInfo: boolean;

    // LeaveQueue
    public showError: boolean = false;
    public leaveQueueSuccessfull : boolean = false;

    // reload
    private hasLoadedGeneralInfoBefore: boolean = false;
    private hasLoadedPrivateInfoBefore: boolean = false;
    private hasLoadedHistoryBefore: boolean = false;
    public reloadDisabled: boolean = false;
    public timeTillReload: number = 10;
    private reloadTimer : any;


    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private qrScanService: QFlexQrScanService,
        private translateService: TranslateService,
        public dialog: MatDialog
    ) 
    { 

    }

    ngOnInit() 
    {
        this.ticketId = this.route.snapshot.paramMap.get('id');
        this.isLoading = true;
        this.qrScanService.getQRSettings(this.ticketId).subscribe(settings => 
        {
            this.isLoading = false;
            this.settingsDto = settings;
            this.enableFeature = settings.useTicketInfoViaScanFeature;
            if(settings.ticketImageDataString != null && settings.ticketImageDataString != "")
            {
                this.ticketImageString = '<img src="data:image/png;base64,' + settings.ticketImageDataString + '">';
            }
            else
            {
                this.settingsDto.showTicketImage = false;
            }
            
            this.getCheckedGeneralInfo(false);
        });
    }

    public reload(): void
    {
        this.reloadDisabled = true;
        this.reloadTimer = setInterval(() => {
            if(this.timeTillReload <= 1)
            {
                this.reloadDisabled = false;
                this.timeTillReload = 10;
                clearInterval(this.reloadTimer);
            }
            else
            {
                this.timeTillReload--;
            }
        }, 1000);
        if(this.settingsDto.securityQuestionEveryTime)
        {
            let requireSecurityCheck = false;
            requireSecurityCheck = this.settingsDto.protectGeneralInformation && this.hasLoadedGeneralInfoBefore;
            if(!requireSecurityCheck)
            {
                requireSecurityCheck = this.settingsDto.protectPersonalInformation && this.hasLoadedPrivateInfoBefore;
            }
            if(!requireSecurityCheck)
            {
                requireSecurityCheck = this.settingsDto.protectHistory && this.hasLoadedHistoryBefore;
            }
            if(requireSecurityCheck)
            {
                this.openSecurityQuestion(false).subscribe( result =>
                {
                    if(result == null)
                    {
                        return;
                    }
                    this.securityValue = "" + result;
                    this.handleIndividualDataReload();
                });
            }
            else
            {
                this.handleIndividualDataReload();
            }
        }
        else
        {
           this.handleIndividualDataReload();
        }
    }
    private handleIndividualDataReload(): void
    {
        if(this.hasLoadedGeneralInfoBefore)
        {
            this.getGeneralInfo();
        }
        if(this.hasLoadedPrivateInfoBefore)
        {
            this.getPrivateInfo();
        }
        if(this.hasLoadedHistoryBefore)
        {
            this.getHistory();
        }
    }

    // ---------------- General Info -------------------
    public toggleGeneralInfo()
    {
        if(!this.showGeneralInfo)
        {
            this.showGeneralInfo = true;
            if(this.generalInfo == null)
            {
                this.getCheckedGeneralInfo(false);
            }
        }
        else
        {
            this.showGeneralInfo = false;
        }
    }

    private getCheckedGeneralInfo(wrongAnswer: boolean)
    {
        if(!this.settingsDto.protectGeneralInformation ||
            (wrongAnswer == false && !this.settingsDto.securityQuestionEveryTime && this.securityValue != "" && this.securityValue != null && this.securityValue != undefined && wrongAnswer == false))
        {
            this.getGeneralInfo();
            return;
        }
    
        this.openSecurityQuestion(wrongAnswer).subscribe( result =>
        {
            if(result == null)
            {
                this.generalInfo = null;
                this.showGeneralInfo = false;
                return;
            }
            this.securityValue = "" + result;
            this.getGeneralInfo();
        });
    }

    private getGeneralInfo(): void
    {
        this.isLoading = true;
        this.qrScanService.getGeneralInfo(this.securityValue, this.ticketId).subscribe(info =>
        {
            this.isLoading = false;
            this.hasLoadedGeneralInfoBefore = true;
            // success = 0,
            // failed = 1,
            // noData = 2
            if(info.securitySuccessType == 0)
            {
                this.generalInfo = info;
                if(info.imageData != null && info.imageData?.length > 0)
                {
                    this.ticketImageString = '<img src="data:image/png;base64,' + info.imageData + '">';
                }
                
                if( (info.imageData == null || info.imageData?.length <1) && (this.settingsDto.ticketImageDataString == null || this.settingsDto.ticketImageDataString?.length <1) )
                {
                    this.settingsDto.showTicketImage = false;
                }
            }
            else if(info.securitySuccessType == 1)
            {
                this.securityValue = "";
                this.getCheckedGeneralInfo(true);
            }
            else if(info.securitySuccessType == 2)
            {
                this.generalInfo = null;
            }
           
        },
        (error) =>
        {
            this.isLoading = false;
        });
    }

    // ------------------- Private Info -------------------- 
    public togglePrivateInfo()
    {
        if(!this.displayPrivateInfo)
        {
            if(!this.settingsDto.showPrivateInformationButton)
            {
                return;
            }

            this.displayPrivateInfo = true;
            if(this.privateInfo == null)
            {
                this.getCheckedPrivateInfo(false);
            }
            
        }
        else
        {
            this.displayPrivateInfo = false;
        }
    }
    private getCheckedPrivateInfo(wrongAnswer: boolean)
    {
        if(!this.settingsDto.protectPersonalInformation ||
            (wrongAnswer == false && !this.settingsDto.securityQuestionEveryTime && this.securityValue != "" && this.securityValue != null && this.securityValue != undefined))
            {
                this.getPrivateInfo();
                return;
            }

        let securitySubscription = this.openSecurityQuestion(wrongAnswer).subscribe( result =>
            {
                if(result == null)
                {
                    this.privateInfo = null;
                    this.displayPrivateInfo = false;
                    return;
                }
                this.securityValue = "" + result;
                this.getPrivateInfo();
            });
    }
    private getPrivateInfo()
    {
        this.isLoading = true;
        this.qrScanService.getPrivateInfo(this.securityValue, this.ticketId).subscribe(info =>
            {
                this.isLoading = false;
                this.hasLoadedPrivateInfoBefore = true;
                // success = 0,
                // failed = 1,
                // noData = 2
                if(info.securitySuccessType == 0)
                {
                    this.privateInfo = info;
                }
                else if(info.securitySuccessType == 1)
                {
                    this.securityValue = "";
                    this.getCheckedPrivateInfo(true);
                }
                else if(info.securitySuccessType == 2)
                {
                    this.privateInfo = null;
                }     
            },
            (error)=>{
                this.isLoading = false;
            });
    }


    // --------------------- History ---------------------
    public toggleHistory()
    {
        if(!this.displayHistory)
        {
            if(!this.settingsDto.showTicketHistory)
            {
                return;
            }
            this.displayHistory = true;
            if(this.history == null)
            {
                this.getCheckedHistory(false);
            }
        }
        else
        {
            this.displayHistory = false;
        }
    }
    private getCheckedHistory(wrongAnswer: boolean)
    {
        if(!this.settingsDto.protectHistory ||
            (wrongAnswer == false && !this.settingsDto.securityQuestionEveryTime && this.securityValue != "" && this.securityValue != null && this.securityValue != undefined))
            {
                this.getHistory();
                return;
            }
        let securitySubscription = this.openSecurityQuestion(wrongAnswer).subscribe( result =>
            {
                if(result == null)
                {
                    this.history = null;
                    this.displayHistory = false;
                    return;
                }
                this.securityValue = "" + result;
                this.getHistory();
            });
    }
    private getHistory()
    {
        this.isLoading = true;
        this.qrScanService.getHistory(this.securityValue, this.ticketId).subscribe(info =>
            {
                this.isLoading = false;
                this.hasLoadedHistoryBefore = true;
                // success = 0,
                // failed = 1,
                // noData = 2
                if(info.securitySuccessType == 0)
                {
                    this.history = info;
                    if(this.history)
                    {
                        if(this.history.information.length > 0)
                        {
                            let eventVal = this.history.information[0].localizations[0].event;
                            let timestampVal = this.history.information[0].localizations[0].timestamp;
                            let nodeVal = this.history.information[0].localizations[0].node;
                            let userVal = this.history.information[0].localizations[0].user;
                            let eventInfoVal = this.history.information[0].localizations[0].eventInfo;

                            this.showEvent = !(eventVal == null || eventVal == "" || eventVal == undefined);
                            this.showTimestamp = !(timestampVal == null || timestampVal == "" || timestampVal == undefined);
                            this.showNode = !(nodeVal == null || nodeVal == "" || nodeVal == undefined);
                            this.showUser = !(userVal == null || userVal == "" || userVal == undefined);
                            this.showEventInfo = !(eventInfoVal == null || eventInfoVal == "" || eventInfoVal == undefined);

                        }
                    }
                }
                else if(info.securitySuccessType == 1)
                {
                    this.securityValue = "";
                    this.getCheckedHistory(true);
                }
                else if(info.securitySuccessType == 2)
                {
                    this.history = null;
                }
               
            },
            (error) => 
            {
                this.isLoading = false;
            });
    }

    // ---------------------- Exit Queue -------------------
    public async exitQueue(): Promise<void>
    {
        this.showError = false;
        
        const msg = await this.translateService.get('QRScan.ExitQueueWarningText').toPromise();
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            disableClose: true,
            width: '95%',
            maxWidth: '95vw',
            data: msg
        });

        const result = await dialogRef.afterClosed().toPromise();
        if(result == true)
        {
            this.exitQueueSecutiryCheck(false);
        }
    }
    private exitQueueSecutiryCheck(wrongAnswer: boolean)
    {
        if(!this.settingsDto.protectExitQueueButton ||
            (wrongAnswer == false && !this.settingsDto.securityQuestionEveryTime && this.securityValue != "" && this.securityValue != null && this.securityValue != undefined))
            {
                this.executeExitQueue();
                return;
            }

        let securitySubscription = this.openSecurityQuestion(wrongAnswer).subscribe( result =>
            {
                if(result == null)
                {
                    return;
                }
                this.securityValue = "" + result;
                this.executeExitQueue();
            });
    }

    private executeExitQueue()
    {
        this.isLoading = true;
        this.qrScanService.exitQueue(this.securityValue, this.ticketId).subscribe(info =>
            {
                this.isLoading = false;
                // success = 0,
                // failed = 1,
                // noData = 2
                if(info.securitySuccessType == 1)
                {
                    this.exitQueueSecutiryCheck(true);
                }
                else if(info.success)
                {
                    this.history = null;
                    this.generalInfo = null;
                    this.privateInfo = null;
                    this.leaveQueueSuccessfull = true;
                }
                else
                {
                    this.showError = true;
                }
               
            },
            (error) => 
            {
                this.isLoading = false;
            });
    }

    //  ------------- All data --------------------
    private openSecurityQuestion(wrongAnswer: boolean): Observable<any>
    {
        const dialogRef = this.dialog.open(QFlexSecurityPopupComponent, {
            disableClose: true,
            width: '95%',
            maxWidth: '95vw',
            data: {requirement: this.settingsDto.securityQuestionRequirement, wrongAnswer: wrongAnswer},
          });

        return dialogRef.afterClosed();
    }




    public bookAppointment()
    {
        this.router.navigate(['book-appointment/init']);
    }

}
