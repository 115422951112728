import { RequirementValueDto } from "./requirement-value-dto.model";
import { IUserConcern } from "./userconcern";

export class BookAppointmentDto
{
    public id: string;

    public start: string;

    public customerLanguageCode: string;

    public requirementValues: RequirementValueDto[];
    public selectedServiceDtos: IUserConcern[];

    public qMobileEndUserId: string;

}