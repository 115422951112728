
<div style="overflow: auto;">
 
        <div style="display:flex; flex-direction:row; justify-content:space-between; align-items:center;">
            <!-- to center the symbol add a buffer before it. the app-language-settings has a width of 45px -->
            <p style="width: 45px;"></p> 
            <span class="material-icons">privacy_tip</span>
            <app-language-settings >
            </app-language-settings>
        </div>
        

    <h4 class="questionText" style="color:red" *ngIf="data.wrongAnswer">{{'QRScan.SecurityQuestionWrong' | translate}}</h4>
    <h4 *ngIf="!data.wrongAnswer" class="questionText">{{'QRScan.SecurityQuestionTitle' | translate}}</h4>

    <div class="inputArea">
        <h3 class="answerFieldName">{{data.requirement | defaultTranslation: 'name'}}:</h3>

        <!-- -------------- SingleLine Text--------------------- -->
        <div *ngIf="data.requirement.type == 0">
            <mat-form-field  class="w-100" style="line-height: 15px;">
                <input matInput [(ngModel)]="enteredSecurityValue">
            </mat-form-field>
        </div>
        <!-- -------------- MultiLine Text --------------------- -->
        <div *ngIf="data.requirement.type == 1">
            <mat-form-field class="w-100" style="line-height: 15px;">
                <textarea matInput [(ngModel)]="enteredSecurityValue" rows="5"></textarea>
            </mat-form-field>
        </div>

        <!-- ------------- Int -------------------------------- -->
        <div *ngIf="data.requirement.type == 3">
            <mat-form-field >
                <input matInput type="number" step="1" pattern="\-?\d*" [(ngModel)]="enteredSecurityValue">
            </mat-form-field>
        </div>
        <!-- ------------- Double ----------------------------- -->
        <div *ngIf="data.requirement.type == 4">
            <mat-form-field class="w-100">
                <input matInput type="number" step="0.0001" [(ngModel)]="enteredSecurityValue">
            </mat-form-field>
        </div>
        <!-- ------------- Date ------------------------------- -->
        <div *ngIf="data.requirement.type == 5">
            <mat-form-field class="w-100">
                <input matInput type="date" [(ngModel)]="enteredSecurityValue">
            </mat-form-field>
        </div>
    </div>

    <div style="display:flex; flex-direction:row; justify-content:space-between; align-items:center;">
        <button mat-raised-button (click)="cancel()">
            <div style="display:flex; flex-direction:row; align-items:center">
                <div>{{'QRScan.Cancel' |translate}}</div>
                <span class="material-icons">clear</span>
            </div>
        </button>
        <button mat-raised-button (click)="checkSecurityValue()" [disabled]="enteredSecurityValue == null || enteredSecurityValue == undefined">
            <div style="display:flex; flex-direction:row; align-items:center;">
                <div>{{'QRScan.SecurityCheckButton' |translate}}</div>
                <span class="material-icons">lock_open</span>
            </div>
        </button>
    </div>
</div>