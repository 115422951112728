
<div *ngIf="!isLoading">
    <mat-select [(ngModel)]="selectedLanguage" (selectionChange)="updateLanguage($event)" style="width: 100px;">
      <mat-select-trigger style="height: 64px;">
        <img src="assets/img/flags/{{selectedLanguage.flag | lowercase}}.svg" alt="german" style="width: 60px; margin-top: 8px;">
      </mat-select-trigger>
      <mat-option *ngFor="let language of availableLanguages" [value]="language">
        <div style="display:flex; flex-direction: column; justify-content: center; align-items: center;">
            <img src="assets/img/flags/{{language.flag | lowercase}}.svg" alt="{{language.code}}" >
            <div>{{language.name | translate}}</div>
        </div>
      </mat-option>
    </mat-select>
</div>
