<!-- <button mat-icon-button class="example-double-arrow" 
(click)="previousClicked()">
<mat-icon>arrow_back</mat-icon>
</button>
<span class="example-header-label">{{periodLabel}}</span>
<button mat-icon-button class="example-double-arrow" 
(click)="closeClicked()">
<mat-icon>close</mat-icon>
</button> -->
<mat-card appearance="outlined" *ngIf="isLoading">
    <mat-card-content style="display:flex; flex-direction:row; justify-content:center; align-items:center;">
        <mat-spinner></mat-spinner>
    </mat-card-content>
    <div style="display:flex; flex-direction:row; flex-wrap:wrap; justify-content:center; align-items:center;">
        <h4><span>{{'LOADING' | translate}}</span> </h4>
    </div>
</mat-card>

<ng-template [ngIf]="!isLoading">
    <form [formGroup]="frmCalendar" class="form">
        <mat-calendar #calendar [dateClass]="dateClass()" [(selected)]="selectedDate"
            (selectedChange)="onSelect($event)" [minDate]="minDate" [maxDate]="maxDate"
            [startAt]="selectedMonth"></mat-calendar>
    </form>
</ng-template>