import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RequirementBlueprintDto } from 'src/app/shared/model/requirement-blueprint-dto.model';
import { QFlexQrScanService } from '../q-flex-qr-scan.service';

@Component({
  selector: 'app-q-flex-security-popup',
  templateUrl: './q-flex-security-popup.component.html',
  styleUrls: ['./q-flex-security-popup.component.scss']
})
export class QFlexSecurityPopupComponent implements OnInit {

    public enteredSecurityValue: any = null;

    constructor(
        public dialogRef: MatDialogRef<QFlexSecurityPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {requirement: RequirementBlueprintDto, wrongAnswer: boolean}
        ) { }

    ngOnInit() {
    }

    public checkSecurityValue()
    {
        this.dialogRef.close(this.enteredSecurityValue);
    }

    public cancel()
    {
        this.dialogRef.close(null);
    }

}
