import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfigSettingsService } from './config-settings.service';
import { IQFlexWorkloadDTO } from '../model/q-flex-workload-dto';


@Injectable({
    providedIn: 'root'
})
export class QFlexWorkloadService implements OnDestroy
{

    private subscriptions : Subscription[] = [];
    public workload : IQFlexWorkloadDTO;

    constructor( private http: HttpClient, private configSettingsService: ConfigSettingsService ) { 
        this.workload = new IQFlexWorkloadDTO();
    }

    public loadWorkload()
    {
        this.subscriptions.push(this.http.get<IQFlexWorkloadDTO>(this.configSettingsService.configSettings.apiUrl + "/api/qflex-workload/get-dto").subscribe(wl => this.workload = wl));
    }

    ngOnDestroy()
    {
        this.subscriptions.forEach(s => s.unsubscribe());
    }


}
