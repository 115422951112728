
Services:
<div class="userconcerns">
    <div *ngFor="let userconcern of userconcerns">
        {{getConcern(userconcern.id)}}
        <span> / Anzahl: {{userconcern.quantity}}</span>
        <a href="#"></a>
    </div>
</div>
<ng-template [ngIf]="userconcerns?.length === 0">
    <div class="">{{userConcernEmpty}}</div>
</ng-template>