import { Component, OnInit, Inject } from '@angular/core';
import { IConcern } from '../../shared/model/concern';


import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalSettings } from 'src/app/shared/config/globalsettings';
import { MessageService } from 'src/app/shared/services/messages.service';


@Component({
  selector: 'app-concerndetail',
  templateUrl: './concerndetail.component.html',
  styleUrls: ['./concerndetail.component.scss']
})
export class ConcernDetailComponent implements OnInit {

  private classame = (<any>this).constructor.name;

  globalSettings: GlobalSettings = new GlobalSettings();
  title: string;
  description: string;
  concern:any;
  constructor(
    private messageService: MessageService,
    public dialogRef: MatDialogRef<ConcernDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      
  
      if (data) {
        this.concern = data;
       

        // todo: use localisation
        //this.title = this.data.concern.languageKey;
        this.title = "todo";
        this.description = "todo";
        // if (this.globalSettings.devMode){
        //   this.description = this.data.concern.descriptionKey? this.data.concern.descriptionKey : "Hier könnte ihr Beschreibungstext stehen.";
        // } else {
        //   this.description = this.data.concern.descriptionKey? this.data.concern.descriptionKey : "";
        // }
       
    
      }

      //this.dialogRef.updateSize('300vw','300vw')


    }

  ngOnInit() {
    

  }

  onClick(): void {
    this.dialogRef.close();
  }


}
