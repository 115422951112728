import { Injectable, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Language } from '../modul/language';
import { Subject, Observable, Subscription } from 'rxjs';
import { MessageService } from './messages.service';
import { ConfigSettingsService } from './config-settings.service';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import * as moment from 'moment/moment';
import { TranslateService } from '@ngx-translate/core';
import { GlobalSettings } from '../config/globalsettings';

@Injectable({
  providedIn: 'root'
})
export class LanguageService implements OnDestroy {
  
 
  public currentLanguage:Language;
  private allLanguages:Language[] = [];
  public languageChangeSubject = new Subject<Language>();


  private subscriptions : Subscription[] = [];


  // translations
  translationObject: any;
  settings_time:string;
  settings_minute:string;
  



  constructor(
    private messageService: MessageService,
    private configSettingsService:ConfigSettingsService,
    private http: HttpClient, 
    private translate: TranslateService,

  ) 
  {
    this.getAllLanguages().subscribe(result => 
        {
            this.allLanguages = result;
            let defaultLanguage = this.allLanguages.find(l => l.isDefaultLanguage);
            this.changeLanguage(defaultLanguage);
        })
  }

  public getAllLanguages(): Observable<Language[]>
  {
    return this.http.get<Language[]>(this.configSettingsService.configSettings.apiUrl + "/api/language/get-all/");
  }

  public changeLanguage(language:Language): void
  {
    this.currentLanguage = language;
    this.setDefaultLang();
    this.languageChangeSubject.next(language);
  }


  public setDefaultLang(): void
  {
    // after this settings, the language resources will be loaded.
    // will be only called once at the beginning (app.component)
    this.translate.setDefaultLang(this.currentLanguage.code);

    // the time settings are defined at the language settings after language change
    // in the first initialisation is must be read from the configsettings.json
    if (this.currentLanguage.timeFormat === undefined){
      this.currentLanguage.timeFormat = this.configSettingsService.configSettings.timeSettings
    }

    this.subscriptions.push(
        this.translate.get(['SETTINGS.TIME','SETTINGS.MINUTE']).subscribe((translation: [string]) => {
            this.translationObject = translation;
            this.settings_time = this.translationObject['SETTINGS.TIME'];
            this.settings_minute = this.translationObject['SETTINGS.MINUTE'];
        })
    );
  
  }

  public setLanguage(id:string): void
  {

    let selectedLanguage = this.allLanguages.find(l => l.id == id);
    if(selectedLanguage)
    {
        this.changeLanguage(selectedLanguage);
    }
  }

  public setLanguageFromCode(languageCode:string) : void
  {

    let selectedLanguage = this.allLanguages.find(l => l.code == languageCode.toLocaleLowerCase());
    if(selectedLanguage)
    {
        this.changeLanguage(selectedLanguage);
    }
   }


  replaceSettingRessources(value:string){

    if(GlobalSettings.isEmpty(value))
    return "NotFound";

  if ((value.indexOf("SETTINGS.TIME")) !== -1){
    value = value.replace("SETTINGS.TIME",this.settings_time );
  }

  if ((value.indexOf("SETTINGS.MINUTE")) !== -1){
    value = value.replace("SETTINGS.MINUTE",this.settings_minute);

  }

  return (value);
}

  // replaces DateTime, Date, Time in a local string 
  getFormatDateString(str: string, inputDate:Date):string {

    if ((str.indexOf("{{DateTime}}")) !== -1) { //date with time place holder
    str = str.replace("{{DateTime}}", this.convertDateTime(new Date(inputDate)));
      return str;
    } else if (str.indexOf("{{Date}}") !== -1 ){ // date placeholder
      str = str.replace("{{Date}}", this.convertDate( new Date(inputDate)));
      return str;
    } else if (str.indexOf("{{Time}}") !== -1 ){ // date placeholder
      str = str.replace("{{Time}}", this.convertTime( new Date(inputDate)));
      return str;  
    } else {
      return (str);
    }
  }

  public replacePlaceholder( originalString: string, stringToReplace: string, value: any)
  {
      if(originalString.indexOf(stringToReplace) !== -1)
      {
          return originalString.replace(stringToReplace, value.toString());
      }
      console.warn("Could not find string: " + stringToReplace + "in: " + originalString);
      return originalString;
  }

  convertTime(date:Date):string {
    var convertToMoment  =   moment(date);
    let time = convertToMoment.format(this.currentLanguage.timeFormat);
    return time;
  }

  // one dependency and low risk configSettingsService, config-setting.json
  convertDate(date:any):string {
    let dateStr = new Date(date).toLocaleDateString(this.currentLanguage.code); //,this.configSettingsService.dateDisplayOptions
    return dateStr;
  }

  // risk of time settings.
  convertDateTime(dateTime:Date):string {
    var convertToMoment  =   moment(dateTime);
    // e.g. timeSettings HH:MM for german
    let time = convertToMoment.format(this.currentLanguage.timeFormat);

    let date = dateTime.toLocaleDateString(this.currentLanguage.code); //,this.configSettingsService.dateDisplayOptions
    let dateTimeString = date + " " + time
    
    //dateTimeString = dateTimeString.replace("{{DateTime}}", date + " " + time);
    return dateTimeString;
  }


  ngOnDestroy()
  {
      this.subscriptions.forEach(s => s.unsubscribe() );
  }

}
