<h2 style="text-align: center;">{{'SHIFT_APPOINTMENT.TITLE' | translate}}</h2>
<div class="page-content">

</div>
<div class="stepper-container">
    <mat-card appearance="outlined" *ngIf="isLoading">
        <mat-card-content style="display:flex; flex-direction:row; justify-content:center; align-items:center;">
            <mat-spinner></mat-spinner>
        </mat-card-content>
    </mat-card>

    <div class="page-content" *ngIf="!isLoading && currentStep == 'controlCode' ">
        <form [formGroup]="codeForm" class="form" novalidate>
            <div class="content-header-height" style="text-align: center;">
                <div [innerHTML]="'SHIFT_APPOINTMENT.HEADER_TEXT' | translate"></div>
            </div>

            <h2 *ngIf="appointmentIsToCloseToNow" style="text-align: center; color: red;">{{'SHIFT_APPOINTMENT.TO_CLOSE_TO_NOW' | translate}}</h2>
            <div class="center" style="display:flex; flex-direction:column; justify-content:start; align-items:center;">
                <mat-form-field class="form-element" style="max-width: 300px;">
                    <mat-label>{{'SHIFT_APPOINTMENT.CONTROL_CODE_INPUT' | translate}}</mat-label>
                    <input matInput placeholder="{{'SHIFT_APPOINTMENT.CONTROL_CODE_INPUT' | translate}}"
                        formControlName="controlCode">
                    <mat-error *ngIf="codeForm.controls.controlCode.errors?.required">{{'MESSAGES.EMTPY_DELETE_CODE' |
                        translate}}</mat-error>
                </mat-form-field>
                <mat-form-field class="form-element" style="max-width: 300px;">
                    <mat-label>{{'CONTACT_FORM.EMAIL' | translate}}</mat-label>
                    <input matInput placeholder="{{'CONTACT_FORM.EMAIL' | translate}}" formControlName="email">
                    <mat-error *ngIf="codeForm.controls.email.errors">{{'SHIFT_APPOINTMENT.VALIDATION_EMAIL' |
                        translate}}</mat-error>
                </mat-form-field>

                <div class="nav-footer-bar">
                    <div class="fixedRight">
                        <button mat-raised-button type="submit" (click)="getAppointment()"
                            matTooltip="{{'BUTTONS.NEXT' | translate}}" [disabled]="!codeForm.valid">
                            <div style="display:flex; flex-direction:row; align-items:center">
                            <div>{{'BUTTONS.NEXT' | translate}}</div>
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div *ngIf="!isLoading && currentStep=='selectEmployee'">
        <mat-form-field>
            <mat-label>{{'EMPLOYEE.LABEL' | translate}}</mat-label>
            <mat-select (selectionChange)="selectedEmployee($event)" [value]="targetId">
                <mat-form-field class="w-100">
                    <mat-icon class="material-icons" matPrefix>search</mat-icon>
                    <input matInput (keyup)="filterDisplayedEmployees($event.target.value)"
                        placeholder="{{ 'EMPLOYEE.SEARCH' | translate }}" autocomplete="off"
                        (keydown)="$event.stopPropagation()">
                </mat-form-field>
                <mat-option [value]="-1">
                    {{'EMPLOYEE.ANY' | translate}}
                </mat-option>
                <mat-option *ngFor="let employee of filteredDisplayedEmployees" [value]="employee.id">
                    {{employee.lastName}}, {{employee.firstName}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div class="nav-footer-bar">
            <div class="fixedLeft">
                <button mat-raised-button (click)="previousStep()">
                    <div style="display:flex; flex-direction:row; align-items:center;">
                        <mat-icon>keyboard_arrow_left</mat-icon>
                        <div>{{'BUTTONS.BACK' | translate}}</div>
                    </div>
                </button>
            </div>

            <div class="fixedRight">
                <button mat-raised-button type="submit" (click)="getPossibleDays()" [disabled]="!codeForm.valid">
                    <div style="display:flex; flex-direction:row; align-items:center;">
                        <div>{{'BUTTONS.NEXT' | translate}}</div>
                        <mat-icon>keyboard_arrow_right</mat-icon>
                    </div>
                </button>
            </div>
        </div>
    </div>


    <div class="page-content" *ngIf="!isLoading && currentStep == 'selectTime'">

        <h3 style="text-align: center;">{{languageService.getFormatDateString('SHIFT_APPOINTMENT.PREVIOUS_APPOINTMENT_START' |
            translate,appointment.start)}}</h3>

        <div class="appointment-content">
            <div class="appointment-sec1">
                <div class="calendar-wrapper">
                    <div>
                        <ng-container #outlet></ng-container>
                    </div>
                    <app-calendar [datesToHiglightList]="datesToHighlight"
                        (notifyCalendarDay)="selectCalendarDay($event)" class="no-select"></app-calendar>
                </div>
            </div>

            <div class="appointment-space1">
            </div>

            <div class="appointment-sec2">
                <ng-template [ngIf]="displayStartTimes">
                    <form [formGroup]="selectTimeForm">
                        <label id="example-radio-group-label"></label>
                        <mat-radio-group aria-labelledby="example-radio-group-label"
                            formControlName="selectedDateWithTime" class="example-radio-group"
                            [(ngModel)]="selectedDateWithTime" (change)="selectAppointmentDate($event)">
                            <div formArrayName="timeSectionsArray">
                                <div
                                    *ngFor="let timeSection of selectTimeForm.controls.timeSectionsArray.controls; let i=index">
                                    <mat-accordion [formGroupName]="i">
                                        <mat-expansion-panel [expanded]="(i == 0)">
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>
                                                    {{'APPOINTMENT_CALENDAR.DAY_HOUR_HEADER_TEXT' | translate |
                                                    userSettings:timeSection.get('timeFrom').value:timeSection.get('timeTo').value}}
                                                </mat-panel-title>
                                                <mat-panel-description>
                                                </mat-panel-description>
                                            </mat-expansion-panel-header>

                                            <mat-radio-button class="sel-date-radio-button no-select"
                                                *ngFor="let availableDateTime of avilableHoursSelect[i]"
                                                [value]="availableDateTime"
                                                [checked]="(selectedDateWithTime?.getTime()===availableDateTime?.getTime())">
                                                {{availableDateTime | localdate:'ConfigTimeSettings'}}
                                            </mat-radio-button>
                                        </mat-expansion-panel>
                                    </mat-accordion>
                                </div>
                            </div>
                        </mat-radio-group>
                    </form>
                    <div class="selected-value">
                        <ng-template [ngIf]="selectedDateWithTime">
                            {{languageService.getFormatDateString('APPOINTMENT_CALENDAR.TEXT_SELECTED_VALUE' |
                            translate,selectedDateWithTime)}}
                        </ng-template>
                    </div>
                </ng-template>
            </div>

            <div class="appointment-space2"></div>

            <div class="sec3"></div>
        </div>

        <div class="nav-footer-bar">
            <div class="fixedLeft">
                <button mat-raised-button (click)="previousStep()">
                    <div style="display:flex; flex-direction:row; align-items:center">
                        <mat-icon>keyboard_arrow_left</mat-icon>
                        <div>{{'BUTTONS.BACK' | translate}}</div>
                    </div>
                </button>
            </div>

            <div class="fixedRight">
                <button mat-raised-button type="submit" (click)="confirmInput()" [disabled]="newAppointmentStart == null">
                    <div style="display:flex; flex-direction:row; align-items:center;">
                        <div>{{'BUTTONS.NEXT' | translate}}</div>
                        <mat-icon>keyboard_arrow_right</mat-icon>
                    </div>
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="!isLoading && currentStep=='confirmInput'">
        
        <div style="display:flex; flex-direction:row; justify-content:space-evenly; align-items:center;">
            <!-- previous data -->
            <div style="display:flex; flex-direction:column;">
                <p>{{'SHIFT_APPOINTMENT.CONFIRMATION.PREVIOUS.START' | translate}}: <br/><b>{{languageService.convertDate(appointment.start)}} {{languageService.convertTime(appointment.start)}}</b></p>
                <p *ngIf="canSelectEmployee">{{'SHIFT_APPOINTMENT.CONFIRMATION.PREVIOUS.EMPLOYEE' | translate}}: <br /><b>{{appointment.employeeFirstName}} {{appointment.employeeLastName}}</b></p>
            </div> 
            <div style="display:flex; flex-direction:column;">
                <p>
                    <span class="material-icons">
                    arrow_right_alt
                    </span>
                </p>

                <p *ngIf="canSelectEmployee">
                    <br />
                    <span class="material-icons">
                        arrow_right_alt
                    </span>
                </p>
            </div>
            <!-- new data after shifting -->
            <div style="display:flex; flex-direction:column;">
                <p>{{'SHIFT_APPOINTMENT.CONFIRMATION.CURRENT.START' | translate}}: <br /><b>{{languageService.convertDate(newAppointmentStart)}} {{languageService.convertTime(newAppointmentStart)}}</b></p>
                <p *ngIf="canSelectEmployee">{{'SHIFT_APPOINTMENT.CONFIRMATION.CURRENT.EMPLOYEE' | translate}}: <br /><b>{{getCurrentEmployeeName() | translate}}</b></p>
            </div>
        </div>

        <div class="nav-footer-bar">
            <div class="fixedLeft">
                <button mat-raised-button (click)="previousStep()">
                    <div style="display:flex; flex-direction:row; align-items: center;">
                        <mat-icon>keyboard_arrow_left</mat-icon>
                        <div>{{'BUTTONS.BACK' | translate}}</div>
                    </div>
                </button>
            </div>

            <div class="fixedRight">
                <button mat-raised-button type="submit" (click)="shift()">
                    <div style="display:flex; flex-direction:row; align-items:center;">
                        <div>{{'SHIFT_APPOINTMENT.TITLE' | translate}}</div>
                        <mat-icon>keyboard_arrow_right</mat-icon>
                    </div>
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="currentStep == 'appointmentShifted'">
        <h2 style="text-align: center;">{{'SHIFT_APPOINTMENT.SHIFTED' | translate}}</h2>
    </div>

</div>