import { Component, OnInit, OnDestroy } from '@angular/core';
import { CompanyService } from 'src/app/shared/services/company.service';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-legal-notice',
    templateUrl: './legal-notice.component.html',
    styleUrls: ['./legal-notice.component.scss']
})
export class LegalNoticeComponent implements OnInit, OnDestroy
{

    private subscriptions : Subscription[] = [];
    public legalNotice: string;

    constructor(private companyService: CompanyService,  private translateService: TranslateService, public title: Title) { }

    ngOnInit()
    {
        this.subscriptions.push(this.translateService.get(['APPLICATION_TITLE','MENU.LEGAL_NOTICE']).subscribe((translation: [string]) => {
            this.title.setTitle(translation['APPLICATION_TITLE'] + " > " + translation['MENU.LEGAL_NOTICE']);  
        }));

        this.subscriptions.push(this.companyService.getLegalNotice().subscribe(data => {
             this.legalNotice = data.legalNotice;
        }));
    }

    ngOnDestroy()
    {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

}
