import { Injectable, EventEmitter, Output } from "@angular/core";

import { Observable, of, Subject } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, map, tap } from "rxjs/operators";

import { LOCATIONS } from "../mock/mock-location";
import { ILocation } from "../model/location";
import { MessageService } from "./messages.service";
import { IUserConcern } from "../model/userconcern";
import { GlobalSettings } from "../config/globalsettings";
import { ConfigSettings } from "src/app/config-settings";
import { ConfigSettingsService } from "./config-settings.service";
import { IPublicEmployee } from "../model/public-employee";

@Injectable({
  providedIn: "root",
})
export class LocationService {
  private classame = (<any>this).constructor.name;

  configSettings: ConfigSettings;
  globalSettings: GlobalSettings = new GlobalSettings();

  private serviceUrl: string;
  private urlFile: string;
  private urlApi: string;
  loadedLocationsInvalid: boolean = true;

  // Holds the records for the entire application
  private locations: ILocation[] | null;

  private selectedLocationSource = new Subject<ILocation | undefined>();
  selectedLocationChanges$ = this.selectedLocationSource.asObservable();

  //value can be changed from the component with this method.
  changeSelectedLocation(selectedLocation: ILocation | undefined): void {
    this.selectedLocationSource.next(selectedLocation);
  }

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private configSettingsService: ConfigSettingsService
  ) {
    this.configSettings = this.configSettingsService.configSettings;
    this.urlFile = this.configSettings.clientUrl + "assets/location.json";
    this.urlApi = this.configSettings.apiUrl + "/api/offices/";
  }

  private _selectedLocation: ILocation;
  get selectedLocation(): ILocation {
    return this._selectedLocation;
  }

  getLocations(
    userConcerns: IUserConcern[],
    employee: IPublicEmployee
  ): Observable<ILocation[]> {
    // the loaded locations are hopefully invalid if the concerns have changed.
    if (!this.loadedLocationsInvalid) {
      return of(this.locations);
    }

    // without userConcerns in the body a request is useless!
    // instead: no locations will be displayed!
    if (userConcerns.length === 0) {
      this.locations = [];
      return of(this.locations);
    }

    this.serviceUrl = this.urlApi;

    let employeeId = "";
    if (!GlobalSettings.isEmpty(employee)) {
      employeeId = employee.id;
    }

    return this.http
      .post<ILocation[]>(
        this.configSettings.apiUrl + "/api/offices/employee/" + employeeId,
        userConcerns
      )
      .pipe(
        tap((data) => (this.loadedLocationsInvalid = false)),
        catchError(this.handleError<ILocation[]>("post Locations", []))
      );
  }

  getLocation(id: string): Observable<ILocation> {
    const foundItem = this.locations.find((x) => x.id === id);
    return of(foundItem);
  }

  getLocationsMock(): ILocation[] {
    return LOCATIONS;
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      this.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private error(message: string) {
    this.messageService.error(`${this.classame} : ${message}`);
  }
}
