
<!-- #region InfoBanner-->
<div *ngIf="infoBanner?.useInfoBanner && showBanner" class="bannerDiv" style="display:flex; flex-direction:row;" >
    <textarea matTextareaAutosize class="bannerTextArea" [style.background-color]="infoBanner.bannerColor" [style.color]="infoBanner.textColor"
        [style.font-weight]="infoBanner.boldTextVM" [style.font-style]="infoBanner.italicTextVM" 
        [style.font-size]="infoBanner.fontSize"  disabled>{{infoBanner | defaultTranslation : 'text'}}</textarea>
    <div [style.background-color]="infoBanner.bannerColor">
        <button mat-mini-fab color="basic" [style.background-color]="infoBanner.buttonColor" [style.color]="infoBanner.buttonTextColor"
         (click)="hideBanner()" style="margin: 10px;">
            <mat-icon >close</mat-icon>
        </button>
    </div>
</div>
<!-- #endregion InfoBanner-->

<!-- #region Header-->
<div *ngIf="showHeader && !isQRScan">
    <a *ngIf="headerImageIsLink" [href]="websiteLink" target="_new">
        <div class="header-picture" *ngIf="!mobileQuery.matches && !mobileHeightQuery.matches"
            style="background-position: center; background-repeat: no-repeat;" [style.background-image]="'url(' + headerImagePath + ')'"> 
            <img *ngIf="useHeaderBackgroundImage" [src]="headerBackgroundImagePath" style="height: 100%; width: 100%; z-index: -1;">
            <div *ngIf="!useHeaderBackgroundImage" style="height: 100%; width: 100%; z-index: -1;" [style.background-color]="headerBackgroundColor"></div>
        </div>
    </a>
    <div *ngIf="!headerImageIsLink">
        <div class="header-picture" *ngIf="!mobileQuery.matches && !mobileHeightQuery.matches"
            style="background-position: center; background-repeat: no-repeat;" [style.background-image]="'url(' + headerImagePath + ')'"> 
            <img *ngIf="useHeaderBackgroundImage" [src]="headerBackgroundImagePath" style="height: 100%; width: 100%; z-index: -1;">
            <div *ngIf="!useHeaderBackgroundImage" style="height: 100%; width: 100%; z-index: -1;" [style.background-color]="headerBackgroundColor"></div>
        </div>
    </div>
</div>
<!-- #endregion Header-->


<mat-sidenav-container #sidenav class="mat-sidenav-container" style="-webkit-user-select: none; user-select: none;" *ngIf="!isQRScan"
    [style.marginTop.px]="mobileQuery.matches ? 0 : 0">

    <!-- #region SideMenu -->
    <mat-sidenav #snav class="navigation" [mode]="mobileQuery.matches ? 'over' : 'side'"
        [fixedInViewport]="mobileQuery.matches" fixedTopGap="0">

        <mat-toolbar class="primary-colors toolbar_left md-medium-tall">
            <mat-toolbar-row class="toolbar_row">
                <a *ngIf="logoImageIsLink" [href]="websiteLink" target="_new">
                    <img class="logo" [src]="logoImagePath">
                </a>
                <img *ngIf="!logoImageIsLink" class="logo" [src]="logoImagePath" [title]="titleName"
                        [alt]="titleName">
            </mat-toolbar-row>
        </mat-toolbar>
        <mat-nav-list class="mat-nav-list">
            <a mat-list-item (click)="bookAppointment()" style="color: black !important;">{{'MENU.BOOK_APPOINTMENT' | translate}}</a>
            <a mat-list-item (click)="confirmAppointment()" style="color: black !important;">{{'MENU.CODE_CONFIRM' | translate}}</a>
            <a *ngIf="enableShiftAppointment" mat-list-item (click)="shiftAppointment()" style="color: black !important;">{{'MENU.SHIFT_APPOINTMENT' | translate}}</a>
            <a *ngIf="enableDeleteAppoinmtent" mat-list-item
                (click)="deleteAppointment()" style="color: black !important;">{{'MENU.CANCEL_APPOINTMENT' | translate}}</a>
            <a mat-list-item routerLink="/q-flex" style="color: black !important;" *ngIf="showTicketInfo">{{'MENU.Q-FLEX' | translate}}</a>
            <a mat-list-item routerLink="/legal-notice" style="color: black !important;">{{'MENU.LEGAL_NOTICE' | translate}}</a>
        </mat-nav-list>
    </mat-sidenav>
    <!-- #endregion SideMenu -->

    <!-- #region SiteContent -->
    <mat-sidenav-content class="sidenav-content" *ngIf="!isQRScan">
      <!--  [style.marginTop.px]="mobileQuery.matches ? 3 : 0" -->
        <mat-toolbar  class="primary-colors nav-toolbar md-medium-tall">
            <mat-toolbar-row>
                <span><button mat-icon-button (click)="snav.toggle()">
                        <mat-icon>menu</mat-icon>
                    </button></span>
                <span>
                    <h2>{{titleName}}</h2>
                </span>
                <span class="example-fill-remaining-space"></span>

                <app-language-settings *ngIf="showFlags">
                </app-language-settings>

            </mat-toolbar-row>
        </mat-toolbar>

        <router-outlet></router-outlet>
    </mat-sidenav-content>
    <!-- #endregion SiteContent -->
</mat-sidenav-container>


<!-- QR-Scan -->
<div *ngIf="isQRScan" style="overflow: auto; height: 100vh;" class="hideScrollbar">
    <router-outlet></router-outlet>
</div>


<!-- #region Footer -->
<div class="desktop-footer-bar" *ngIf="!isQRScan">
    <mat-toolbar class="primary-colors">
        <a class="footer-link" href="{{'WEB_SITE_LINK' | translate}}" target="_new">
            <span>
                <img *ngIf="useBlackSchaufLogo" class="logo primary-colors" src="./assets/img/Schauf-Logo-Dunkel.png">
                <img *ngIf="!useBlackSchaufLogo" class="logo primary-colors" src="./assets/img/Schauf-Logo-Hell.png">
            </span>
        </a>
        <p>Q-Org v.1.17.5.2326</p>

        <span class="example-fill-remaining-space"></span>
        <a class="footer-link" href="{{'WEB_SITE_LINK' | translate}}" target="_new"><span
                class="desktop-footer-text-bar primary-colors" [innerHTML]="get_right_footer_text()"></span></a>
    </mat-toolbar>
</div>
<!-- #endregion Footer -->