

export class IConcernGroupLocalization {
  name: string;
  description: string;
  languageCode:string;
}

export class IConcernGroup {
    id: string;
    localizations:IConcernGroupLocalization[];
    //languageKey: string;
    //descriptionLanguageKey: string;
    amount:number;
    duration: number;
  }

  export class IConcernGroupCounter {
    id: string;
    amount: number;
    duration: number;
  }