import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { IUserConcern } from '../model/userconcern';
import { IPublicEmployee } from '../model/public-employee';


@Injectable({
  providedIn: 'root'
})


export class UserConcernService {

  private userconcerns: IUserConcern[] = [];

  private employee: IPublicEmployee;
  
  constructor(private http: HttpClient) {

  }

  public setEmployee(employee)
  {
      this.employee = employee;
      this.deleteUserConcerns();
  }

  public getEmployee(): IPublicEmployee
  {
      return this.employee;
  }

  getUserConcerns$(): Observable<IUserConcern[]> {
      return of(this.userconcerns);
  }
  
  getUserConcerns(): IUserConcern[] {
    return this.userconcerns;
  }

  deleteUserConcerns() {
    this.userconcerns = [];
  }

  deleteUserConcern(userconcern: IUserConcern) {
    const foundIndex =  this.userconcerns.findIndex (x => x.id === userconcern.id)
    this.userconcerns.splice(foundIndex,1);
    
  }

  deleteUserConcernById(concernid: string) {
    const foundIndex =  this.userconcerns.findIndex (x => x.id === concernid)
    this.userconcerns.splice(foundIndex,1);
    
  }

  updateUserConcern(userconcern: IUserConcern): Observable<IUserConcern> {

    const foundIndex =  this.userconcerns.findIndex (x => x.id === userconcern.id)
    
    if (foundIndex > -1){ 
    this.userconcerns[foundIndex] = userconcern;
    } else {
      this.userconcerns.push(userconcern);
    }  
    return of(userconcern);
  }

  updateUserConcernQuantity(concernid: string, quantity: number): Observable<number> {
    
    const foundIndex =  this.userconcerns.findIndex (x => x.id === concernid)
    
    if ((foundIndex === -1) && (quantity > 0)) { //add new record
      
      
      let userconcern = new IUserConcern();
      userconcern.id = concernid;
      userconcern.quantity = quantity;
      this.userconcerns.push(userconcern);

    } else if (foundIndex > -1){

      const foundRecord = this.userconcerns.find (x => x.id === concernid)
      
      // new value from old and changes.
      foundRecord.quantity = foundRecord.quantity + quantity;
     
      if (foundRecord.quantity <= 0){ // if count >= 0 => delete the record

        this.userconcerns.splice(foundIndex,1);
      } else { // update quantity of record
       
        this.userconcerns[foundIndex] = foundRecord;
      }
    }
    return of(quantity);
  }
}
