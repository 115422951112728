<div class="page-content">
    <ng-template [ngIf]="(!isInStep || showTitleInStepper)">
        <h2 style="text-align: center;" *ngIf="showConfirmationForm">{{'CANCEL_APPOINTMENT.TITLE' | translate}}</h2>
        <h2 style="text-align: center;" *ngIf="!showConfirmationForm">{{'CANCEL_APPOINTMENT.CANCEL_COMPLETED_TITLE' |
            translate}}</h2>
    </ng-template>
    <div class="content-header-height" *ngIf="!showConfirmationForm">
        <!-- form submit messages -->
        <div style="text-align: center;" [innerHTML]="getReplaceText('CANCEL_APPOINTMENT.SUBMITTED_TEXT')"></div>
        <mat-card *ngIf="isLoading" style="margin-bottom: 20px;">
            <mat-card-content style="display:flex; flex-direction:row; justify-content:center; align-items:center;">
                <mat-spinner></mat-spinner>
            </mat-card-content>
        </mat-card>
    </div>
</div>
<div class="page-content">
    <form [formGroup]="frmGroup" class="form" novalidate *ngIf="showConfirmationForm">
        <div class="content-header-height" style="text-align: center;">
            <div class="center" style="min-width: 300px;" [innerHTML]="'CANCEL_APPOINTMENT.HEADER_TEXT' | translate">
            </div>
        </div>

        <mat-card *ngIf="isLoading" style="margin-bottom: 20px;">
            <mat-card-content style="display:flex; flex-direction:row; justify-content:center; align-items:center;">
                <mat-spinner></mat-spinner>
            </mat-card-content>
        </mat-card>

        <div class="center"
            style="display:flex; flex-direction:column; align-items:center; justify-content:start; min-width: 300px;">
            <mat-form-field class="form-element" style="max-width: 300px;">
                <mat-label>{{'CANCEL_APPOINTMENT.CONTROL_CODE_INPUT' | translate}}</mat-label>
                <input matInput placeholder="{{'CANCEL_APPOINTMENT.CONTROL_CODE_INPUT' | translate}}"
                    formControlName="controlCode">
                <mat-error *ngIf="frmGroup.controls.controlCode.errors?.required">{{'MESSAGES.EMTPY_DELETE_CODE' |
                    translate}}</mat-error>
            </mat-form-field>
            <!-- <mat-error >
              {{errorMessage}}  
          </mat-error> -->
            <mat-error *ngIf="confirmError">
                {{'CANCEL_APPOINTMENT.CODE_NOT_EXISTS' | translate}}
            </mat-error>

            <div class="nav-footer-bar">
                <div class="fixedRight">
                    <button mat-raised-button type="submit" [disabled]="!enableForm" (click)="submit()"
                        matTooltip="{{'CANCEL_APPOINTMENT.CONFIRM' | translate}}">
                        <div style="display:flex; flex-direction:row; align-items:center;">
                            <div>{{'BUTTONS.CONFIRM' | translate}}</div>
                            <mat-icon>keyboard_arrow_right</mat-icon>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
<ng-template [ngIf]="!showConfirmationForm">
    <div class="center">
        <div class="nav-footer-bar">
        </div>
    </div>
</ng-template>