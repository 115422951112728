import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { LanguageService } from 'src/app/shared/services/language.service';

import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { Language } from 'src/app/shared/modul/language';

@Component({
  selector: 'app-language-settings',
  templateUrl: './language-settings.component.html',
  styleUrls: ['./language-settings.component.scss']
})
export class LanguageSettingsComponent implements OnInit, OnDestroy {
  
  private subscriptions : Subscription[] = [];

  public isLoading: boolean = true;
  public availableLanguages: Language[] = [];
  public selectedLanguage: Language = null;
 
  constructor(
    private languageService:LanguageService
  ) { }

  ngOnInit() 
  {
    this.subscriptions.push(this.languageService.getAllLanguages().subscribe( languages => {
      this.availableLanguages = languages.filter ( x => x.isActive);

      let index = this.availableLanguages.findIndex( x => x.code == this.languageService.currentLanguage.code);
      if (index > -1)
      {
        this.selectedLanguage = this.availableLanguages[index];
      }      
      else
      {
        console.error("Could not find language for code: " + this.languageService.currentLanguage.code);
      }
      this.isLoading = false; 
    }));

    // subscribe for new flag
    this.subscriptions.push(this.languageService.languageChangeSubject.subscribe(language => {

        if(this.availableLanguages)
        {
            let index = this.availableLanguages.findIndex( x => x.code == language.code);
            if (index > -1)
            {
              this.selectedLanguage = this.availableLanguages[index];
            }
            else
            {
                console.error("Could not find availableLanguage for code: " + language.code);
            }
        }
      
    }));
  }



  updateLanguage(event) : void
  {
    this.languageService.setLanguage(event.value.id);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
