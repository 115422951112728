<div *ngIf="!enableFeature">
    <mat-card appearance="outlined" class="border">
        <div style="display:flex; flex-direction:row; justify-content:center; align-items:center;">
            <img *ngIf='settingsDto && settingsDto.logoUrl && settingsDto.showLogo && settingsDto.logoUrl.length > 0'
                [src]="settingsDto.logoUrl" style="height: 100%; width: 70%; max-width: 400px; max-height: 400px;">
            <img *ngIf='(!settingsDto || !settingsDto.logoUrl || settingsDto.logoUrl.length <= 0)' class="logo"
                style="height: 30%; width: 70%; max-width: 400px; max-height: 400px;"
                src="./assets/img/Schauf-Logo-Dunkel.png">
        </div>
        <h3 style="text-align: center;">{{'QRScan.FeatureUnavailable' | translate}}</h3>
    </mat-card>
</div>

<div *ngIf="enableFeature">

    <mat-card appearance="outlined" *ngIf="isLoading" class="border">
        <div style="display:flex; flex-direction:row; justify-content:center; align-items:center;">
            <img *ngIf='settingsDto && settingsDto.logoUrl && settingsDto.showLogo && settingsDto.logoUrl.length > 0'
                [src]="settingsDto.logoUrl" style="height: 100%; width: 70%; max-width: 400px; max-height: 400px;">
            <img *ngIf='(!settingsDto || !settingsDto.logoUrl || settingsDto.logoUrl.length <= 0)' class="logo"
                style="height: 30%; width: 70%; max-width: 400px; max-height: 400px;"
                src="./assets/img/Schauf-Logo-Dunkel.png">
        </div>
        <mat-card-content style="display:flex; flex-direction:row; align-items:center; justify-content:center;">
            <mat-spinner></mat-spinner>
        </mat-card-content>
    </mat-card>

    <div *ngIf="leaveQueueSuccessfull" class="border" style="text-align: center;">
        <div style="display:flex; flex-direction:row; justify-content:center; align-items:center;">
            <!-- "'url(' + settingsDto.logoUrl + ')'" -->
            <img *ngIf='settingsDto && settingsDto.logoUrl && settingsDto.showLogo && settingsDto.logoUrl.length > 0'
                [src]="settingsDto.logoUrl" style="height: 100%; width: 70%; max-width: 400px; max-height: 400px;">
            <img *ngIf='(!settingsDto || !settingsDto.logoUrl || settingsDto.logoUrl.length <= 0) && settingsDto.showLogo'
                class="logo" style="height: 30%; width: 70%; max-width: 400px; max-height: 400px;"
                src="./assets/img/Schauf-Logo-Dunkel.png">
        </div>

        <h2>{{'QRScan.LeftQueue' |translate}}</h2>

        <br />

        <div style="display:flex; flex-direction:row; justify-content:center; align-items:center;">
            <button mat-raised-button (click)="bookAppointment()">{{'QRScan.BookAppointment' | translate}}</button>
        </div>
    </div>

    <div *ngIf="!isLoading && !leaveQueueSuccessfull" class="border">

        <div style="display:flex; flex-direction:row; justify-content:flex-end; align-items:center">
            <app-language-settings style="margin: 5px;">
            </app-language-settings>
        </div>
        <!-- ------------------- Header Image & LanguageSelection- -------------------- -->
        <div style="display:flex; flex-direction:row; justify-content:center; align-items:center;">
            <img *ngIf='settingsDto && settingsDto.logoUrl && settingsDto.showLogo && settingsDto.logoUrl.length > 0'
                [src]="settingsDto.logoUrl" style="height: 100%; width: 70%; max-width: 400px; max-height: 400px;">
            <img *ngIf='(!settingsDto || !settingsDto.logoUrl || settingsDto.logoUrl.length <= 0) && settingsDto.showLogo'
                class="logo" style="height: 30%; width: 70%; max-width: 400px; max-height: 400px;"
                src="./assets/img/Schauf-Logo-Dunkel.png">
        </div>
        <br />

        <!-- --------------- InfoText ---------------------------------- -->
        <div style="text-align: center;" *ngIf="settingsDto"
            [innerHtml]="(settingsDto | defaultTranslation: 'text') | safeHtml"></div>

        <!-- ---------------- Error -------------------------------- -->
        <h3 style="text-align: center; color:red" *ngIf="showError">{{'QRScan.Error' | translate}}</h3>

        <br />

        <div *ngIf="settingsDto?.showTicketImage" style="text-align: center;" [innerHtml]="ticketImageString"></div>

        <div *ngIf="settingsDto?.showAddToWalletButtons" style="display:flex; flex-direction:row; justify-content:center; align-items:center;">
            <div [innerHTML]="settingsDto?.googleAddToWalletButton | safeHtml"></div>
            <div [innerHTML]="settingsDto?.appleAddToWalletButton | safeHtml"></div>
        </div>

        <!-- ---------------- Reload ------------------------------- -->
        <div style="display:flex; flex-direction:row; justify-content:center; align-items:center;">
            <button mat-mini-fab color="basic" (click)="reload()" [disabled]="reloadDisabled">
                <span class="material-icons" *ngIf="!reloadDisabled">autorenew</span>
                <span style="text-align: center;" *ngIf="reloadDisabled">{{timeTillReload}}</span>
            </button>
        </div>
        <br />

        <!-- -------------------- General Info ----------------------------- -->
        <div style="display:flex; flex-direction:row; justify-content:space-between; align-items:center;" class="container">
            <h3>{{"QRScan.GeneralTitle" | translate}}</h3>
            <div style="display:flex; flex-direction:row; justify-content:center; align-items:center;">
                <button mat-mini-fab color="basic" (click)="toggleGeneralInfo()">
                    <span class="material-icons" *ngIf="!showGeneralInfo">expand_more</span>
                    <span class="material-icons" *ngIf="showGeneralInfo">expand_less</span>
                </button>
            </div>
        </div>

        <div *ngIf="generalInfo && showGeneralInfo" class="container">
            <mat-card appearance="outlined" style="display:flex; flex-direction:row; justify-content:space-between; align-items:center;" *ngFor="let info of generalInfo.information">
                <span>{{info | defaultTranslation: 'name'}}:</span>
                <span style="word-break: break-word;">{{info | defaultTranslation: 'value'}}</span>
            </mat-card>
        </div>
        <h3 style="text-align: center;" *ngIf="generalInfo == null && showGeneralInfo">{{'QRScan.NoData' | translate}}
        </h3>

        <!--  -------------------- Private Info --------------------------- -->
        <hr *ngIf="settingsDto.showPrivateInformationButton" />
        <div style="display:flex; flex-direction:row; justify-content:space-between; align-items:center;" class="container"
            *ngIf="settingsDto.showPrivateInformationButton">
            <h3>{{"QRScan.PersonalInfoTitle" | translate}}</h3>
            <div style="display:flex; flex-direction:row; justify-content:center; align-items:center;">
                <button mat-mini-fab color="basic" (click)="togglePrivateInfo()">
                    <span class="material-icons" *ngIf="!displayPrivateInfo">expand_more</span>
                    <span class="material-icons" *ngIf="displayPrivateInfo">expand_less</span>
                </button>
            </div>
        </div>
        <div *ngIf="privateInfo && displayPrivateInfo" class="container">
            <mat-card appearance="outlined" style="display:flex; flex-direction:row; justify-content:space-between; align-items:center;" *ngFor="let info of privateInfo.information">
                <span>{{info | defaultTranslation: 'name'}}:</span>
                <span style="word-break: break-word;">{{info | defaultTranslation: 'value'}}</span>
            </mat-card>
        </div>
        <h3 style="text-align: center;" *ngIf="privateInfo == null && displayPrivateInfo">{{'QRScan.NoData' |
            translate}}</h3>

        <!-- --------------------------- History --------------------------------- -->
        <hr *ngIf="settingsDto.showTicketHistory" />
        <div style="display:flex; flex-direction:row; justify-content:space-between; align-items:center;" class="container"
            *ngIf="settingsDto.showTicketHistory">
            <h3>{{"QRScan.HistoryTitle" | translate}}</h3>
            <div style="display:flex; flex-direction:row; justify-content:center; align-items:center;">
                <button mat-mini-fab color="basic" (click)="toggleHistory()">
                    <span class="material-icons" *ngIf="!displayHistory">expand_more</span>
                    <span class="material-icons" *ngIf="displayHistory">expand_less</span>
                </button>
            </div>
        </div>
        <div *ngIf="history && displayHistory" style="width: 90%; margin-left: 5%; overflow-x: auto;">

            <table class="historyTable">
                <tr style="text-align: center; font-weight: bold; font-size: larger;">
                    <td *ngIf="showTimestamp">{{'QRScan.Timestamp' | translate}}</td>
                    <td *ngIf="showEvent">{{'QRScan.Event' | translate}}</td>
                    <td *ngIf="showEventInfo">{{'QRScan.EventInfo' | translate}}</td>
                    <td *ngIf="showNode">{{'QRScan.Node' | translate}}</td>
                    <td *ngIf="showUser">{{'QRScan.User' | translate}}</td>
                </tr>
                <tr *ngFor="let entry of history.information; let i=index;" style="text-align: center;"
                    [ngClass]="{'evenRow': 1 === i % 2}">
                    <td *ngIf="showTimestamp">{{entry | defaultTranslation: 'timestamp'}}</td>
                    <td *ngIf="showEvent">{{entry | defaultTranslation: 'event'}}</td>
                    <td *ngIf="showEventInfo">{{entry | defaultTranslation: 'eventInfo'}}</td>
                    <td *ngIf="showNode">{{entry | defaultTranslation: 'node'}}</td>
                    <td *ngIf="showUser">{{entry | defaultTranslation: 'user'}}</td>
                </tr>
            </table>

        </div>
        <h3 style="text-align: center;" *ngIf="history == null && displayHistory">{{'QRScan.NoData' | translate}}</h3>

        <!-- ---------------- Leave Queue ------------------- -->
        <hr *ngIf="settingsDto.showExitQueueButton" />
        <br />
        <div style="display:flex; flex-direction:row; justify-content:center; align-items:center;" *ngIf="settingsDto.showExitQueueButton">
            <button mat-raised-button (click)="exitQueue()">
                {{'QRScan.ExitQueueButton' | translate}}
                <span class="material-icons">directions_walk</span>
            </button>
        </div>
<!-- this space is added for iphones to show the button properly.... -->
        <br />
        <br />
        <br />
        <br />
        <br />
    </div>

</div>