// https://material.angular.io/components/toolbar/overview


import { ChangeDetectorRef, Component, OnDestroy, NgZone, OnInit, AfterViewInit, Inject } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { GlobalSettings } from './shared/config/globalsettings';
import { MessageService } from './shared/services/messages.service';
import { ConfigSettings } from './config-settings';
import { getLocaleDateFormat, DOCUMENT } from '@angular/common';
import { ConfigSettingsService } from './shared/services/config-settings.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { LanguageService } from './shared/services/language.service';
import { Language } from './shared/modul/language';
import { Subscription } from 'rxjs';
import { CalendarService } from './shared/services/calendar.service';
import { ServerSettingsService } from './shared/services/server-settings.service';
import { InfoBannerService } from './shared/services/info-banner.service';
import { IInfoBanner } from './shared/model/info-banner.model';
import { timeout } from 'rxjs/operators';
import { QFlexWorkloadHub } from './shared/services/q-flex-workload-hub.service';
import { OnlineDesignService } from './shared/services/online-design.service';
import { ServerSettingService } from './shared/services/server-setting.service';

//import Styles from 'style-loader!css-loader?modules!./styles1.scss';

//import purple from '@material/theme/_variables.scss';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})




export class AppComponent implements OnInit, OnDestroy
{


    private classame = (<any>this).constructor.name;
    private subscriptions: Subscription[] = [];
    translationObject: any;

    configSettings: ConfigSettings;
    globalSettings: GlobalSettings = new GlobalSettings();
    mobileQuery: MediaQueryList;
    mobileHeightQuery: MediaQueryList;
    public enableDeleteAppoinmtent = false;
    public enableShiftAppointment = false;
    imagePath: string;
    RIGHT_FOOTER_TEXT: string;
    currentDateTime: Date;
    private _mobileQueryListener: () => void;


    public isQRScan: boolean = false;

    // --------------- online design ---------------
    public titleName: string = "Q-Org";
    public websiteLink: string = "schauf.eu";
    public showFlags: boolean = true;
    public headerImageIsLink: boolean = true;
    public logoImageIsLink: boolean = true;
    public logoImagePath: string = "";

    public showHeader: boolean = false;
    public headerImagePath: string = "";
    public headerBackgroundImagePath: string = "";
    public useHeaderBackgroundImage: boolean = false;
    public headerBackgroundColor: string = "black";

    public useBlackSchaufLogo: boolean = false;
    public showTicketInfo:boolean = false;


    // ------------------- banner ----------------------------
    public infoBanner: IInfoBanner;

    public showBanner: boolean;

    constructor(
        calenderService: CalendarService,
        private messageService: MessageService,
        private configSettingsService: ConfigSettingsService,
        private translate: TranslateService,
        private route: ActivatedRoute,
        private router: Router,
        private languageService: LanguageService,
        private onlineDesignService: OnlineDesignService,

        changeDetectorRef: ChangeDetectorRef,
        private serverSettingsService: ServerSettingsService,
        private serverSettingService: ServerSettingService,

        private workloadHub: QFlexWorkloadHub,

        private bannerService: InfoBannerService,
        media: MediaMatcher,
        @Inject(DOCUMENT) private document: Document
    )
    {
        this.setStyle(true);
        this.showBanner = true;
        router.initialNavigation();

        router.events.subscribe( (event) => 
            {
                if (event instanceof NavigationEnd) {
                    // Hide loading indicator
                    if(event.url.toLowerCase().indexOf("qr-scan") >= 0)
                    {
                        this.isQRScan = true;
                    }
                    else
                    {
                        this.isQRScan = false;
                    }
                }

            });

        workloadHub.startConnection();
        workloadHub.receiveWorkload();

        // the date of the server is initialised
        this.subscriptions.push(this.serverSettingsService.getServerDate().subscribe(date =>
        {
            this.currentDateTime = date.dateTimeNow;
        }));

        let tmpLang : Language = 
        {
            code: "de",
            flag: "de",
            isActive: true,
            isDefaultLanguage: false,
            timeFormat: "HH:mm",
            order: -1,
            name: "Deutsch",
            id: "0"
        };
        this.languageService.currentLanguage = tmpLang;
        this.languageService.setDefaultLang();

        this.enableDeleteAppoinmtent = configSettingsService.configSettings.enableDeleteAppoinmtent !== undefined ? configSettingsService.configSettings.enableDeleteAppoinmtent : true;


        this.serverSettingService.settingsBehaviourSubject.subscribe(s => this.enableShiftAppointment = s.enableOnlineShiftAppointment);

        this.configSettings = this.configSettingsService.configSettings;
        this.mobileQuery = media.matchMedia(`(max-width: ${this.configSettings.SMALL_WIDTH_BREAKPOINT}px)`);
        this.mobileHeightQuery = media.matchMedia(`(max-height: ${this.configSettings.SMALL_WIDTH_BREAKPOINT}px)`);

        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this._mobileQueryListener);


        this.imagePath = "./assets/img/logo.png";
    }


    ngOnInit()
    {
        this.setStyle(false);

        this.subscriptions.push(this.translate.get(['RIGHT_FOOTER_TEXT']).subscribe((translation: [string]) =>
        {
            this.translationObject = translation;
            this.RIGHT_FOOTER_TEXT = this.translationObject['RIGHT_FOOTER_TEXT'].replace("{{year}}", new Date().getFullYear().toString());
        }));

        this.subscriptions.push(this.bannerService.getInfoBannerSettings().subscribe(data => 
        {
            this.infoBanner = data;
            this.infoBanner.fontSize += "pt";
            this.infoBanner.italicTextVM = this.infoBanner.italicText ? "italic" : "normal";
            this.infoBanner.boldTextVM = this.infoBanner.boldText ? "bold" : "normal";
            this.infoBanner.buttonColor += " !important";
            this.infoBanner.buttonTextColor += " !important";
        }));
    }

    private setStyle(useDefault: boolean): void
    {
        if (useDefault)
        {
            // --------------- primary-color ---------------
            document.documentElement.style.setProperty('--primary-background-color', '#3f51b5');
            document.documentElement.style.setProperty('--primary-text-color', '#fff');

            // --------------- highlight-color ---------------
            document.documentElement.style.setProperty('--highlight-background-color', '#ff4081');
            document.documentElement.style.setProperty('--highlight-text-color', '#fff');

            // --------------- Buttons ---------------
            document.documentElement.style.setProperty('--button-background-color', '#3f51b5');
            document.documentElement.style.setProperty('--button-text-color', '#fff');

            // --------------- step edit state ---------------
            document.documentElement.style.setProperty('--step-edit-background-color', '#3f51b5');
            document.documentElement.style.setProperty('--step-edit-text-color', '#fff');

            // --------------- input highlight ---------------
            document.documentElement.style.setProperty('--input-highlight-color', '#3f51b5');
        }
        else
        {
            this.subscriptions.push(this.onlineDesignService.getOnlineDesignSettings().subscribe( dto => {

                // --------------- primary-color ---------------
                document.documentElement.style.setProperty('--primary-background-color', dto.primaryBackgroundColor);
                document.documentElement.style.setProperty('--primary-text-color', dto.primaryTextColor);

                // --------------- highlight-color ---------------
                document.documentElement.style.setProperty('--highlight-background-color', dto.highlightBackgroundColor);
                document.documentElement.style.setProperty('--highlight-text-color', dto.highlightTextColor);

                // --------------- Buttons ---------------
                document.documentElement.style.setProperty('--button-background-color', dto.buttonBackgroundColor);
                document.documentElement.style.setProperty('--button-text-color', dto.buttonTextColor);

                document.documentElement.style.setProperty('--info-button-text-color', dto.serviceInfoButtonTextColor);
                document.documentElement.style.setProperty('--add-button-text-color', dto.serviceAddButtonTextColor);
                document.documentElement.style.setProperty('--substract-button-text-color', dto.serviceSubstractButtonTextColor);

                // --------------- step edit state ---------------
                document.documentElement.style.setProperty('--step-edit-background-color', dto.stepEditBackgroundColor);
                document.documentElement.style.setProperty('--step-edit-text-color', dto.stepEditTextColor);

                // --------------- input highlight ---------------
                document.documentElement.style.setProperty('--input-highlight-color', dto.inputHighlightColor);

                
                this.showFlags = dto.showFlags;
                this.showTicketInfo = dto.showTicketInfo;
                this.onlineDesignService.showServiceDuration.next(dto.showServiceDuration);
                this.onlineDesignService.showServiceGroupDuration.next(dto.showServiceGroupDuration);
                this.onlineDesignService.showTotalDuration.next(dto.showTotalDuration);
                this.titleName = dto.titleName;
                this.websiteLink = dto.websiteLink;
                this.headerImageIsLink = dto.headerImageIsLink;
                this.logoImageIsLink = dto.logoImageIsLink;
                this.logoImagePath = dto.logoImagePath;

                this.showHeader = dto.showHeader;
                this.headerImagePath = dto.headerImagePath;
                this.headerBackgroundImagePath = dto.headerBackgroundImagePath;
                this.useHeaderBackgroundImage = dto.useHeaderBackgroundImage;
                this.headerBackgroundColor = dto.headerBackgroundColor;

                this.useBlackSchaufLogo = dto.useBlackSchaufLogo;

                let favIconElement: HTMLLinkElement  = document.querySelector("#appIcon");
                favIconElement.href = this.logoImagePath;
            }));
            
        }
    }

    private hideBanner()
    {
        // var div = document.getElementById("banner");
        // $(div).fadeOut("slow");

        // setTimeout(() => { this.showBanner = false;}, 1000);
        this.showBanner = false;
    }


    styleLoader()
    {

        //var styleUrl = "style1.4ab9b45d72f32b722d80.css"

        //var styleUrl = "style2.77ff7dde85cbd12ef69e.css"
        var styleUrl = "style1.scss"
        debugger;
        return new Promise((resolve, reject) =>
        {
            const styleElement = document.createElement('link');
            styleElement.href = styleUrl;
            styleElement.onload = resolve;
            document.head.appendChild(styleElement);
        });
    }

    get_right_footer_text()
    {
        return this.RIGHT_FOOTER_TEXT;
    }



    public bookAppointment(): void
    {
        let curRoute = this.router.url;
        if (curRoute == '/book-appointment/init')
        {
            this.document.defaultView.location.reload();
            return;
        }
        else
        {
            this.router.navigate(['book-appointment/init']);
        }

    }

    public confirmAppointment(): void
    {
        let curRoute = this.router.url;
        if (curRoute == '/confirm-appointment')
        {
            this.document.defaultView.location.reload();
            return;
        }
        else
        {
            this.router.navigate(['confirm-appointment']);
        }

    }

    public shiftAppointment(): void
    {
        let curRoute = this.router.url;
        if (curRoute == '/shift-appointment')
        {
            this.document.defaultView.location.reload();
            return;
        }
        else
        {
            this.router.navigate(['shift-appointment']);
        }
    }

    public deleteAppointment(): void
    {
        let curRoute = this.router.url;
        if (curRoute == '/cancel-appointment')
        {
            this.document.defaultView.location.reload();
            return;
        }
        else
        {
            this.router.navigate(['cancel-appointment']);
        }

    }

    ngOnDestroy(): void
    {
        this.mobileQuery.removeListener(this._mobileQueryListener);
        this.subscriptions.forEach(s => s.unsubscribe());
    }

}
