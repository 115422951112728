import { ObserversModule } from '@angular/cdk/observers';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigSettingsService } from 'src/app/shared/services/config-settings.service';
import { QFlexQRScanHistoryDto, QFlexQRScanInformationDto, QFlexQRScanInformationWithTicketImageDto, QFlexQRScanLeaveQueueResponseDto, QFlexQRScanTicketInformationDto, QRScanSettingsDto, SecurityDto } from './q-flex-qr-scan.model';

@Injectable({
  providedIn: 'root'
})
export class QFlexQrScanService 
{

    constructor(private http: HttpClient,  private configSettingsService: ConfigSettingsService) {

     }

     public getQRSettings(id: string): Observable<QRScanSettingsDto>
     {
        return this.http.get<QRScanSettingsDto>(this.configSettingsService.configSettings.apiUrl + "/api/qflex/get-qr-scan-settings/" + id);
     }

     public getGeneralInfo(securityValue: string, id: string): Observable<QFlexQRScanInformationWithTicketImageDto>
     {
         let dto: SecurityDto = 
         {
             id: id,
             securityValue: securityValue
         };
        return this.http.post<QFlexQRScanInformationWithTicketImageDto>(this.configSettingsService.configSettings.apiUrl + "/api/qflex/get-qr-general-info/", dto);
     }

     public getPrivateInfo(securityValue: string, id: string): Observable<QFlexQRScanInformationDto>
     {
         let dto: SecurityDto = 
         {
             id: id,
             securityValue: securityValue
         };
        return this.http.post<QFlexQRScanInformationDto>(this.configSettingsService.configSettings.apiUrl + "/api/qflex/get-qr-private-info/", dto);
     }

     public getHistory(securityValue: string, id: string): Observable<QFlexQRScanHistoryDto>
     {
         let dto: SecurityDto = 
         {
             id: id,
             securityValue: securityValue
         };
        return this.http.post<QFlexQRScanHistoryDto>(this.configSettingsService.configSettings.apiUrl + "/api/qflex/get-qr-history/", dto);
     }

     public exitQueue(securityValue: string, id: string): Observable<QFlexQRScanLeaveQueueResponseDto>
     {
        let dto: SecurityDto = 
        {
            id: id,
            securityValue: securityValue
        };
        return this.http.post<QFlexQRScanLeaveQueueResponseDto>(this.configSettingsService.configSettings.apiUrl + "/api/qflex/qr-leave-queue/", dto);
     }
}
