import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { MessageService } from './messages.service';
import { GlobalSettings } from '../config/globalsettings';
import { IUserConcern } from '../model/userconcern';
import { tap, catchError, map } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { IContactData } from '../model/contactdata';
import { ConfigSettings } from 'src/app/config-settings';
import { ConfigSettingsService } from './config-settings.service';
import { LanguageService } from './language.service';
import { ServerSettingService } from './server-setting.service';
import { IPublicEmployee } from '../model/public-employee';
import { BookAppointmentDto } from '../model/book-appointment-dto.model';
import { QMobileAppointmentDetailDto } from '../model/qmobile-appointment-detail-dto';

@Injectable({
    providedIn: 'root'
})
export class CustomerService
{

    private classame = (<any>this).constructor.name;

    configSettings: ConfigSettings;
    globalSettings: GlobalSettings = new GlobalSettings();


    private bookAtFieldOfficeAPIUrl: string;
    private confirmAppointmentAPIUrl: string;
    private deleteAppointmentAPIUrl: string;

    customerFormSent: boolean = false;
    notifyFormSent: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private http: HttpClient,
        private messageService: MessageService,
        private configSettingsService: ConfigSettingsService,
        private languageService: LanguageService,
        private globalSettingService: ServerSettingService
    )
    {

        this.configSettings = this.configSettingsService.configSettings;
        this.bookAtFieldOfficeAPIUrl = this.configSettings.apiUrl + "/api/appointment/book/fieldoffice";
        this.confirmAppointmentAPIUrl = this.configSettings.apiUrl + "/api/appointments/confirm/";
        this.deleteAppointmentAPIUrl = this.configSettings.apiUrl + "/api/appointments/customer-delete/";

    }

    // submit the customer form
    // error is catched somewhere else. 
    saveData(dto: BookAppointmentDto, employee: IPublicEmployee): any 
    {
        let languageCode = GlobalSettings.isEmpty(this.languageService.currentLanguage.code) ? this.globalSettingService.configSettings.localizationLanguage : this.languageService.currentLanguage.code;
        dto.customerLanguageCode = languageCode;

        dto.requirementValues.forEach(element => 
            {
                element.value = element.value == null ? null: element.value.toString();
            });

        dto.qMobileEndUserId = null;

        if (GlobalSettings.isEmpty(employee))
        {
            return this.http.post<QMobileAppointmentDetailDto>(this.bookAtFieldOfficeAPIUrl, dto);
        }
        else
        {
            return this.http.post<QMobileAppointmentDetailDto>(this.configSettings.apiUrl + "/api/appointment/book/employee/true", dto);
        }

    }

    // error is catched somewhere else.
    confirmCode(confirmCode: string): any
    {
        return this.http.get<any>(this.confirmAppointmentAPIUrl + confirmCode);
    }

    deleteAppointmentCode(deleteCode: string): Observable<HttpResponse<object>>
    {
        return this.http.get<HttpResponse<object>>(this.deleteAppointmentAPIUrl + deleteCode);
    }

}
