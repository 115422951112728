<mat-card appearance="outlined" *ngIf="isLoading">
    <mat-card-content style="display:flex; flex-direction:row; justify-content:center; align-items:center;">
        <mat-spinner></mat-spinner>
    </mat-card-content>
    <div style="display:flex; flex-direction:row; justify-content:center; align-items:center; flex-wrap: wrap;">
        <h4><span>{{'LOADING' | translate}}</span> </h4>
    </div>
</mat-card>

<div *ngIf="mobileQuery.matches">

<mat-card appearance="outlined" *ngIf="!isLoading && workloadService.workload.displayWorkload" style="flex:100;" class="workload-container" >
    <mat-card-content>
        {{'WORKLOAD.STATUS' | translate}}<button mat-mini-fab color="basic" [style.background-color]="workloadService.workload.color"
            style="margin: 10px;" disabled></button>
        {{ languageService.replacePlaceholder('WORKLOAD.WAITING_CUSTOMER_COUNT' | translate, 'WaitingCustomerCount', workloadService.workload.waitingCustomerCount) }}
        |
        {{ languageService.replacePlaceholder('WORKLOAD.AVG_WAITING_TIME' | translate, 'AvgWaitTime', workloadService.workload.avgWaitTime) }}
        
    </mat-card-content>
</mat-card>
</div>

<form [formGroup]="frmConcern" *ngIf="!isLoading">
    <div style="display:flex; flex-direction:row;" >

        <div style="flex: 100;">
            <div *ngIf="!isLoading && canSelectEmployeeOnline">
                <mat-form-field>
                    <mat-label>{{'EMPLOYEE.LABEL' | translate}}</mat-label>
                    <mat-select (selectionChange)="selectedEmployee($event)">
                        <mat-form-field class="w-100">
                            <mat-icon class="material-icons" matPrefix>search</mat-icon>
                            <input matInput (keyup)="filterDisplayedEmployees($event.target.value)" placeholder="{{ 'EMPLOYEE.SEARCH' | translate }}" autocomplete="off" (keydown)="$event.stopPropagation()">
                        </mat-form-field>
                        <mat-option [value]="">
                            {{'EMPLOYEE.ANY' | translate}}
                        </mat-option>
                        <mat-option *ngFor="let employee of filteredDisplayedEmployees" [value]="employee.id">
                            {{employee.lastName}}, {{employee.firstName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>


            <!-- <h2>{{'CONCERN.TITLE' | translate}}</h2> -->
            <input matInput formControlName="concernText" [hidden]="true">
            <div style="display:flex; flex-direction:row; justify-content:space-between;" class="flex-container">
                <div>
                    {{'CONCERN.HEADER_TEXT' | translate}}
                </div>
                <div *ngIf="showTotalDuration">{{'CONCERN.CURRENT_TOTAL_DURATION' | translate}}
                    {{'CONCERN.DURATION_TEXT' | translate | userSettings:calculateTotalDuration()}}</div>
            </div>



            <div class="flex-container">
                <mat-accordion *ngFor="let concernGroup of displayedConcernGroups">
                    <div class="flex-item">
                        <mat-expansion-panel [expanded]="(this.displayedConcernGroups.length == 1)"
                            class="list-content">
                            <mat-expansion-panel-header>
                                <mat-panel-title style="display:flex; flex-direction:row; justify-content:space-between;">
                                    {{concernGroup | defaultTranslation : 'name' }}
                                    <span *ngIf="showServiceGroupDuration" style="margin-left:5px"
                                        matTooltip="{{'CONCERN.TOOLTIP_DURATION_SERVICE_GROUP' | translate}}">

                                        {{'CONCERN.DURATION_TEXT' | translate | userSettings:calculateDuration(concernGroup.id)}}


                                    </span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <app-concern [concernGroupid]='concernGroup.id'
                                (concernGroupCounterNotify)="modifyConcern($event)">
                            </app-concern>
                        </mat-expansion-panel>
                    </div>
                </mat-accordion>
            </div>
            <mat-error>
                {{errorMessage}}
            </mat-error>


        </div>

        <mat-card appearance="outlined" *ngIf="!isLoading && workloadService.workload.displayWorkload && !mobileQuery.matches" style="flex:20;" class="workload-container" >
            <mat-card-content>
                {{'WORKLOAD.STATUS' | translate}}<button mat-mini-fab color="basic" [style.background-color]="workloadService.workload.color"
                style="margin: 10px;" disabled></button>
                <p> {{ languageService.replacePlaceholder('WORKLOAD.WAITING_CUSTOMER_COUNT' | translate, 'WaitingCustomerCount', workloadService.workload.waitingCustomerCount) }}</p>
                <p> {{ languageService.replacePlaceholder('WORKLOAD.AVG_WAITING_TIME' | translate, 'AvgWaitTime', workloadService.workload.avgWaitTime) }}</p>
            </mat-card-content>
        </mat-card>


    </div>
    <!-- <hr /> -->
    <div class="nav-footer-bar" style="margin-top: 0px;" >
        <div class="fixedRight">
            <!-- <a *ngIf="nextStepIsEnabled"  > -->
            <button mat-raised-button [disabled]="!nextStepIsEnabled"
                matTooltip="{{'CONCERN.NEXT_PAGE_TOOLTIIP' | translate}}" matStepperNext>
                <div style="display:flex; flex-direction:row; align-items: center;">
                    <div>
                        {{'BUTTONS.NEXT' | translate}}
                    </div>
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </div>
                
            </button>
        </div>
    </div>
</form>