import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { GlobalSettings } from 'src/app/shared/config/globalsettings';
import { ServerSettings } from 'src/app/shared/config/server-settings';
import { AppointmentDetailDto } from 'src/app/shared/model/appointment.model';
import { ICalendarDay } from 'src/app/shared/model/calendarday';
import { IPublicEmployee } from 'src/app/shared/model/public-employee';
import { ITimeSpan } from 'src/app/shared/model/timespan';
import { LanguageService } from 'src/app/shared/services/language.service';
import { ServerSettingService } from 'src/app/shared/services/server-setting.service';
import { ServerSettingsService } from 'src/app/shared/services/server-settings.service';
import { ShiftAppointmentDto } from './ShiftAppointment.model';
import { ShiftAppointmentService } from './ShiftAppointment.service';

const MIN: number = 60000;
const HOUR: number = 3600000;

@Component({
  selector: 'app-ShiftAppointment',
  templateUrl: './ShiftAppointment.component.html',
  styleUrls: ['./ShiftAppointment.component.scss']
})
export class ShiftAppointmentComponent implements OnInit, OnDestroy {

    // general
    private subscriptions: Subscription[] = [];
    public isLoading: boolean;
    public currentStep: string = "controlCode";
    public appointment: AppointmentDetailDto;
    private currentServerDate: Date;
    private serverDateNowString: string;
    public appointmentIsToCloseToNow: boolean;


    // Settings
    public canSelectEmployee: boolean;
    public minTimeToAppointment: number;
    public startTimeSteps: number;
    private roundAppointmentStart: boolean;


    // step controlCode
    public codeForm: UntypedFormGroup;

    // step selectEmployee
    private allPublicEmployees : IPublicEmployee[];
    public displayedPublicEmployees: IPublicEmployee[];
    public filteredDisplayedEmployees: IPublicEmployee[] = [];

    // step selectTime
    public selectTimeForm: UntypedFormGroup;
    public target: number = 0;
    public targetId: string = null;
    public datesToHighlight: Array<Number> = [];
    public displayStartTimes: boolean = false;
    public availableDateTimes: Array<Date> = [];
    public avilableHoursSelect: Date[][];
    public newAppointmentStart: Date = null;

    // Appointment Targets
    private readonly targetCurrentEmployee: number = 0;
    private readonly targetOtherEmployee: number = 1;
    private readonly targetAny: number = 3;

   


    constructor(
        private formBuilder: UntypedFormBuilder,
        private shiftAppointmentService: ShiftAppointmentService,
        private serverSettingService: ServerSettingService,
        private serverSettingsService: ServerSettingsService,
        private route: ActivatedRoute,
        private languageService: LanguageService,
        private translate: TranslateService,
    ) { }

    ngOnInit() 
    {
        this.codeForm = this.formBuilder.group({
            'controlCode': [null, Validators.required],
            'email': [null, [Validators.email, Validators.required]]
        });

        this.selectTimeForm = this.formBuilder.group({
            'selectedDateWithTime': [null, Validators.required],
            timeSectionsArray: this.formBuilder.array([])
        });

        this.serverSettingService.settingsBehaviourSubject.subscribe( (settings: ServerSettings) => {
            this.canSelectEmployee = settings.canSelectEmployeeOnline;
            this.minTimeToAppointment = settings.shiftAppointmentMinDistToStartInHours;
            this.startTimeSteps = settings.appointmentStartTimeSteps;
            this.roundAppointmentStart = settings.appointmentRoundStartMinutes;
        });

        this.subscriptions.push(this.serverSettingsService.getServerDate().subscribe(date =>
            {
                this.currentServerDate = date.dateTimeNow;
                this.serverDateNowString = date.serverTime;
            }));

        

        // check direct confirmation link.
        const paramCode = this.route.snapshot.paramMap.get('code');
        if (paramCode)
        {
            this.codeForm.setValue({
                'controlCode': paramCode,
                'email': null
            });
        }

        const paramLang = this.route.snapshot.paramMap.get('lang');
        if (paramLang)
        {
            this.languageService.setLanguageFromCode(paramLang);
            this.translate.setDefaultLang(paramLang);
        }
    }


    public previousStep(): void
    {
        switch(this.currentStep)
        {
            case "confirmInput":
                this.currentStep = "selectTime";
                break;
            case "selectTime":
                this.currentStep = this.canSelectEmployee ? "selectEmployee" : "controlCode";
                break;
            case "selectEmployee":
                this.currentStep = "controlCode";
                break;
        }
    }


    public async getAppointment(): Promise<void>
    {
        if(!this.codeForm.valid)
        {
            return;
        }

        let data = {...this.codeForm.value};
        this.isLoading = true;
        try
        {
            this.shiftAppointmentService.getAppointment(data.controlCode, data.email).subscribe(async (app) => {
                this.appointment = app;
            
                let timeDiffMS = moment(this.appointment.start).diff(moment(this.currentServerDate));
                let timeDiffMin = (timeDiffMS / 1000) / 60;
                let timeDiffHour = timeDiffMin / 60;

                if(timeDiffHour < this.minTimeToAppointment)
                {
                    this.appointmentIsToCloseToNow = true;
                    this.isLoading = false;
                    return;
                }
        

                if(this.canSelectEmployee)
                {
                    this.shiftAppointmentService.getPublicEmployees(this.appointment.id).subscribe(allPublicEmployees => {
                        this.allPublicEmployees = allPublicEmployees;
                    
                        this.allPublicEmployees.sort( (a , b) => a.lastName.localeCompare(b.lastName));
                        this.displayedPublicEmployees = [... this.allPublicEmployees];
                        this.filterDisplayedEmployees("");

                        this.currentStep = "selectEmployee";
                        this.target = this.targetAny;
                        this.targetId = null;
                    });
                }
                else
                {
                    this.currentStep = "selectTime";
                    this.targetId = null;
                    this.target = this.targetAny;
                    await this.getPossibleDays();
                }
                
                this.isLoading = false;
            });
        }
        catch(error)
        {
            console.error(error);
            this.isLoading = false;
            return;
        }
        

        
    }

    public filterDisplayedEmployees(input): void
    {
        if(input == 0 || input == "")
        {
            this.filteredDisplayedEmployees = this.displayedPublicEmployees;
        }
        else
        {
            this.filteredDisplayedEmployees = this.displayedPublicEmployees.filter(emp => (emp.firstName.toLowerCase() + " " + emp.lastName.toLowerCase()).includes(input.toLowerCase()));
        }
    }

    public selectedEmployee(event): void
    {
        if(event.value == -1 || event.value == undefined || event.value == null)
        {
            this.target = this.targetAny;
            this.targetId = null;
        }
        else
        {
            this.target = this.targetOtherEmployee;
            this.targetId = event.value;
        }
    }

    public async getPossibleDays(): Promise<void>
    {
        this.currentStep = "selectTime";
        this.isLoading = true;
        try
        {

            let possibleDays = await this.shiftAppointmentService.getPossibleDays(this.appointment.id, this.target, this.targetId);
            
            possibleDays.forEach(function (day)
            {
                let help = moment(day.day, "YYYY-MM-DDThh:mm");
                day.dayDate = help.toDate();
                day.dayNumber = day.dayDate.setHours(0, 0, 0, 0); // only interested in start of the day of current date
            });
            let earliestPossibleStartDate = new Date();
            earliestPossibleStartDate = new Date(earliestPossibleStartDate.setHours(earliestPossibleStartDate.getHours()+this.minTimeToAppointment));
    
            // remove all days, that lie in the past
            possibleDays = possibleDays.filter(x => x.dayDate >= new Date(new Date(earliestPossibleStartDate).setHours(0, 0, 0, 0)) );
            this.datesToHighlight = possibleDays.map(x => x.dayNumber);

            this.isLoading = false;
        }
        catch(error)
        {
            console.error(error);
            this.isLoading = false;
        }
    }

    public async selectCalendarDay(selectedDate: number): Promise<void>
    {

        this.displayStartTimes = true;

        //convert the number into the date format.
        let thisDate = new Date(selectedDate);

        // CAREFUL! Have to send the day with time 00:00 otherwise some timespans might not be detected from backend
        let daySpans: Array<ITimeSpan> = await this.shiftAppointmentService.getPossibleTimespans(this.appointment.id, this.target, this.targetId, moment(thisDate).format("YYYY-MM-DDT00:00"))
        daySpans.forEach((e) =>
        {
            if (e["start"] !== undefined)
            {
                // convert to moment, best option to transform to a date
                e.momentStart = moment(e.start, "YYYY-MM-DDThh:mm");
                let startdate = new Date(e.momentStart);
                e.startTimeDate = new Date(startdate);
                e.startTimeNumber = new Date(startdate).getTime();
            }
            if (e["end"] !== undefined)
            {
                e.momentEnd = moment(e.end, "YYYY-MM-DDThh:mm");

                let enddate = new Date(e.momentEnd);
                e.endTimeDate = new Date(enddate);
                e.endTimeNumber = new Date(enddate).getTime();
            }
        });
        
        let thisSpans = daySpans.filter(
            x => 
                x.startTimeDate.getDay() === thisDate.getDay()
                && x.startTimeDate.getMonth() === thisDate.getMonth()
                && x.startTimeDate.getFullYear() === thisDate.getFullYear()
        );
        

        // prepares our this.daySpans for display 
        this.setModifiedStartDay(thisSpans);

        let availableDateTimesLocal: Date[] = [];
        
        thisSpans.forEach((e) =>
        {
            for (let suggestedTime = e.startTimeNumber; suggestedTime <= e.endTimeNumber; suggestedTime = suggestedTime + (this.startTimeSteps * MIN))
            {
                availableDateTimesLocal.push(new Date(suggestedTime));
            }
        });
       
        // global variable not available in the inner scope
        this.availableDateTimes = availableDateTimesLocal;

        this.initTimeSections();

    }

    private setModifiedStartDay(thisSpans: ITimeSpan[])
    {
        // 1 modification: check configuration values in hours for first possible booking   
        // step 1.1: filter out values where the enddate is earlier as the config value. 
        let startHours = Math.floor(this.minTimeToAppointment);

        let momentDate = moment(this.serverDateNowString, "YYYY-MM-DDThh:mm");
        let serverDateHelper = momentDate.toDate();
        let newDate = serverDateHelper;

        let EarliestDateNumber = newDate.setHours(newDate.getHours() + startHours);
        // this.EarliestDateNumberDev = EarliestDateNumber;

        thisSpans.filter(x => x.endTimeNumber > EarliestDateNumber)

        // step 1.2: modify the startdate of all, 
        // where the startdate is earlier then the config value
        thisSpans.forEach(function (e)
        {
            //let date = new Date(e.startTimeNumber);
            if (e.startTimeNumber <= EarliestDateNumber)
            {

                e.startTimeNumber = EarliestDateNumber;
                e.startTimeDate = new Date(EarliestDateNumber);
            }

        });

        // 2 modifcation
        // check if rounded values should be displayed for the start date.

        if (this.roundAppointmentStart)
        {
            let minuteSpan = this.startTimeSteps;
            thisSpans.forEach(function (e)
            {
                // get a valid date from the number value 
                let date = new Date(e.startTimeNumber);
                // start minutes value of the dayspan
                let minutes = date.getMinutes();
                for (let minutesCounter = minutes; minutesCounter <= (minutes + minuteSpan); minutesCounter = minutesCounter + 1)
                {
                    let helper = minutesCounter / minuteSpan;
                    let helper2 = Math.round(helper);
                    if (helper === helper2)
                    {
                        //wir haben den startzeitpunkt der minuten ermittelt.
                        // date
                        var x = date.setMinutes(0);
                        var y = new Date(x);
                        var z = y.setMinutes(minutesCounter);
                        e.startTimeNumber = z;
                        e.startTimeDate = new Date(z);
                        break;
                    }
                }
            });
        }
    }


    initTimeSections()
    {
        let avilableHoursSelect = new Array<Array<Date>>();

        avilableHoursSelect = this.getIndexHourDay2DArray(this.availableDateTimes);
        let timeSections = this.selectTimeForm.get('timeSectionsArray') as UntypedFormArray;

        // delete old elements from previous selected day.
        let count = timeSections.controls.length;
        for (let i = 0; i < count; i++)
        {
            timeSections.removeAt(0);
        }

        for (let i = 0; i < avilableHoursSelect.length; i++)
        {
            // find full hour which is before the first entry for header display
            let timeFrom = avilableHoursSelect[i][0];
            var timeFromHours = timeFrom.getHours();

            // time from is the earliest possible time
            var momentDate = moment(timeFrom, "YYYY-MM-DDThh:mm");
            // calculate 

            // todo: check if this also works
            var momentStartDay = momentDate.format("YYYY-MM-DD" + "T00:00:00");
            var momentStartDay = momentDate.format("YYYY-MM-DD" + " 00:00:00");

            let help = moment(momentStartDay, "YYYY-MM-DDThh:mm");
            var helpDate = help.toDate();

            timeFrom = new Date(helpDate.setHours(helpDate.getHours() + timeFromHours));
            let timeTo = new Date(timeFrom);
            timeTo = new Date(timeTo.setHours(timeTo.getHours() + 1));

            let timeFromString = this.languageService.convertTime(timeFrom);
            let timeToString = this.languageService.convertTime(timeTo);

            let hourHeader = "" ;// this.APPOINTMENT_CALENDAR_DAY_HOUR_HEADER_TEXT.replace("{{StartHour}}", timeFromString).replace("{{EndHour}}", timeToString);


            timeSections.push(this.formBuilder.group({
                radioSection: false,
                hourHeader: hourHeader,
                timeFrom: timeFrom,
                timeTo: timeTo,
            }));

        }

        this.avilableHoursSelect = avilableHoursSelect;
    }

    // return a 2D array, first elment index can be used as counter with value: array of dates of 1 hour
    getIndexHourDay2DArray(availableDateTimesLocal: Date[]): Array<Array<Date>>
    {

        if(GlobalSettings.isEmpty(availableDateTimesLocal) || availableDateTimesLocal.length == 0)
            return new Array<Array<Date>>();
        // get the first value. it is a complete date format.
        let start = availableDateTimesLocal[0];

        // controls display of the first hour step.

        // hh:MM A

        var startHours = start.getHours()
        let avilableHoursSelect = new Array<Array<Date>>();
        var counterDate: Date;

        // max possible step for one day is 23 - 24 Uhr
        for (let i = 0; i < 24; i++)
        {
            var row: Date[];
            row = new Array<Date>();
            for (let entry of availableDateTimesLocal)
            {
                counterDate = entry;
                if (entry.getHours() > startHours)
                {
                    // time step not in the same hour. break the inner loop and go to next hour.
                    startHours = entry.getHours()
                    break;
                }
                // push the time entry to the row hour element
                row.push(entry);
            }

            // at the 2 dimension array hour element
            avilableHoursSelect.push(row);

            // check if more hours values are available 
            if (availableDateTimesLocal.findIndex(x => x > counterDate) === -1)
            {
                // if the last row only has 1 element the row would not be added. With this it checks if the last row
                // only has 1 element and then adds that row
                if(row[row.length-1]!=counterDate)
                {
                    let finalRow = new Array<Date>();
                    finalRow.push(counterDate);
                    avilableHoursSelect.push(finalRow);
                }
                
                // no more hour values enable, exit the outer loop
                break;
            }
            // start the next hour loop
            availableDateTimesLocal = availableDateTimesLocal.filter(x => x >= counterDate)
        }
        return avilableHoursSelect;
    }


    public selectAppointmentDate(event : MatRadioChange)
    {
        this.newAppointmentStart = event.value;
    }

    public confirmInput(): void
    {
        this.currentStep = "confirmInput";
    }

    public getCurrentEmployeeName():string
    {
        if(this.target == this.targetOtherEmployee)
        {
            let employee = this.allPublicEmployees.find(e => e.id == this.targetId);
            return employee?.firstName + " " + employee?.lastName;
        }
        else
        {
            return "EMPLOYEE.ANY";
        }
    }

    public async shift():Promise<void>
    {
        let dto: ShiftAppointmentDto = {
            appointmentId: this.appointment.id,
            newStartDate: moment(this.newAppointmentStart).format("YYYY-MM-DDTHH:mm"),
            shiftTarget: this.target,
            targetId: this.targetId
        };
        try
        {
            this.isLoading = true;
            await this.shiftAppointmentService.shiftAppointment(dto).catch(e => console.error("Error shifting appointment", e));
            
        }
        catch(error)
        {
            this.isLoading = false;
            console.error(error);
            return;
        }

        this.currentStep = "appointmentShifted"
            this.isLoading = false;
    }

    ngOnDestroy(): void
    {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

}
