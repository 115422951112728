import { Injectable } from '@angular/core';
import { GlobalSettings } from '../config/globalsettings';
import { Observable, Subject, of } from 'rxjs';
import { ICurrentDateTime } from '../model/timespan';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { MessageService } from './messages.service';
import * as moment from 'moment/moment';
import { ServerSettingService } from './server-setting.service';
import { ConfigSettingsService } from './config-settings.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ServerSettingsService {

  // TER-510
  // working with the server date.
  private classame = (<any>this).constructor.name;

  globalSettings: GlobalSettings = new GlobalSettings();
  currentDateTimeObj:ICurrentDateTime; 
  
  updateCurrentTimeSubject = new Subject<Date>();

  public updateCurrentTime(date:Date): void{
    this.updateCurrentTimeSubject.next(date);
  }


  private _currentDateTime:Date;
  get currentDateTime():Date{
    return this._currentDateTime;
  }

  set currentDateTime(date:Date) {     
    this._currentDateTime = date;
    this.updateCurrentTime(date);

  }


  constructor(
    private http: HttpClient, 
    private messageService: MessageService,
    private globalSettingService: ServerSettingService,
    private configSettingsService: ConfigSettingsService
  ) {

  }

  getDateFromString(str:string):any {
    
    let help  = moment(str,"YYYY-MM-DDThh:mm");
    return (help.toDate());
  }

  getServerDate():Observable<ICurrentDateTime>{

    if (this.currentDateTimeObj !== undefined) 
      return of (this.currentDateTimeObj)
    
      var urlSystemDateTime = "";
    //let url = this.configSettings.clientUrl + "assets/data/system-time.json";
    
  
      urlSystemDateTime = this.configSettingsService.configSettings.apiUrl + '/api/system/time';
    
  
    return this.http.get<ICurrentDateTime>(urlSystemDateTime).pipe(  
      tap(data => this.currentDateTimeObj = data),
      tap(data => this.currentDateTimeObj.serverTime = data.serverTime.toString()), 
      tap(data => this.currentDateTimeObj.momentNow = moment(data.serverTime)), 
      tap(data => this.currentDateTimeObj.dateTimeNow = this.getDateFromString(data.serverTime.toString())), 
      tap(data => this._currentDateTime = this.getDateFromString(data.serverTime.toString())),
    );

  }

  private error(message: string) {
    this.messageService.error(`${this.classame} : ${message}`);
  }

}
