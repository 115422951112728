<mat-card appearance="outlined" *ngIf="isLoading">
    <mat-card-content style="display:flex; flex-direction:row; justify-content:center; align-items:center;">
        <mat-spinner></mat-spinner>
    </mat-card-content>
    <div style="display:flex; flex-direction:row; flex-wrap: wrap; justify-content:center; align-items:center;">
        <h4><span>{{'LOADING' | translate}}</span> </h4>
    </div>
</mat-card>

<form [formGroup]="frmLocation" *ngIf="!isLoading">
    <h2>{{'LOCATION.TITLE' | translate}}</h2>
    <div>{{'LOCATION.HEADER_TEXT' | translate}}</div>
    <!--  [disabled]="formFielsAreDisabled" -->
    <mat-radio-group formControlName="locationChoice" class="vertical-radio-group"
        (change)="onLocationChange($event)" required>
        <!-- <mat-radio-button [value]="location.id" disabled="true">{{location.name}}</mat-radio-button> -->
        <div class="location-list" *ngFor="let location of locations">
            <div class="location-item location-item-01" *ngIf="location.firstPossibleAppointment !== ''">
                <mat-radio-button [value]="location.id">{{location.name}}</mat-radio-button>
            </div>
            <div class="location-item location-item-01" *ngIf="location.firstPossibleAppointment === ''">
                <mat-radio-button [value]="location.id" disabled="true">{{location.name}}</mat-radio-button>
            </div>
            <div class="location-item-space"></div>

            <div class="location-item location-item-02"
                [innerHTML]="'LOCATION.ADRESS' | translate | userSettings:location.address:location.city"></div>
            <div class="location-item-space"></div>
            <div class="location-item location-item-03">
                <ng-template [ngIf]="location.firstPossibleAppointment !== ''">
                        <span >{{ 'LOCATION.NEXT_AVAILABLE_DATE' | translate }}{{ formatString(location.firstPossibleAppointment)}}
                        <br></span>
                </ng-template>
                <ng-template [ngIf]="location.firstPossibleAppointment == ''">
                    {{'LOCATION.NOT_AVAILABLE_TEXT' | translate}}
                </ng-template>
            </div>
            <div class="location-item location-item-space-end"></div>
        </div>
    </mat-radio-group>

</form>

<div *ngIf="locations?.length === 0">
    Keine Standorte gefunden.
</div>
<div class="nav-footer-bar">
    <div class="fixedLeft">
        <button mat-raised-button matTooltip="{{'LOCATION.BACK_PAGE_TOOLTIIP' | translate}}"
            matStepperPrevious>
            <div style="display:flex; flex-direction:row; align-items:center;">
                <mat-icon>
                    <mat-icon>keyboard_arrow_left</mat-icon>
                </mat-icon>
                <div>{{'BUTTONS.BACK' | translate}}</div>
            </div>
        </button>
    </div>

    <!-- <a *ngIf="nextStepIsEnabled" ></a> -->
    <div class="fixedRight">
        <button mat-raised-button matTooltip="{{'LOCATION.NEXT_PAGE_TOOLTIIP' | translate}}"
            [disabled]="!nextStepIsEnabled" matStepperNext>
            <div style="display:flex; flex-direction:row; align-items:center;">
                <div>{{'BUTTONS.NEXT' | translate}}</div>
                <mat-icon>
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </mat-icon>
            </div>
            
        </button>
    </div>
</div>