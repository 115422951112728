import { Pipe, PipeTransform } from '@angular/core';

// tranforms "/n" to a "<br>""
// https://stackoverflow.com/questions/35999185/angular2-pipes-output-raw-html/51236958#51236958
@Pipe({
  name: 'nl2pbr'
})
export class Nl2pbrPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    // return value.replace(/\n/g, '<br />');
    value = value.replace(/(?:\r\n\r\n|\r\r|\n\n)/g, '</p><p>');
    return '<p>' + value.replace(/(?:\r\n|\r|\n)/g, '<br>') + '</p>';
  }

/****************************
 * example useage
 * <div [innerHTML]="'testi\n\nng \n t/n/nest\n\b\ning' | translate | nl2pbr"></div>
 * 
 * 
 *  basically this also works <div [innerHTML]="headerText"></div> with a <br> tag and without the pipe
 *  <p><span *ngFor="let myContent of headerText.split('\n')">{{ myContent }} <br></span></p>

 ****************************/




}
