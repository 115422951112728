import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MessageService } from './messages.service';
import { GlobalSettings } from '../config/globalsettings';
import { ServerSettings } from '../config/server-settings';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { ConfigSettings } from 'src/app/config-settings';
import { ConfigSettingsService } from './config-settings.service';
// import { IDateDisplayOptions } from '../model/date-display-options';
import { LanguageService } from './language.service';
import * as moment from 'moment/moment';

@Injectable({
    providedIn: 'root'
})

export class ServerSettingService
{

    private classame = (<any>this).constructor.name;

    configSettings: ConfigSettings;
    globalSettings: GlobalSettings = new GlobalSettings();
    cultureSetting;

    public defaultLanguageCode = "de";
    private serverSettings: ServerSettings;
    private serviceUrl: string;
    private urlFile: string;
    private urlApi: string;

   public settingsBehaviourSubject: BehaviorSubject<ServerSettings>;

    constructor(
        private http: HttpClient,
        private configSettingsService: ConfigSettingsService,
        private languageService: LanguageService,
    )
    {
      
        this.configSettings = this.configSettingsService.configSettings;
        this.urlFile = this.configSettings.clientUrl + "/assets/appointment-settings";
        this.urlApi = this.configSettings.apiUrl + "/api/appointment-settings";
        const tmpServerSettings : ServerSettings = {
            appointmentRoundStartMinutes: true,
            appointmentStartTimeSteps: 10,
            canSelectEmployeeOnline : false,
            dataProtectionURL : "",
            defaultLanguageCode: "de",
            earliestAppointmentStartInHours: 2,
            maxCalculatedDays: 120,
            maxEstimatedAppointmentDuration: 500,
            mutuallyExclusiveServiceGroups: false,
            reCaptchaSiteKey: "",
            useReCaptcha: false,
            enableOnlineShiftAppointment: false,
            shiftAppointmentMinDistToStartInHours: 2,
            useSMSService: false
        };
        this.settingsBehaviourSubject = new BehaviorSubject<ServerSettings>(tmpServerSettings);
        this.http.get<ServerSettings>(this.urlApi).subscribe(
            data => {
                this.serverSettings = data;
                this.defaultLanguageCode = this.serverSettings.defaultLanguageCode;
                this.settingsBehaviourSubject.next(data);
            }
        );
    }

    getServerVariables(): BehaviorSubject<ServerSettings>
    {
        return this.settingsBehaviourSubject;
    }



}
