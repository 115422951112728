


// use hammer.
// https://stackoverflow.com/questions/41322566/angular-2-could-not-find-hammerjs

import { CommonModule, HashLocationStrategy } from '@angular/common';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {HttpClientModule, HTTP_INTERCEPTORS, HttpClient, withInterceptors, provideHttpClient, HttpErrorResponse, HttpResponse, HttpInterceptorFn} from '@angular/common/http';
import {NgModule, APP_INITIALIZER, inject} from '@angular/core';


import {FormsModule, ReactiveFormsModule} from '@angular/forms';
// import {FlexLayoutModule} from '@angular/flex-layout';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { Routes, RouterModule } from '@angular/router';


import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';

import { environment } from '../environments/environment';


import { LocationComponent } from './components/location/location.component';
import { ConcernComponent } from './components/concern/concern.component';
import { UserconcernComponent } from './components/userconcern/userconcern.component';

import { registerLocaleData,Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';



import { LocationService } from './shared/services/location.service';
import { ConcernService } from './shared/services/concern.service';

import { UserConcernService } from './shared/services/userconcern.service';
import { ConcernDetailComponent } from './components/concerndetail/concerndetail.component';
import { AppointmentcalendarComponent } from './components/appointmentcalendar/appointmentcalendar.component';
import { LocalnumberPipe } from './shared/pipe/localnumber.pipe';
import { LocaldatePipe } from './shared/pipe/localdate.pipe';
import { ConcactFormComponent } from './components/concactform/concactform.component';
import { StepperComponent } from './shared/stepper/stepper.component';
import { FilterComponent } from './shared/filter/filter.component';
import { ConcernGroupService } from './shared/services/concerngroup.service';
import { ConcernGroupComponent } from './components/concerngroup/concerngroup.component';
import * as _ from 'lodash';
import * as Moment from 'moment/moment';

import { CalendarComponent } from './components/calendar/calendar.component';

import { RecaptchaModule,  RecaptchaFormsModule } from 'ng-recaptcha';
import { InMemoryDataService } from './in-memory-data.service';
// import {StoreModule} from '@ngrx/store';
import { ConfirmationComponent } from './components/confirm-appointment/confirmation.component';
import { Nl2pbrPipe } from './shared/pipe/nl2pbr.pipe';
import { ConfigSettingsService } from './shared/services/config-settings.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { DefaultTranslationPipe } from './shared/pipe/default-translation.pipe';
import { CancelAppointmentComponent } from './components/cancel-appointment/cancel-appointment.component';
import { PageAttributesDirective } from './shared/directives/page-attributes.directive';
import { LanguageSettingsComponent } from './components/language-settings/language-settings.component';
import { UserSettingsPipe } from './shared/pipe/user-settings.pipe';
import { MaterialModule } from './shared/material.module';
import { LegalNoticeComponent } from './components/legal-notice/legal-notice.component';
// import { ResponseInterceptor } from 'schauf-response-handler';
import { QFlexComponent } from './components/q-flex/q-flex.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { QFlexQrScanComponent } from './components/q-flex-qr-scan/q-flex-qr-scan.component';
import { QFlexSecurityPopupComponent } from './components/q-flex-qr-scan/q-flex-security-popup/q-flex-security-popup.component';
import { SafeHtmlPipe } from './shared/pipe/safeHtml.pipe';
import { ShiftAppointmentComponent } from './components/ShiftAppointment/ShiftAppointment.component';
import { ShiftAppointmentService } from './components/ShiftAppointment/ShiftAppointment.service';
import { catchError, of, tap } from 'rxjs';
//import { ConfigSettings } from './config-settings';
const routes: Routes = [
  { path: '', redirectTo: '/book-appointment', pathMatch: 'full' },
  { path: 'book-appointment/init', redirectTo: '/book-appointment', pathMatch: 'full' },
  { path: 'book-appointment', component: ConcernComponent }
  // { path: '**', redirectTo: 'concern' }
];

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http,'./assets/i18n/','.json');
}
// init data 
// todo: just one useFactory function should be used.
// currently the data are loaded more then ones.
export function initializeData(configSettingsService: ConfigSettingsService) {
  return (): Promise<any> => { 
    return configSettingsService.init();
  }
}
export function tokenGetter() {
  return localStorage.getItem('currentUser');
}


export const responseInterceptorFn : HttpInterceptorFn = (request, next) =>
{
    const toasterService = inject(ToastrService);
    const translatorService = inject(TranslateService);

    return next(request).pipe(
        tap( response => 
        {
            try
            {
                let evt = <HttpResponse<any>> response;
                const message = evt.headers.get("Message");
                const type = evt.headers.get("Type");
                if (type !== null && type !== undefined && message !== null && message !== undefined)
                {
                    switch (type)
                    {
                        case "Ok":
                            translatorService.get(message).subscribe(translatedMessage => 
                            {
                                toasterService.success(translatedMessage, "", { positionClass: 'toast-top-center' });
                            }).unsubscribe();
                            break;

                        case "Information":
                            translatorService.get(message).subscribe(translatedMessage => 
                            {
                                toasterService.info(translatedMessage, "", { positionClass: 'toast-top-center' });
                            }).unsubscribe();
                            break;
                    }
                }
            }
            catch
            {
            }
           
        }),
        catchError((error: any) => 
        {
            try
            {
                const err = <HttpErrorResponse>error;

                if (err.headers != null && err.headers != undefined)
                {
                    const message = err.headers.get("Message");
                    const type = err.headers.get("Type");

                    if (type !== null && type !== undefined && message !== null && message !== undefined)
                    {
                        switch (type)
                        {
                            case "Warning":
                                translatorService.get(message).subscribe(translatedMessage => 
                                {
                                    toasterService.warning(translatedMessage, "", { positionClass: 'toast-top-center' });
                                }).unsubscribe();
                                break;

                            default:
                                translatorService.get(message).subscribe(translatedMessage => 
                                {
                                    toasterService.error(translatedMessage, "", { positionClass: 'toast-top-center' });
                                }).unsubscribe();
                                break;
                        }
                    }
                }
                return of(error);
            }
            catch
            {
                return of(error);
            }
        })
    );
}


@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        MaterialModule,
        FormsModule,
        ToastrModule.forRoot({
            timeOut: 15000,
            positionClass: 'toast-bottom-center',
            preventDuplicates: true,
        }),
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ReactiveFormsModule,
        // FlexLayoutModule,
        AppRoutingModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        RouterModule.forRoot(routes, { initialNavigation: 'disabled' }),
        // StoreModule.forRoot({}),
        // todo: implement for this and other components
        // StoreModule.forFeature('concern',{}) 
    ],
    declarations: [
        AppComponent,
        LocationComponent,
        ConcernComponent,
        UserconcernComponent,
        ConcernDetailComponent,
        AppointmentcalendarComponent,
        Nl2pbrPipe,
        LocaldatePipe,
        LocalnumberPipe,
        UserSettingsPipe,
        DefaultTranslationPipe,
        SafeHtmlPipe,
        ConcactFormComponent,
        StepperComponent,
        FilterComponent,
        ConcernGroupComponent,
        ConfirmationComponent,
        CancelAppointmentComponent,
        CalendarComponent,
        PageAttributesDirective,
        LanguageSettingsComponent,
        LegalNoticeComponent,
        QFlexComponent,
        ConfirmationDialogComponent,
        QFlexQrScanComponent,
        QFlexSecurityPopupComponent,
        ShiftAppointmentComponent
    ],
    providers: [
        provideHttpClient(withInterceptors([responseInterceptorFn])),
        // { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
        // { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        ConfigSettingsService, { provide: APP_INITIALIZER, useFactory: initializeData, deps: [ConfigSettingsService], multi: true },
        LocationService,
        ConcernService,
        ShiftAppointmentService,
        ConcernGroupService,
        UserConcernService,
        Location, { provide: LocationStrategy, useClass: PathLocationStrategy }
    ],
    exports: [FilterComponent],
    bootstrap: [AppComponent]
})
export class AppModule { }

//platformBrowserDynamic().bootstrapModule(AppModule);

registerLocaleData(localeDe, 'de-De', localeDeExtra);
