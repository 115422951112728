import { Component, OnInit, LOCALE_ID, Output, EventEmitter, ViewChild, ElementRef, ViewChildren, OnDestroy, ViewContainerRef, TemplateRef } from '@angular/core';
import { SessionService } from 'src/app/shared/services/session.service';
import { MatCalendarBody, MatCalendarCell, MatCalendarCellCssClasses, MatCalendar } from '@angular/material/datepicker';
import { CalendarService } from 'src/app/shared/services/calendar.service';
import { ILocation } from '../../shared/model/location';
import { ActivatedRoute, Router } from '@angular/router';
import { LocationService } from 'src/app/shared/services/location.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators, NgModel, UntypedFormArray, AbstractControl } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { LocationComponent } from '../location/location.component';
import { StepperComponent } from 'src/app/shared/stepper/stepper.component';
import { Observable, Subscription, Subject } from 'rxjs';
import { UserConcernService } from 'src/app/shared/services/userconcern.service';
import { IUserConcern } from 'src/app/shared/model/userconcern';
import { ICalendarDay } from 'src/app/shared/model/calendarday';
import { MessageService } from 'src/app/shared/services/messages.service';
import { ITimeSpan } from 'src/app/shared/model/timespan';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { ServerSettingService } from 'src/app/shared/services/server-setting.service';
import { ServerSettings } from 'src/app/shared/config/server-settings';
import { formatDate } from '@angular/common';
import * as moment from 'moment/moment';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { LanguageService } from 'src/app/shared/services/language.service';
import { ServerSettingsService } from 'src/app/shared/services/server-settings.service';
import { IPublicEmployee } from 'src/app/shared/model/public-employee';
import { GlobalSettings } from 'src/app/shared/config/globalsettings';


const MIN: number = 60000;
const HOUR: number = 3600000;

@Component({
    selector: 'app-appointmentcalendar',
    templateUrl: './appointmentcalendar.component.html',
    styleUrls: ['./appointmentcalendar.component.scss']
})

export class AppointmentcalendarComponent implements OnInit, OnDestroy
{

    // ---------- general ---------------
    private subscriptions: Subscription[] = [];
    private isReadOnly: boolean;
    private serverSettings: ServerSettings;
    private earliestPossibleStartDate: Date;
    private currentServerDate: Date;
    public form: UntypedFormGroup;

    private selectedLocation: ILocation;


    daySpans: ITimeSpan[] = [];

    datesToHighlight: Array<number> = [];


    selectedDay: number;
    selectedDate: Date;
    chooseAvailabeDateVisible: boolean;

    APPOINTMENT_CALENDAR_DAY_HOUR_HEADER_TEXT: string;


    // two dimension array
    // bug for MAC Safari
    avilableHoursSelect: Array<Array<Date>>;
    EarliestDateNumberDev: number;

    devSpans: ITimeSpan[];
    serverDateNowString: string;

    private employee: IPublicEmployee;


    _availableDateTimes: Date[] = [];
    get availableDateTimes(): Date[] { return this._availableDateTimes; }
    set availableDateTimes(d: Date[])
    {
        // moeglicherweise kann man das hier neu initialisieren.
        this._availableDateTimes = d;
    }
    private userconcerns: IUserConcern[];

    selectedDateWithTime: Date;

    @ViewChild("outlet", { read: ViewContainerRef, static: true }) outletRef: ViewContainerRef;
    @ViewChild("calendar", { read: TemplateRef, static: true }) calendarRef: TemplateRef<any>;


    constructor(
        private calendarService: CalendarService,
        private locationService: LocationService,
        private formBuilder: UntypedFormBuilder,
        private userConcernService: UserConcernService,
        private messageService: MessageService,
        private customerService: CustomerService,
        public serverSettingService: ServerSettingService,
        private translate: TranslateService,
        public languageService: LanguageService,
        private serverSettingsService: ServerSettingsService,
        public title: Title,
    )
    {
        this.subscriptions.push(
            this.translate.get(['APPLICATION_TITLE', 'MENU.BOOK_APPOINTMENT', 'APPOINTMENT_CALENDAR.DAY_HOUR_HEADER_TEXT']).subscribe((translation: [string]) =>
            {
                this.APPOINTMENT_CALENDAR_DAY_HOUR_HEADER_TEXT = translation['APPOINTMENT_CALENDAR.DAY_HOUR_HEADER_TEXT'];
                title.setTitle(translation['APPLICATION_TITLE'] + " > " + translation['MENU.BOOK_APPOINTMENT']);
            })
        );

        this.subscriptions.push(this.serverSettingsService.getServerDate().subscribe(date =>
        {
            this.currentServerDate = date.dateTimeNow;
            this.serverDateNowString = date.serverTime;
        }));

    }


    ngOnInit()
    {
        // TER-571
        this.isReadOnly = false;

        this.form = this.formBuilder.group({
            'selectedDateWithTime': [null, Validators.required],
            timeSectionsArray: this.formBuilder.array([])
        });
        this.chooseAvailabeDateVisible = false;

        this.subscriptions.push(
            this.serverSettingService.getServerVariables().subscribe((serverSettings: ServerSettings) =>
            {
                this.serverSettings = serverSettings;
            }
            )
        );

        this.subscriptions.push(
            this.locationService.selectedLocationChanges$.subscribe(selectedLocation => 
            {
                this.getDayOverviewCalendarData(selectedLocation)
            }
            )
        );

        this.subscriptions.push(
            this.customerService.notifyFormSent.subscribe(customerFormSent =>
            {
                this.setReadonlyForm(customerFormSent)
            }
            )
        );

    }

    private setReadonlyForm(isSent: boolean): void
    {
        if (isSent)
        {
            this.form.disable();
            this.isReadOnly = true;
        }
        else
        {
            this.form.enable();
            this.isReadOnly = false;
        }
    };


    // methods renders the calendar component againg
    private rerender(): void
    {
        if (!this.isReadOnly)
        {
            this.outletRef.clear();
            this.outletRef.createEmbeddedView(this.calendarRef);
        }
    }

    // calls the service for the calendar days and sets the highlight date values
    getDayOverviewCalendarData(location: any)
    {

        if (!this.isReadOnly)
        {
            this.reconfigHighligtDates([]);
            this.rerender();
        }

        this.chooseAvailabeDateVisible = false;

        this.selectedLocation = location;

        if (this.selectedLocation !== null)
        {

            this.userconcerns = this.userConcernService.getUserConcerns();
            this.employee = this.userConcernService.getEmployee();

            this.subscriptions.push(this.calendarService.getLocationCalendarDays(this.selectedLocation.id.toString(), this.userconcerns, this.employee, this.currentServerDate)
                .subscribe(dates =>
                {
                    this.reconfigHighligtDates(dates);

                    if (!this.isReadOnly)
                    {
                        this.rerender();
                    }
                }
                ));
        }

    }

    private reconfigHighligtDates(calenderDays: ICalendarDay[]): void
    {

        calenderDays.forEach(function (day)
        {
            let help = moment(day.day, "YYYY-MM-DDThh:mm");
            day.dayDate = help.toDate();
            day.dayNumber = day.dayDate.setHours(0, 0, 0, 0); // only interested in start of the day of current date
        });
        if (!this.earliestPossibleStartDate)
        {
            console.log("earliestPossibleStartDate null - ", this.earliestPossibleStartDate);
            this.earliestPossibleStartDate = this.calendarService.getStartDate(this.currentServerDate);
        }


        // remove all days, that lie in the past
        calenderDays = calenderDays.filter(x => x.dayDate >= new Date(new Date(this.earliestPossibleStartDate).setHours(0, 0, 0, 0)));
        this.datesToHighlight = [];
        calenderDays.forEach((calDay: ICalendarDay) =>
        {
            this.datesToHighlight.push(calDay.dayNumber);
        })

        if (this.datesToHighlight.length > 0)
        {
            this.notifyCalendarDay(this.datesToHighlight[0]);
        }

    }


    // since we have only a configuration values of hours for the earliest time
    // we get a value in the middle of the day in the future calculated from current date
    // e.g for 24 hours configuration we get exactly suggestion for 24 hours later dependent on the current time

    private setModifiedStartDay(thisSpans: ITimeSpan[])
    {

        // 1 modification: check configuration values in hours for first possible booking   
        // step 1.1: filter out values where the enddate is earlier as the config value. 

        let startHours = Math.floor(this.serverSettings.earliestAppointmentStartInHours);

        let momentDate = moment(this.serverDateNowString, "YYYY-MM-DDThh:mm");
        let serverDateHelper = momentDate.toDate();
        //let newDate = new Date();
        let newDate = serverDateHelper;

        //this.serverDateNowString 





        let EarliestDateNumber = newDate.setHours(newDate.getHours() + startHours);
        this.EarliestDateNumberDev = EarliestDateNumber;

        thisSpans.filter(x => x.endTimeNumber > EarliestDateNumber)

        // step 1.2: modify the startdate of all, 
        // where the startdate is earlier then the config value
        thisSpans.forEach(function (e)
        {
            //let date = new Date(e.startTimeNumber);
            if (e.startTimeNumber <= EarliestDateNumber)
            {

                e.startTimeNumber = EarliestDateNumber;
                e.startTimeDate = new Date(EarliestDateNumber);
            }

        });

        //to IPAD:
        // die ueberschrift weicht 4 Stunden ab nach vorne

        // die radioboxen weichen 2 Stunden ab nach vorne

        // => statt 7:00 - 8:00
        // und vier eintraeg 7:15

        // => 11:00 - 12:00 Uhr
        // und eintrege 9:15


        // 2 modifcation
        // check if rounded values should be displayed for the start date.

        if (this.serverSettings.appointmentRoundStartMinutes)
        {

            let minuteSpan = this.serverSettings.appointmentStartTimeSteps;
            thisSpans.forEach(function (e)
            {
                // get a valid date from the number value 
                let date = new Date(e.startTimeNumber);
                // start minutes value of the dayspan
                let minutes = date.getMinutes();
                for (let minutesCounter = minutes; minutesCounter <= (minutes + minuteSpan); minutesCounter = minutesCounter + 1)
                {
                    let helper = minutesCounter / minuteSpan;
                    let helper2 = Math.round(helper);
                    if (helper === helper2)
                    {
                        //wir haben den startzeitpunkt der minuten ermittelt.
                        // date
                        var x = date.setMinutes(0);
                        var y = new Date(x);
                        var z = y.setMinutes(minutesCounter);
                        e.startTimeNumber = z;
                        e.startTimeDate = new Date(z);
                        break;
                    }
                }
            });
        }



        //});


        //this.scrollTo("timeInputScrollTo");
    }


    calculateDayDisplay(selectedDate: number)
    {

        //currently the appointmentStartTimeSteps is used for start and step duration.


        let timeStep = this.serverSettings.appointmentStartTimeSteps; //  will be defined by configuration.
        this.chooseAvailabeDateVisible = true;
        //this.scrollTo("timeInputScrollTo");

        //convert the number into the date format.
        let thisDate = new Date(selectedDate);

        //this.todayStartDate = new Date(selectedDate);
        // control value for the selected day

        let thisSpans = this.daySpans.filter(
            x => x.startTimeDate.getDay() === thisDate.getDay()
                && x.startTimeDate.getMonth() === thisDate.getMonth()
                && x.startTimeDate.getFullYear() === thisDate.getFullYear()
        );

        // prepares our this.daySpans for display 
        this.setModifiedStartDay(thisSpans);



        let availableDateTimesLocal: Date[] = [];
        thisSpans.forEach(function (e)
        {
            for (let suggestedTime = e.startTimeNumber; suggestedTime <= e.endTimeNumber; suggestedTime = suggestedTime + (timeStep * MIN))
            {
                availableDateTimesLocal.push(new Date(suggestedTime));
            }
        });
        this.devSpans = thisSpans;

        // global variable not available in the inner scope
        this.availableDateTimes = availableDateTimesLocal;

        this.initTimeSections();

    }


    // pushes one or more spans into a date array
    // required input: daySpans array  and time steps 
    setAvailableDateTimeLocal(daySpans: ITimeSpan[], timeStep: number): Date[]
    {

        var availableDateTimesLocal: Date[] = [];

        // dayspans can be one or more values.
        daySpans.forEach(function (e)
        {
            for (let suggestedTime = e.startTimeNumber; suggestedTime <= e.endTimeNumber; suggestedTime = suggestedTime + (timeStep * MIN))
            {
                //this.availableHourSteps.push(new Date(suggestedTime));
                availableDateTimesLocal.push(new Date(suggestedTime));
            }
        });

        return availableDateTimesLocal;

    }

    // return a 2D array, first elment index can be used as counter with value: array of dates of 1 hour
    getIndexHourDay2DArray(availableDateTimesLocal: Date[]): Array<Array<Date>>
    {

        if (GlobalSettings.isEmpty(availableDateTimesLocal) || availableDateTimesLocal.length == 0)
            return new Array<Array<Date>>();
        // get the first value. it is a complete date format.
        let start = availableDateTimesLocal[0];

        // controls display of the first hour step.

        // hh:MM A

        var startHours = start.getHours()
        let avilableHoursSelect = new Array<Array<Date>>();
        var counterDate: Date;

        // max possible step for one day is 23 - 24 Uhr
        for (let i = 0; i < 24; i++)
        {

            var row: Date[];

            row = new Array<Date>();

            for (let entry of availableDateTimesLocal)
            {

                counterDate = entry;

                if (entry.getHours() > startHours)
                {
                    // time step not in the same hour. break the inner loop and go to next hour.
                    startHours = entry.getHours()
                    break;
                }

                // push the time entry to the row hour element
                row.push(entry);

            }

            // at the 2 dimension array hour element
            avilableHoursSelect.push(row);

            // check if more hours values are available 
            if (availableDateTimesLocal.findIndex(x => x > counterDate) === -1)
            {
                // if the last row only has 1 element the row would not be added. With this it checks if the last row
                // only has 1 element and then adds that row
                if (row[row.length - 1] != counterDate)
                {
                    let finalRow = new Array<Date>();
                    finalRow.push(counterDate);
                    avilableHoursSelect.push(finalRow);
                }

                // no more hour values enable, exit the outer loop
                break;
            }

            // start the next hour loop
            availableDateTimesLocal = availableDateTimesLocal.filter(x => x >= counterDate)

        }

        return avilableHoursSelect;


    }

    initTimeSections()
    {
        var availableDateTimesLocal = this.setAvailableDateTimeLocal(this.daySpans, this.serverSettings.appointmentStartTimeSteps);
        let avilableHoursSelect = new Array<Array<Date>>();

        avilableHoursSelect = this.getIndexHourDay2DArray(availableDateTimesLocal);
        let timeSections = this.form.get('timeSectionsArray') as UntypedFormArray;

        // delete old elements from previous selected day.
        let count = timeSections.controls.length;
        for (let i = 0; i < count; i++)
        {
            timeSections.removeAt(0);
        }

        for (let i = 0; i < avilableHoursSelect.length; i++)
        {
            // find full hour which is before the first entry for header display
            let timeFrom = avilableHoursSelect[i][0];

            var timeFromHours = timeFrom.getHours();

            // time from is the earliest possible time
            var momentDate = moment(timeFrom, "YYYY-MM-DDThh:mm");

            // todo: check if this also works
            var momentStartDay = momentDate.format("YYYY-MM-DD" + "T00:00:00");
            var momentStartDay = momentDate.format("YYYY-MM-DD" + " 00:00:00");

            //this.momentStartDayDev = momentDate.format("YYYY-MM-DD" + " 00:00:00");
            //this.momentStartDayDev = this.todayStartDate
            //error for IOS
            // soll im prinzip den beginn des Tages angeben
            // nicht IOS Systeme gehen zwei stunden nach vorne

            let help = moment(momentStartDay, "YYYY-MM-DDThh:mm");
            var helpDate = help.toDate();

            timeFrom = new Date(helpDate.setHours(helpDate.getHours() + timeFromHours));

            let timeTo = new Date(timeFrom);
            timeTo = new Date(timeTo.setHours(timeTo.getHours() + 1));

            let timeFromString = this.languageService.convertTime(timeFrom);
            let timeToString = this.languageService.convertTime(timeTo);

            let hourHeader = this.APPOINTMENT_CALENDAR_DAY_HOUR_HEADER_TEXT.replace("{{StartHour}}", timeFromString).replace("{{EndHour}}", timeToString);

            timeSections.push(this.formBuilder.group({
                radioSection: false,
                hourHeader: hourHeader,
                timeFrom: timeFrom,
                timeTo: timeTo,
            }));
        }

        // Make sure the first possible Appointment-Date is selected right from the start
        this.avilableHoursSelect = avilableHoursSelect;
        console.log(this.earliestPossibleStartDate, );
        this.selectedDateWithTime = this.avilableHoursSelect[0][0];// this.earliestPossibleStartDate;
        this.calendarService.selectedDate =  this.selectedDateWithTime;
    }


    // call back event from the calendar component
    // choice for selecting the date.
    notifyCalendarDay(event): void
    {
        console.log("notifyCalendarDay - ", event);
        // this disables the weiter button
        this.selectedDateWithTime = null;
        // this will be null if there are no appointments for the day especially when the user clicks on a disabled date
        if (event == null)
        {
            this.selectedDate = null;
            this.chooseAvailabeDateVisible = false;

            return;
        }

        this.selectedDay = event;
        this.selectedDate = new Date(this.selectedDay);

        this.subscriptions.push(this.calendarService.getLocationCalendarDay(this.selectedLocation.id.toString(), this.selectedDay, this.employee, this.userconcerns)
            .subscribe(dates =>
            {

                this.daySpans = dates.timespans;
                this.calculateDayDisplay(this.selectedDay)
            }
            ));
    }

    // selectes the final date for the services 
    //todo: use other form.
    SelectAppointmentDate(event: AbstractControl): void
    {
        console.log("selectAppointmentDate - event - ", event);
        let date = new Date(event.value);
        this.selectedDateWithTime = date;
        // let date = new Date(this.selectedDateWithTime);
        this.calendarService.selectedDate = date;
        console.log("selectAppointmentDate - ", date);
    }

    ngOnDestroy(): void
    {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
