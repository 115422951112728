import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, forkJoin } from 'rxjs';
import { IConcernGroup } from '../model/concerngroup';
import { tap, catchError } from 'rxjs/operators';
import { MessageService } from './messages.service';
import { GlobalSettings } from '../config/globalsettings';
import { ConfigSettings } from 'src/app/config-settings';
import { ConfigSettingsService } from './config-settings.service';
import { IPublicEmployee } from '../model/public-employee';

@Injectable({
    providedIn: 'root'
})
export class ConcernGroupService
{
    configSettings: ConfigSettings;

    constructor(
        private http: HttpClient,
        private messageService: MessageService,
        private configSettingsService: ConfigSettingsService
    )
    {
        this.configSettings = this.configSettingsService.configSettings;
       
    }


    getConcernGroupsVM(): Observable<any>
    {
        return forkJoin(
            this.http.get<IConcernGroup[]>(this.configSettings.apiUrl + "/api/concern-groups"),
            this.http.get<IPublicEmployee[]>(this.configSettings.apiUrl + "/api/public-employees")
            );
    }


}
