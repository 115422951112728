// object which will be sent back to the controller
export class IUserConcern {
  id: string;
  quantity: number;
  // only used for userconcern
  name:string;
}

// export class IUserLocation {
//   locationId: string;
//   location: string;
// }
