import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { IConcernGroup, IConcernGroupCounter } from 'src/app/shared/model/concerngroup';
import { ConcernGroupService } from 'src/app/shared/services/concerngroup.service';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { IConcernCounter } from 'src/app/shared/model/concern';
import { GlobalSettings } from 'src/app/shared/config/globalsettings';
import { ConcernService } from 'src/app/shared/services/concern.service';
import { ServerSettingService } from 'src/app/shared/services/server-setting.service';
import { ServerSettings } from 'src/app/shared/config/server-settings';
import { MessageService } from 'src/app/shared/services/messages.service';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { ConfigSettingsService } from 'src/app/shared/services/config-settings.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { Subscription } from 'rxjs';
import { IPublicEmployee } from 'src/app/shared/model/public-employee';
import { UserConcernService } from 'src/app/shared/services/userconcern.service';
import { LocationService } from 'src/app/shared/services/location.service';
import { QFlexWorkloadService } from 'src/app/shared/services/q-flex-workload.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { OnlineDesignService } from 'src/app/shared/services/online-design.service';

@Component({
    selector: 'app-concerngroup',
    templateUrl: './concerngroup.component.html',
    styleUrls: ['./concerngroup.component.scss']
})

// todo: think about solution for one available group
// todo: think about only display the group, if there are concerns in it.


export class ConcernGroupComponent implements OnInit, OnDestroy
{

    private subscriptions : Subscription[] = [];
    private translationObject: any;
    public isLoading = true;


    private allConcernGroups: IConcernGroup[];
    public displayedConcernGroups: IConcernGroup[];

    private concernGroupsCounter: IConcernGroupCounter[] = [];


    private allPublicEmployees : IPublicEmployee[];
    public displayedPublicEmployees: IPublicEmployee[];
    public filteredDisplayedEmployees: IPublicEmployee[] = [];

    private mutuallyExclusiveServiceGroups: boolean = false;
    private maxEstimatedAppointmentDuration: number;
    
    public totalServiceAmount: number;
    public totalServiceDuration: number;

    public frmConcern: UntypedFormGroup;
    public nextStepIsEnabled: boolean;
    public showTotalDuration: boolean = false;
    public showServiceGroupDuration: boolean = false;
    public canSelectEmployeeOnline : boolean = false;
    
    public mobileQuery: MediaQueryList;
    //notification which is used in the parent stepper.
    @Output() notifyNumberOfConcerns: EventEmitter<number> = new EventEmitter<number>();

    constructor(
        private onlineDesignService: OnlineDesignService,
        private concernGroupService: ConcernGroupService,
        private formBuilder: UntypedFormBuilder,
        private concernService: ConcernService,
        private userConcernService: UserConcernService,
        private globalSettingService: ServerSettingService,
        private messageService: MessageService,
        private customerService: CustomerService,
        public translate: TranslateService,
        private configSettingsService: ConfigSettingsService,
        public title: Title,
        public languageService: LanguageService,
        private locationService: LocationService,
        private workloadService : QFlexWorkloadService,
        private media: MediaMatcher,
     
    )
    {
    }


    ngAfterContentInit()
    {

    }

    ngOnInit()
    {
        this.mobileQuery = this.media.matchMedia(`(max-width: ${this.configSettingsService.configSettings.SMALL_WIDTH_BREAKPOINT}px)`);

        

        this.workloadService.loadWorkload();

        this.frmConcern = this.formBuilder.group({
            'concernText': ['', Validators.required]
        });

        // get the translation resources
        this.subscriptions.push(this.translate.get(['APPLICATION_TITLE', 'MENU.BOOK_APPOINTMENT']).subscribe((translation: [string]) =>
        {
            this.translationObject = translation;
            this.title.setTitle(this.translationObject['APPLICATION_TITLE'] + " > " + this.translationObject['MENU.BOOK_APPOINTMENT']);
        }));

        //this.showDurationTime = this.configSettingsService.configSettings.concernShowDurationTime;
        this.onlineDesignService.showTotalDuration.subscribe(result => this.showTotalDuration = result);
        this.showTotalDuration = this.onlineDesignService.showTotalDurationCurrentState;

        this.onlineDesignService.showServiceGroupDuration.subscribe(result => this.showServiceGroupDuration = result);
        this.showServiceGroupDuration = this.onlineDesignService.showServiceGroupDurationCurrentState;

        this.subscriptions.push(this.globalSettingService.getServerVariables().subscribe(
            (serverSettings: ServerSettings) =>
            {
                this.mutuallyExclusiveServiceGroups = serverSettings.mutuallyExclusiveServiceGroups;
                this.maxEstimatedAppointmentDuration = serverSettings.maxEstimatedAppointmentDuration;
                this.canSelectEmployeeOnline = serverSettings.canSelectEmployeeOnline;
            }));

            this.subscriptions.push(this.customerService.notifyFormSent.subscribe(
            customerFormSent =>
            {
                this.setReadonlyForm(customerFormSent)
            }
        ));

        this.subscriptions.push(this.concernService.getConcerns().subscribe(_ =>
        {
            this.getConcernGroups();

        }, (error: any) =>
        {
            this.isLoading = false
        }));

    }


    public filterDisplayedEmployees(input): void
    {
        if(input == 0 || input == "")
        {
            this.filteredDisplayedEmployees = this.displayedPublicEmployees;
        }
        else
        {
            this.filteredDisplayedEmployees = this.displayedPublicEmployees.filter(emp => (emp.firstName.toLowerCase() + " " + emp.lastName.toLowerCase()).includes(input.toLowerCase()));
        }
    }

    getConcernGroups(): void
    {

        this.concernGroupService.getConcernGroupsVM()
            .subscribe((data: any) =>
            {
                this.allConcernGroups = data[0];
                this.allPublicEmployees = data[1];
                this.allPublicEmployees.sort( (a , b) => a.lastName.localeCompare(b.lastName));
                this.displayedConcernGroups = [... this.allConcernGroups];
                this.displayedPublicEmployees = [... this.allPublicEmployees];
                this.filteredDisplayedEmployees = this.displayedPublicEmployees;
                this.isLoading = false;
                this.nextStepIsEnabled = this.frmConcern.valid;
            },
                error =>
                {
                    this.isLoading = false;
                });

    }

    calculateDuration(id: string): number
    {

        const foundGroupIndex = this.concernGroupsCounter.findIndex(x => x.id === id)

        if (foundGroupIndex == -1)
            return null;

        let amount = this.concernGroupsCounter[foundGroupIndex].duration;

        if (amount == 0)
            return null;

        return (amount);

    }

    public calculateTotalDuration(): number 
    {

        let amount = 0;
        this.concernGroupsCounter.forEach(grp => amount += grp.duration);

        if (amount == 0)
            return null;

        return (amount);
    }


    //count all records of the concerns in a group
    modifyConcern(concernGroupCounter: IConcernGroupCounter): void
    {

        // calculate the new groupconcern records from this values.
        const foundGroupIndex = this.concernGroupsCounter.findIndex(x => x.id === concernGroupCounter.id)

        if (foundGroupIndex > -1)
        { //group record already exist     

            // if amount == 0 concern groupsCounter 

            this.concernGroupsCounter[foundGroupIndex] = concernGroupCounter;
        } else
        {
            this.concernGroupsCounter.push(concernGroupCounter);
        };

        let duration = 0;
        let amount = 0;
        this.concernGroupsCounter.forEach(function (item)
        {

            //if (duration === 0)  
            duration += item.duration;
            amount += item.amount;
        });


        this.totalServiceAmount = amount;
        this.totalServiceDuration = duration;

        //making the form valid
        if (amount > 0)
        {
            this.frmConcern.get("concernText").setValue(duration);
        } else
        {
            this.frmConcern.get("concernText").setValue("");
        }
        this.nextStepIsEnabled = this.frmConcern.valid;
        this.notifyNumberOfConcerns.emit(this.totalServiceAmount);

        this.concernService.handleMaxEstimatedDuration(this.concernGroupsCounter, this.maxEstimatedAppointmentDuration, this.calculateTotalDuration());

        // handling combining services in different groups
        if (this.mutuallyExclusiveServiceGroups === true)
        {
            if (concernGroupCounter.amount > 0)
            {
                this.concernService.disableOtherGroupConcerns(concernGroupCounter.id);
            }
            if (concernGroupCounter.amount === 0)
            {
                this.concernService.enableOtherGroupConcerns(concernGroupCounter.id);
            }
        }


    }


    getGroupServiceNumber(guid: string)
    {

        const foundGroupRecord = this.concernGroupsCounter.find(x => x.id === guid)
        if (foundGroupRecord === undefined)
        {
            return ("");
        }

        return (foundGroupRecord.amount);
    }


    getGroupServiceTime(guid: string)
    {

        const foundGroupRecord = this.concernGroupsCounter.find(x => x.id === guid)
        if (foundGroupRecord === undefined)
        {
            return ("");
        }
        return ("(" + foundGroupRecord.duration.toString() + " min)");
    }

    setReadonlyForm(isSent: boolean)
    {
        this.frmConcern.disable()
        this.nextStepIsEnabled = true;
    };


    public selectedEmployee(event): void
    {
        if(GlobalSettings.isEmpty(event) ||GlobalSettings.isEmpty(event.value))
        {
           this.concernService.setEmployee( null );
           this.userConcernService.setEmployee(null);
        }
        else
        {
           this.concernService.setEmployee( this.allPublicEmployees.filter(e => e.id == event.value)[0] );
           this.userConcernService.setEmployee( this.allPublicEmployees.filter(e => e.id == event.value)[0] );
        }

        this.locationService.loadedLocationsInvalid = true;
        this.totalServiceAmount = 0;
        this.totalServiceDuration = 0;

        this.concernGroupsCounter.forEach(function (item)
        {

            //if (duration === 0)  
            item.duration = 0;
            item.amount = 0;
        });

        this.frmConcern.get("concernText").setValue("");
    
        this.nextStepIsEnabled = this.frmConcern.valid;
        this.notifyNumberOfConcerns.emit(this.totalServiceAmount);

        //this.concernService.handleMaxEstimatedDuration(this.concernGroupsCounter, this.maxEstimatedAppointmentDuration, this.calculateTotalDuration());
    }

    ngOnDestroy(): void
    {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

}