import { Directive, ElementRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[appPageAttributes]'
})
export class PageAttributesDirective {

  translationObject: any;
  constructor(
    private el: ElementRef,
    private title: Title,
    private translate: TranslateService) {

   }


    ngAfterContentInit(){
      // todo: want to set the this.el.nativeElement.innerHTML
      // but this works only with static html
      // herefore currently we have the tilte of the index.html
      //this.translationObject = this.translate.get("APPLICATION_TITLE");    
      
     // this.title = "xyz";
      
      this.title.setTitle(this.el.nativeElement.innerHTML);

    }
}
