import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
import { SessionService } from '../services/session.service';
import { GlobalSettings } from '../config/globalsettings';
import { ConfigSettingsService } from '../services/config-settings.service';
import { ConfigSettings } from 'src/app/config-settings';
import { LanguageService } from '../services/language.service';
import { ServerSettingService } from '../services/server-setting.service';
import * as moment from 'moment/moment';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, throwError } from 'rxjs';


@Pipe({
  name: 'localdate',
  pure: false
})

// this pipe is currently applied only to ConfigTimeSettings format 09.08.19
// but it is also possible not use the user configsettings for format the date and time
// so the standard date formats can be applied
// see for further information see predefined date options of https://angular.io/api/common/DatePipe 
export class LocaldatePipe implements PipeTransform {

  globalSettings: GlobalSettings = new GlobalSettings();
  configSettings: ConfigSettings;
  subscription: Subscription;
  translationObject: any;
  timeUnit:string;
  constructor(
    private configSettingsService: ConfigSettingsService,
    private languageService: LanguageService,
    private translate: TranslateService,
  ) {
    this.configSettings = this.configSettingsService.configSettings;

   }


    // input date expected 
    transform(value: any, format: string) {
   
      // currenty this cultureSetting is not applied
      // but if it will there must be a subscription on the language for applying the predefined languages!
      let cultureSetting = GlobalSettings.isEmpty(this.languageService.currentLanguage.code) ? this.configSettings.cultureSetting : this.languageService.currentLanguage.code;

      // special case for using the language.json configuration file
      if (format == "ConfigTimeSettings"){

        let date = new Date(value);
        let settings_time = this.languageService.replaceSettingRessources("SETTINGS.TIME");  
        let timeStr = this.languageService.convertTime(date);
        return timeStr +  ' ' + settings_time;

      }

      // throw error because of no subscription on cultureSetting changes.
      //return throwError("Currenty the pipe is not supported with this settings.");  

      if (!value) { return ''; }
      if (!format) { format = 'shortDate'; }

      let result = formatDate(value, format, cultureSetting);

      return result;
        return formatDate(value, format, cultureSetting);
        //return formatDate(value, format, this.session.locale);       
    }
}
