import { Injectable } from '@angular/core';
import { ConfigSettings } from 'src/app/config-settings';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Observable, of } from 'rxjs';
import { MessageService } from './messages.service';
import { catchError, map, tap } from 'rxjs/operators';
import { GlobalSettings } from '../config/globalsettings';
// import { IDateDisplayOptions } from '../model/date-display-options';
@Injectable({
  providedIn: 'root'
})
export class ConfigSettingsService {

  private classame = (<any>this).constructor.name;
  
// https://www.bennadel.com/blog/3487-defining-dynamic-ajax-driven-service-providers-using-app-initializer-in-angular-6-1-4.htm

  // store the values also in a static variable
  // that they can also be used as provider settings.
  //public static AppConfig:ConfigSettings;

  // todo: TER-113 only temp value, because the other do not work in the calendar component.
  // 'de-DE' 'fr-FR' 'en-GB' 
  //public static CultureSetting = "de-DE";

  private configSettingFile: string;

//   dateDisplayOptions: IDateDisplayOptions;
  // todo: TER-113
  //globalSettings: GlobalSettings = new GlobalSettings();
 

//   private dateDisplayOptionsFileData = './assets/date-display-options.json';
  // contains the config settings for the entire application
  configSettings: ConfigSettings;

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    ) {
   
     }

    // app in the app initializer
    init(){

      
     
      this.configSettingFile = './assets/config-setting.json';
      let promise = new Promise((resolve, reject) => {
        lastValueFrom( this.http.get<ConfigSettings>(this.configSettingFile)
          )
          .then(
            res => { // Success
              this.configSettings = res;
            
            //   this.dateDisplayOptions = res.dateDisplayOptions;
              //ConfigSettingsService.AppConfig = res;
              
              if(this.configSettings.apiUrl.slice(-1) == "/")
              {
                this.configSettings.apiUrl = this.configSettings.apiUrl.slice(0,this.configSettings.apiUrl.length -1);
              }

              resolve(null);
            }
          );
      }).catch(error => this.handleError(error));
      
      return promise;
    }

 
    /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
  
    
    return (error: any): Observable<T> => {

     
      this.error(error); // log to console instead
      // TODO: better job of transforming error for user consumption
      this.error(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


  private error(message: string) {
    this.messageService.error(`${this.classame} : ${message}`);
  }


}
