import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StepperComponent } from './shared/stepper/stepper.component';
import { ConfirmationComponent } from './components/confirm-appointment/confirmation.component';
import { CancelAppointmentComponent } from './components/cancel-appointment/cancel-appointment.component';
import { LanguageSettingsComponent } from './components/language-settings/language-settings.component';
import { LegalNoticeComponent } from './components/legal-notice/legal-notice.component';
import { QFlexComponent } from './components/q-flex/q-flex.component';
import { QFlexQrScanComponent } from './components/q-flex-qr-scan/q-flex-qr-scan.component';
import { ShiftAppointmentComponent } from './components/ShiftAppointment/ShiftAppointment.component';

const routes: Routes = [

    { path: 'confirm-appointment/:code/:lang', component: ConfirmationComponent },
    { path: 'confirm-appointment', component: ConfirmationComponent },

    { path: 'book-appointment/:init', component: StepperComponent },
    { path: 'book-appointment', component: StepperComponent },

    { path: 'cancel-appointment/:code/:lang', component: CancelAppointmentComponent },
    { path: 'cancel-appointment', component: CancelAppointmentComponent },

    { path: 'language', component: LanguageSettingsComponent },

    { path: 'legal-notice', component: LegalNoticeComponent },

    {path: 'q-flex/:ticket', component: QFlexComponent},
    {path: 'q-flex', component: QFlexComponent},
    {path: 'qr-scan/:id', component: QFlexQrScanComponent},

    {path: 'shift-appointment', component: ShiftAppointmentComponent},
    {path: 'shift-appointment/:code/:lang', component: ShiftAppointmentComponent}

];



@NgModule({
    imports: [RouterModule.forRoot(routes, { initialNavigation: 'disabled', onSameUrlNavigation: 'reload' })],
    // imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
