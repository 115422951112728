



// https://stackblitz.com/angular/lpnkmavgqao?file=app%2Flist-selection-example.html

// https://material.angular.io/components/radio/overview

import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ILocation } from '../../shared/model/location';
import { LocationService } from 'src/app/shared/services/location.service';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { Router } from '@angular/router';
import { UserConcernService } from 'src/app/shared/services/userconcern.service';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { MessageService } from 'src/app/shared/services/messages.service';
import { GlobalSettings } from 'src/app/shared/config/globalsettings';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { ServerSettingService } from 'src/app/shared/services/server-setting.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-location',
    templateUrl: './location.component.html',
    styleUrls: ['./location.component.scss']
})



// todo: implement detail page in overlay
// idea: display the location on the right in a map.
export class LocationComponent implements OnInit, OnDestroy
{

    private classame = (<any>this).constructor.name;
    private subscriptions : Subscription[] = [];
    APPLICATION_TITLE: string
    globalSettings: GlobalSettings = new GlobalSettings();
    nextStepIsEnabled: boolean = false;

    formFielsAreDisabled: boolean = false;
    locations: ILocation[];
    location: ILocation;
    frmLocation: UntypedFormGroup;
    textRessource: any;
    translationTitleObject: any;
    public isLoading = true;

    @Output() notifyLocationChange: EventEmitter<ILocation> = new EventEmitter<ILocation>();

    smallscreen: boolean;
    constructor(
        private locationService: LocationService,
        private userConcernService: UserConcernService,
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private customerService: CustomerService,
        private messageService: MessageService,
        public translate: TranslateService,
        public languageService: LanguageService,
        private globalSettingsServices: ServerSettingService,
        private title: Title,

    )
    {
     
    }


    ngOnInit()
    {
        
        this.smallscreen = true;
       
        this.frmLocation = this.formBuilder.group({
            'locationChoice': ['', Validators.required],
            //  'messageText': ['', Validators.required]
        });


        this.nextStepIsEnabled = this.frmLocation.valid;

        this.subscriptions.push( this.customerService.notifyFormSent.subscribe(
            customerFormSent =>
            {
                this.setReadonlyForm(customerFormSent)
            }
        ));

    }


    // not sure if subscription here is the best way.
    // as the method is called in the stepper.
    getLocations(): void
    {
        this.subscriptions.push(this.locationService.getLocations(this.userConcernService.getUserConcerns(), this.userConcernService.getEmployee())
            .subscribe(locations =>
            {
                this.locations = locations;
                var employee = this.userConcernService.getEmployee();
                if(!GlobalSettings.isEmpty(employee))
                {
                    locations = locations.filter(location => location.id == employee.fieldOfficeId);
                }
                this.isLoading = false;
                if(locations.length == 1)
                {
                    this.frmLocation.controls["locationChoice"].setValue(locations[0].id);
                    var tmp = {
                        value: locations[0].id
                    }
                    this.onLocationChange(tmp);
                }
            }
            ));
    }

    setReadonlyForm(isSent: boolean)
    {
        this.formFielsAreDisabled = true;
        this.frmLocation.get("locationChoice").disable();
 
        this.nextStepIsEnabled = true;

        
    };

    public formatString(date : Date): string
    {
        return new Date(date).toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'});
    }


    onLocationChange(mrChange: any)
    {


        let locationId = mrChange.value;
        let location: ILocation = this.locations.find(x => x.id == locationId);

        // not sure if it should kept here, at the end the calendar must react on changes somewhere
        // currently the app-storage has the notification for that.


        // currently the stepper is doing the changes.

        // TER-390
        // calls the calendar service api
        //moved to stepper now.
        //this.locationService.changeSelectedLocation(location);

        this.notifyLocationChange.emit(location);

        this.nextStepIsEnabled = this.frmLocation.valid;


    }


    ngOnDestroy(): void
    {
        this.subscriptions.forEach(s => s.unsubscribe() );
    }

}
